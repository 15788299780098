import { take, skip, zip, catchError, map } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../../../validators/emailValidator';
import { OnlyWhiteSpaceValidator } from 'app/validators/onlyWhiteSpaceValidator';
import { Country } from '../../../../models/Country';
import { CountryService } from '../../country/country.service';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GeneralOrganizationBackgroundSurveyService, CountryGroup } from './general-organization-background.service';
import {
  GeneralOrganizationBackgroundSurveyInput,
  GeneralOrganizationBackgroundSurveyOutput,
} from './general-organization-background.model';
import { GOBSurveyInputInterface } from '../survey-input-interface.model';
import { forkJoin, from, Observable } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { MaterializeAction } from 'angular2-materialize';
import { User, UserType } from '../../../../models/User';
import { SurveyErrorCode, SREvent, Dictionary, Collection } from '../../../../models';
import { errSort } from '../shared/survey-error-helpers';
import { SurveyComponent } from '../shared/survey/survey.component';
import { DashboardRedirectService } from '../../shared/dashboard-redirect.service';
import { BasicDateValidator } from '../../../../validators/basicDateValidator';
import { isNullOrUndefined } from 'util';
import { FormElement, getNumberFromInput, getFloatFromInput, responseToFormElement } from '../survey-helpers';
import { AuthenticationService } from '../../../../login/login.service';
import { EmptyOutdatedQuestions } from 'app/models/EmptyOutdatedQuestions';
import { SurveyNotification } from 'app/models/SurveyNotification';
import { VimeoYoutubeUrlValidator } from 'app/validators/vimeoYoutubeUrlValidator';
import { CurrencyCodes } from 'app/models/CurrencyCodes';
import { LocationSpecificSurveyService } from '../locationSpecificSurvey/location-specific-survey.service';
import { PhoneValidator } from '../../../../validators/phoneValidator';

declare var Materialize: any;

interface IAffirmation {
  [propName: string]: boolean;
}

interface OrgLocation {
  organizationId: number;
  countryId: number;
}

const AFFIRMATION_ERROR = 'affirmationError';
const STEP7LOCATIONCONTACT_ERROR = 'step7LocationContactError';
const NEXT_SURVEY_STEP_INDEX = 8;

// import models

@Component({
  selector: 'general-organization-background-survey',
  providers: [],
  templateUrl: './general-organization-background.component.html',
  styleUrls: ['./general-organization-background.component.css', '../survey-table-common.css'],
})
export class GeneralOrganizationalBackgroundSurveyComponent extends SurveyComponent {
  readonly TOTAL_STEPS: number = 8;
  readonly MISSING_STEP_INDEX: number = 7;

  questionCount: any;
  questionNumber: any;
  affirmationCount: any;
  customAffirmationMessage: any;
  emptyOutdatedQuestions: SurveyNotification[] = [];
  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  errSelectedPage: number = 0;
  sortField: string = 'name';
  sortDescending: boolean = true;
  errStepDetails: SurveyErrorCode[] = [];
  gobFormErrors: string[] = [];
  invalidOnInit: Collection<boolean> = {
    singles: {
      cafDonationUrl: false,
      donateUrl: false,
      facebookUrl: false,
      hqStaffCount: false,
      orgIntroVideoUrl: false,
      overallAnnualBudget: false,
      staffCount: false,
      staffIntroVideoUrl: false,
      website: false,
    },
    maps: {
      hqContact: {
        alternativeEmail: false,
        email: false,
      },
      overallAnnualBudget: {
        amount: false,
      },
    },
  };
  currencyCodeList: CurrencyCodes[];
  locationAndContactsData: CountryGroup[];
  locationList: OrgLocation[];
  showLSSButton: boolean = false;
  incompleteLSSList: OrgLocation[] = [];

  // https://angular.io/docs/ts/latest/cookbook/component-communication.html#!#parent-to-child
  @Input()
  set surveyInterface(surveyInterface: GOBSurveyInputInterface) {
    if (surveyInterface) {
      this.gOBSurveyInterface = surveyInterface;
      this.surveyDataInput = null;

      let surveyObservable: Observable<GeneralOrganizationBackgroundSurveyInput>;
      const countryListObservable = this.countryService.getCountries();

      if (surveyInterface.surveyId) {
        surveyObservable = this.surveyService.getSurveyById(surveyInterface.surveyId);
      } else if (surveyInterface.organizationId) {
        surveyObservable = this.surveyService.getMostRecentSurveyForEdit(surveyInterface.organizationId);
      } else {
        this.notificationsService.error(
          this.translateService.instant('GeneralOrganizationalBackgroundSurveyComponent-TITLE'),
          this.translateService.instant('SurveyCommon-INVALID_SURVEY_SELECTED'),
          AppSettings.NOTIFICATIONS_ERROR_OPTIONS
        );

        return;
      }

      // http://reactivex.io/documentation/operators/zip.html
      surveyObservable
        .pipe(
          this.dashRedirectService.redirectUnauthorized(),
          zip(
            countryListObservable,
            (
              survey: GeneralOrganizationBackgroundSurveyInput,
              countries: Country[]
            ): {
              survey: GeneralOrganizationBackgroundSurveyInput;
              countries: Country[];
            } => ({
              survey,
              countries,
            })
          )
        )
        .subscribe(
          (success: { survey: GeneralOrganizationBackgroundSurveyInput; countries: Country[] }) => {
            if (success && success.survey) {
              this.surveyDataInput = success.survey;
              this.affirmation.one = this.surveyDataInput.affirmation1Affirmed;
              this.affirmation.two = this.surveyDataInput.affirmation2Affirmed;
              this.affirmation.three = this.surveyDataInput.affirmation3Affirmed;
              this.countries = success.countries;

              this.resetFormToInput();
              this.getCurrencyCodeList();
              this.getLocationAndContacts();
              this.initModal(this.onSelectQuestion);
            }
          },
          (error) => {
            handleErrors(
              error,
              this.translateService,
              this.notificationsService,
              'GeneralOrganizationalBackgroundSurveyComponent-TITLE'
            );
            this.noErrorResponse = false;
          }
        );
    }
  }

  gOBSurveyInterface: GOBSurveyInputInterface;

  surveyDataInput: GeneralOrganizationBackgroundSurveyInput;
  surveyDataOutput: EmptyOutdatedQuestions;

  gobForm: Dictionary<FormElement> = {};
  /*** Step 6 fields ***/
  private gobFormStep6Saved = {};
  private gobFormStep6NotSaved = {};
  /*** Step 8 header ***/
  headElements = [
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_NAME', sortName: 'surveyName' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_TYPE', sortName: 'surveyType' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STEP', sortName: 'stepNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_QUESTION', sortName: 'questionNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STATUS', sortName: 'status' },
  ];
  surveyStatus: string;
  @Output() refreshTablesAction = new EventEmitter<string>();
  @Output() openLSSModal = new EventEmitter<OrgLocation>();
  @Output() userFormAction = new EventEmitter<SREvent>();

  /*** Materialize Modal Actions ***/
  step1ModalActions: EventEmitter<string | MaterializeAction>;
  step2ModalActions: EventEmitter<string | MaterializeAction>;
  step3ModalActions: EventEmitter<string | MaterializeAction>;
  step4ModalActions: EventEmitter<string | MaterializeAction>;
  step5ModalActions: EventEmitter<string | MaterializeAction>;
  step6ModalActions: EventEmitter<string | MaterializeAction>;
  step7ModalActions: EventEmitter<string | MaterializeAction>;
  step8ModalActions: EventEmitter<string | MaterializeAction>;

  prevStepModal: EventEmitter<string | MaterializeAction>;
  prevStepIndex: number;

  countries: Country[];
  users: User[];

  AppSettings = AppSettings;
  UserType = UserType;

  isSubmitting: boolean = false;
  surveyUpdated: boolean = false;
  surveyCanceled: boolean = false;
  noErrorResponse: boolean = true;

  affirmation: IAffirmation = {
    one: false,
    two: false,
    three: false,
  };

  private isDenyClicked: boolean = false;
  isCafDonationEnabled: boolean = false;
  constructor(
    public router: Router,
    private surveyService: GeneralOrganizationBackgroundSurveyService,
    private countryService: CountryService,
    private lssSurveyService: LocationSpecificSurveyService,
    protected notificationsService: NotificationsService,
    protected translateService: TranslateService,
    private authService: AuthenticationService,
    private dashRedirectService: DashboardRedirectService
  ) {
    super(translateService, notificationsService);
    /*************
     * Step 6
     */
    // initialized
    this.surveyService.gobFormStep6.pipe(take(1)).subscribe((gobFormStep6: CountryGroup) => {
      this.gobFormStep6NotSaved = this.gobFormStep6Saved = JSON.parse(JSON.stringify(gobFormStep6));
      this.canNotSaveAndExit();
    });

    this.surveyService.gobFormStep6.pipe(skip(1)).subscribe((gobFormStep6: CountryGroup) => {
      this.gobFormStep6NotSaved = gobFormStep6;
      this.canNotSaveAndExit();
    });
    this.resetModalActions();
  }

  resetFormToInput(): void {
    if (!this.surveyDataInput) {
      Materialize.updateTextFields();
      return;
    }
    this.resetModalActions();
    /*************
     * Step 1
     */

    this.gobForm['donateButtonDisabled'] = {
      lastUpdated: new Date(),
      lastUpdatedUser: null,
      updatedBySRAdmin: false,

      valueControl: new FormControl(this.surveyDataInput.donateButtonDisabled),
      markAsUpdatedControl: new FormControl(false),
    };

    this.gobForm['organizationName'] = {
      lastUpdated: null,
      lastUpdatedUser: null,
      updatedBySRAdmin: false,

      valueControl: new FormControl(
        {
          value: this.surveyDataInput.organizationName,
          disabled: !this.authService.isSuper(),
        },
        OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
      ),
      markAsUpdatedControl: new FormControl(false),
    };

    const simpleStep1Questions: string[] = ['govIdOrEin', 'govIdOrEin2', 'paypalId'];

    for (const key of simpleStep1Questions) {
      this.gobForm[key] = responseToFormElement(this.surveyDataInput, key, [
        OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
      ]);
      this.setInitialErrorVisibility(key, this.gobForm);
    }

    this.isCafDonationEnabled = this.surveyDataInput.organizationDto.cafDonationEnabled;

    if (this.surveyDataInput.organizationDto.cafDonationUrl) {
      this.gobForm['cafDonationUrl'] = {
        lastUpdated: new Date(),
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormControl(
          this.surveyDataInput.organizationDto.cafDonationUrl,
          Validators.pattern(AppSettings.URL_REGEX_PATTERN)
        ),
        markAsUpdatedControl: new FormControl(false),
      };

      this.invalidOnInit.singles.cafDonationUrl = !this.gobForm['cafDonationUrl'].valueControl.valid;
    } else {
      this.gobForm['cafDonationUrl'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormControl(null, Validators.pattern(AppSettings.URL_REGEX_PATTERN)),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 2
     */
    if (this.surveyDataInput.hqAddress) {
      this.gobForm['hqAddress'] = {
        lastUpdated: new Date(this.surveyDataInput.hqAddress.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.hqAddress.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.hqAddress.updatedBySRAdmin,

        valueControl: new FormGroup({
          address1: new FormControl(
            this.surveyDataInput.hqAddress.value.address1,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          address2: new FormControl(
            this.surveyDataInput.hqAddress.value.address2,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          city: new FormControl(
            this.surveyDataInput.hqAddress.value.city,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          state: new FormControl(
            this.surveyDataInput.hqAddress.value.state,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          country: new FormControl(
            isNullOrUndefined(this.surveyDataInput.hqAddress.value.country)
              ? null
              : this.surveyDataInput.hqAddress.value.country.countryId
          ),
          zip: new FormControl(this.surveyDataInput.hqAddress.value.zip),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.gobForm['hqAddress'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          address1: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          address2: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          city: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          state: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          country: new FormControl(),
          zip: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 3
     */
    if (this.surveyDataInput.hqContact) {
      const phone1 = this.surveyDataInput.hqContact.value.phone1 || '';
      const phone2 = this.surveyDataInput.hqContact.value.phone2 || '';

      this.gobForm['hqContact'] = {
        lastUpdated: new Date(this.surveyDataInput.hqContact.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.hqContact.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.hqContact.updatedBySRAdmin,

        valueControl: new FormGroup({
          email: new FormControl(this.surveyDataInput.hqContact.value.email, [
            EmailValidator.mailFormat,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
          ]),
          alternativeEmail: new FormControl(
            this.surveyDataInput.hqContact.value.alternativeEmail,
            EmailValidator.mailFormat
          ),
          firstName: new FormControl(this.surveyDataInput.hqContact.value.firstName),
          lastName: new FormControl(this.surveyDataInput.hqContact.value.lastName),
          phone1: new FormControl(phone1.replace(/\s/g, ''), PhoneValidator.phoneFormat),
          phone2: new FormControl(phone2.replace(/\s/g, ''), PhoneValidator.phoneFormat),
          title: new FormControl(this.surveyDataInput.hqContact.value.title),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.hqContact.email = !this.gobForm['hqContact'].valueControl.get('email').valid;
      this.invalidOnInit.maps.hqContact.alternativeEmail =
        !this.gobForm['hqContact'].valueControl.get('alternativeEmail').valid;
    } else {
      this.gobForm['hqContact'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          email: new FormControl(null, [EmailValidator.mailFormat, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat]),
          alternativeEmail: new FormControl(null, [
            EmailValidator.mailFormat,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
          ]),
          firstName: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          lastName: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          phone1: new FormControl(null, [OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat, PhoneValidator.phoneFormat]),
          phone2: new FormControl(null, [OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat, PhoneValidator.phoneFormat]),
          title: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 4
     */

    const simpleStep4Questions = {
      mission: [Validators.maxLength(600), OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
      summary: [Validators.maxLength(1200), OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
      orgIntroVideoUrl: VimeoYoutubeUrlValidator.vimeoYoutubeUrlFormat,
      staffIntroVideoUrl: VimeoYoutubeUrlValidator.vimeoYoutubeUrlFormat,
    };

    for (const key in simpleStep4Questions) {
      if (simpleStep4Questions.hasOwnProperty(key)) {
        this.gobForm[key] = responseToFormElement(this.surveyDataInput, key, simpleStep4Questions[key]);
        this.setInitialErrorVisibility(key, this.gobForm);
      }
    }

    const orgTypes: string[] = [
      'orgTypeFaithBased',
      'orgTypeInternationalNGO',
      'orgTypeNationalOrganization',
      'orgTypeLocalCivilSociety',
      'orgTypeLocalCivilSociety',
    ];

    for (const orgType of orgTypes) {
      if (!isNullOrUndefined(this.surveyDataInput[orgType]) && this.surveyDataInput[orgType].lastUpdatedUser) {
        this.gobForm[orgType] = {
          lastUpdated: new Date(this.surveyDataInput[orgType].lastUpdatedDate),
          lastUpdatedUser: this.surveyDataInput[orgType].lastUpdatedUser,
          updatedBySRAdmin: this.surveyDataInput[orgType].updatedBySRAdmin,

          valueControl: new FormControl(this.surveyDataInput[orgType].value),
          markAsUpdatedControl: new FormControl(false),
        };
      } else {
        this.gobForm[orgType] = {
          lastUpdated: null,
          lastUpdatedUser: null,
          updatedBySRAdmin: null,

          valueControl: new FormControl(null),
          markAsUpdatedControl: new FormControl(false),
        };
      }
    }

    /*************
     * Step 5
     */

    const simpleStep5Questions = {
      website: Validators.pattern(AppSettings.URL_REGEX_PATTERN),
      donateUrl: Validators.pattern(AppSettings.URL_REGEX_PATTERN),
      facebookUrl: Validators.pattern(AppSettings.URL_REGEX_PATTERN),
      fiscalYearEnding: [BasicDateValidator.dateFormat, BasicDateValidator.fiscalYearDate],
      staffCount: Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN),
      hqStaffCount: Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN),
      twitterHandle: Validators.pattern(AppSettings.TWITTER_USERNAME_REGEX_PATTERN),
      discriminationPolicy: undefined,
      religiousAffiliation: undefined,
      whistleblowerPolicy: undefined,
    };

    for (const key in simpleStep5Questions) {
      if (simpleStep5Questions.hasOwnProperty(key)) {
        this.gobForm[key] = responseToFormElement(this.surveyDataInput, key, simpleStep5Questions[key]);
        this.setInitialErrorVisibility(key, this.gobForm);
      }
    }

    if (this.surveyDataInput.overallAnnualBudget) {
      this.gobForm['overallAnnualBudget'] = {
        lastUpdated: new Date(this.surveyDataInput.overallAnnualBudget.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.overallAnnualBudget.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.overallAnnualBudget.updatedBySRAdmin,

        valueControl: new FormGroup({
          currency: new FormControl(
            this.surveyDataInput.overallAnnualBudget.value.currency
              ? this.surveyDataInput.overallAnnualBudget.value.currency
              : 'USD'
          ),
          amount: new FormControl(
            this.surveyDataInput.overallAnnualBudget.value.amount,
            Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.singles.overallAnnualBudget = !this.gobForm['overallAnnualBudget'].valueControl.valid;
      this.invalidOnInit.maps.overallAnnualBudget.amount =
        !this.gobForm['overallAnnualBudget'].valueControl.get('amount').valid;
    } else {
      this.gobForm['overallAnnualBudget'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          currency: new FormControl('USD'),
          amount: new FormControl(null, Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 6
     */

    this.userFormAction.emit({ type: 'general_view' });

    this.gobForm['userManagementFormValid'] = {
      lastUpdated: null,
      lastUpdatedUser: null,
      updatedBySRAdmin: null,

      valueControl: new FormControl(true, Validators.requiredTrue),
      markAsUpdatedControl: new FormControl(false),
    };
    this.setInitialErrorVisibility('userManagementFormValid', this.gobForm);

    /* After the fields have been set and the proper event handlers have been fired,
       call updateTextFields to reset the labels to be active if necessary */
    setTimeout(Materialize.updateTextFields, 1000);
    this.step8TabClicked();
  }
  resetModalActions() {
    /*** Materialize Modal Actions ***/
    this.step1ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step2ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step3ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step4ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step5ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step6ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step7ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step8ModalActions = new EventEmitter<string | MaterializeAction>();

    this.modalActions.steps = [
      this.step1ModalActions,
      this.step2ModalActions,
      this.step3ModalActions,
      this.step4ModalActions,
      this.step5ModalActions,
      this.step6ModalActions,
      this.step7ModalActions,
      this.step8ModalActions,
    ];

    this.modalActions.error = new EventEmitter<string | MaterializeAction>();

    this.gobForm = {};
  }
  surveyHasBeenModified(): boolean {
    if (this.surveyUpdated) {
      return true;
    }
    if (this.surveyDataInput) {
      if (
        this.surveyDataInput.affirmation1Affirmed !== this.affirmation.one ||
        this.surveyDataInput.affirmation2Affirmed !== this.affirmation.two ||
        this.surveyDataInput.affirmation3Affirmed !== this.affirmation.three ||
        this.surveyDataInput.affirmation1Affirmed !== this.affirmation.one ||
        this.surveyDataInput.affirmation2Affirmed !== this.affirmation.two
      ) {
        return true;
      }
    }
    let hasDirtyFields = false;
    for (const key in this.gobForm) {
      if (this.gobForm[key].valueControl) {
        hasDirtyFields =
          hasDirtyFields || this.gobForm[key].valueControl.dirty || this.gobForm[key].markAsUpdatedControl.value;
      }
      /* break if any invalid form is found */
      if (hasDirtyFields) {
        return true;
      }
    }
    return false;
  }

  canNotSaveAndExit(): boolean {
    // the requirements for saving is that every selected country in step 6 has one contact
    // *and* that for selected countries, the same user is not selected twice
    // *and* all the validation should pass
    // *and* affirmations were accepted (unless user is super)
    this.getFormErrors();
    let allFormsValid = true;
    for (const f in this.gobForm) {
      if (this.gobForm[f].valueControl) {
        allFormsValid = allFormsValid && (this.gobForm[f].valueControl.valid || this.gobForm[f].valueControl.disabled);
      }
      /* break if any invalid form is found */
      if (!allFormsValid) {
        break;
      }
    }
    return (
      (this.surveyDataInput && this.surveyDataInput.outdated) ||
      this.isSubmitting ||
      !allFormsValid ||
      !(this.affirmed() || this.affirmationUpToDate() || this.authService.isSuper()) ||
      Object.keys(this.gobFormStep6NotSaved)
        .map(
          (countryCode: string): boolean =>
            !this.gobFormStep6NotSaved[countryCode].user1 ||
            (this.gobFormStep6NotSaved[countryCode].user1 &&
              this.gobFormStep6NotSaved[countryCode].user2 &&
              this.gobFormStep6NotSaved[countryCode].user1.userId ===
                this.gobFormStep6NotSaved[countryCode].user2.userId)
        )
        .reduce((left: boolean, right: boolean): boolean => left || right, false)
    );
  }

  private save(): Promise<any> {
    const survey: GeneralOrganizationBackgroundSurveyOutput = this.getGOBSurveyInputs();
    // Nullify input responses which are empty or made up of whitespaces
    for (const formStep in survey) {
      if (survey[formStep] !== null && typeof survey[formStep].value === 'object') {
        // Handle nested object responses
        for (const formField in survey[formStep].value) {
          // Values that are strings have their whitespaces removed and checked if they are empty
          if (
            typeof survey[formStep].value[formField] === 'string' &&
            survey[formStep].value[formField].replace(/\s/g, '') === ''
          ) {
            // Setting the field value to null makes it a "no response", instead of a blank response
            survey[formStep].value[formField] = null;
          }
        }
      } else if (
        survey[formStep] !== null &&
        typeof survey[formStep].value === 'string' &&
        survey[formStep].value.replace(/\s/g, '') === ''
      ) {
        // Handle string responses
        survey[formStep].value = null;
      }
    }
    return this.surveyService.submitSurvey(this.surveyDataInput.organizationId, survey);
  }

  getGOBSurveyInputs(): GeneralOrganizationBackgroundSurveyOutput {
    const survey: GeneralOrganizationBackgroundSurveyOutput = {
      /* meta data */
      previousSurveyId: this.surveyDataInput.surveySubmissionId,

      /* responses */
      affirmed: this.affirmed(),

      affirmation1Affirmed: this.affirmation.one,

      affirmation2Affirmed: this.affirmation.two,

      affirmation3Affirmed: this.affirmation.three,

      donateButtonDisabled: this.gobForm['donateButtonDisabled'].valueControl.value,

      organizationName: this.gobForm['organizationName'].valueControl.value,

      paypalId: {
        markAsUpdated: this.gobForm['paypalId'].markAsUpdatedControl.value,
        value: this.gobForm['paypalId'].valueControl.value,
      },
      discriminationPolicy: {
        markAsUpdated: this.gobForm['discriminationPolicy'].markAsUpdatedControl.value,
        value: this.gobForm['discriminationPolicy'].valueControl.value,
      },
      donateUrl: {
        markAsUpdated: this.gobForm['donateUrl'].markAsUpdatedControl.value,
        value: this.gobForm['donateUrl'].valueControl.value,
      },
      fiscalYearEnding: {
        markAsUpdated: this.gobForm['fiscalYearEnding'].markAsUpdatedControl.value,
        value: this.gobForm['fiscalYearEnding'].valueControl.value,
      },
      facebookUrl: {
        markAsUpdated: this.gobForm['facebookUrl'].markAsUpdatedControl.value,
        value: this.gobForm['facebookUrl'].valueControl.value,
      },
      govIdOrEin: {
        markAsUpdated: this.gobForm['govIdOrEin'].markAsUpdatedControl.value,
        value: this.gobForm['govIdOrEin'].valueControl.value,
      },
      govIdOrEin2: {
        markAsUpdated: this.gobForm['govIdOrEin2'].markAsUpdatedControl.value,
        value: this.gobForm['govIdOrEin2'].valueControl.value,
      },
      hqAddress: {
        markAsUpdated: this.gobForm['hqAddress'].markAsUpdatedControl.value,
        value: Object.assign({}, this.gobForm['hqAddress'].valueControl.value, {
          country: { countryId: this.gobForm['hqAddress'].valueControl.value.country },
        }),
      },
      hqContact: {
        markAsUpdated: this.gobForm['hqContact'].markAsUpdatedControl.value,
        value: this.gobForm['hqContact'].valueControl.value,
      },
      hqStaffCount: {
        markAsUpdated: this.gobForm['hqStaffCount'].markAsUpdatedControl.value,
        value: getNumberFromInput(this.gobForm['hqStaffCount'].valueControl.value),
      },
      mission: {
        markAsUpdated: this.gobForm['mission'].markAsUpdatedControl.value,
        value: this.gobForm['mission'].valueControl.value,
      },
      overallAnnualBudget: {
        markAsUpdated: this.gobForm['overallAnnualBudget'].markAsUpdatedControl.value,
        value: {
          currency: this.gobForm['overallAnnualBudget'].valueControl.value.currency,
          amount: getFloatFromInput(this.gobForm['overallAnnualBudget'].valueControl.value.amount),
        },
      },
      religiousAffiliation: {
        markAsUpdated: this.gobForm['religiousAffiliation'].markAsUpdatedControl.value,
        value: this.gobForm['religiousAffiliation'].valueControl.value,
      },
      staffCount: {
        markAsUpdated: this.gobForm['staffCount'].markAsUpdatedControl.value,
        value: getNumberFromInput(this.gobForm['staffCount'].valueControl.value),
      },
      summary: {
        markAsUpdated: this.gobForm['summary'].markAsUpdatedControl.value,
        value: this.gobForm['summary'].valueControl.value,
      },
      orgIntroVideoUrl: {
        markAsUpdated: this.gobForm['orgIntroVideoUrl'].markAsUpdatedControl.value,
        value: this.gobForm['orgIntroVideoUrl'].valueControl.value,
      },
      staffIntroVideoUrl: {
        markAsUpdated: this.gobForm['staffIntroVideoUrl'].markAsUpdatedControl.value,
        value: this.gobForm['staffIntroVideoUrl'].valueControl.value,
      },
      twitterHandle: {
        markAsUpdated: this.gobForm['twitterHandle'].markAsUpdatedControl.value,
        value: this.gobForm['twitterHandle'].valueControl.value,
      },
      website: {
        markAsUpdated: this.gobForm['website'].markAsUpdatedControl.value,
        value: this.gobForm['website'].valueControl.value,
      },
      whistleblowerPolicy: {
        markAsUpdated: this.gobForm['whistleblowerPolicy'].markAsUpdatedControl.value,
        value: this.gobForm['whistleblowerPolicy'].valueControl.value,
      },
      orgTypeFaithBased: {
        markAsUpdated: this.gobForm['orgTypeFaithBased'].markAsUpdatedControl.value,
        value: this.gobForm['orgTypeFaithBased'].valueControl.value,
      },
      orgTypeInternationalNGO: {
        markAsUpdated: this.gobForm['orgTypeInternationalNGO'].markAsUpdatedControl.value,
        value: this.gobForm['orgTypeInternationalNGO'].valueControl.value,
      },
      orgTypeNationalOrganization: {
        markAsUpdated: this.gobForm['orgTypeNationalOrganization'].markAsUpdatedControl.value,
        value: this.gobForm['orgTypeNationalOrganization'].valueControl.value,
      },
      orgTypeLocalCivilSociety: {
        markAsUpdated: this.gobForm['orgTypeLocalCivilSociety'].markAsUpdatedControl.value,
        value: this.gobForm['orgTypeLocalCivilSociety'].valueControl.value,
      },
      locationContactList: {
        markAsUpdated: false,
        value: <any>Object.keys(this.gobFormStep6NotSaved).map((countryCode: string) => ({
          countryId: this.gobFormStep6NotSaved[countryCode].country.countryId,
          user1: this.gobFormStep6NotSaved[countryCode].user1,
          user2: this.gobFormStep6NotSaved[countryCode].user2,
        })),
      },
    };
    return survey;
  }

  public onPrev(currentStepIndex: number, prevStepIndex?: number): void {
    if (
      currentStepIndex === -1 &&
      (prevStepIndex === this.MISSING_STEP_INDEX || prevStepIndex === undefined || prevStepIndex === null)
    ) {
      this.step8TabClicked();
    }
    this.openPrevModal(currentStepIndex, prevStepIndex);
  }

  public onNext(currentStepIndex: number): void {
    const nextIsMissingStep: boolean = currentStepIndex === this.MISSING_STEP_INDEX - 1;

    // Pre modal actions
    if (nextIsMissingStep) {
      this.step8TabClicked();
    }

    this.openNextModal(currentStepIndex);

    // Post modal actions
    if (nextIsMissingStep) {
      this.displayLSSButton();
    }
  }

  // Before clearing the current window, provide a pause so the user
  // has time to read an alert.

  onDelaySaveAndExit(currentModal: EventEmitter<string | MaterializeAction>): void {
    setTimeout(() => {
      this.onSaveAndExit(currentModal);
      const modal = <HTMLElement>document.getElementsByClassName('modal-overlay')[0];
      modal.style.display = 'none';
    }, 3000);
  }

  onSaveAndExit(currentModal: EventEmitter<string | MaterializeAction>): void {
    if (this.isSubmitting) {
      return;
    }

    /**
     * TODO: We are using stringify or (deep copy) to prevent gobFormStep6NotSaved from changing.
     * We need to investigate more why the object has been changing after an assignment
     * when gobFormStep6NotSaved changed causes the  form to not  properly reset it s value
     */
    this.gobFormStep6Saved = JSON.parse(JSON.stringify(this.gobFormStep6NotSaved));

    this.isSubmitting = true;
    this.save()
      .then((success) => {
        this.notifySaveSuccess('GeneralOrganizationalBackgroundSurveyComponent-TITLE');

        currentModal.emit({ action: 'modal', params: ['close'] });

        this.isSubmitting = false;
        this.surveyUpdated = false;
        // this causes the survey to be refreshed - `surveyInterface` is a set() method defined above
        this.surveyDataInput = null;
        setTimeout(() => (this.surveyInterface = this.gOBSurveyInterface), 0);
        this.hideOverlay();
        if (currentModal === this.step7ModalActions) {
          this.refreshTablesAction.emit('step7');
        }
      })
      .catch((error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'GeneralOrganizationalBackgroundSurveyComponent-TITLE'
        );

        this.isSubmitting = false;
        this.surveyUpdated = false;
      });
  }

  onSaveAndOpenLSS(currentModal: EventEmitter<string | MaterializeAction>): void {
    if (this.isSubmitting) {
      return;
    }

    const openLSS = (): void => {
      currentModal.emit({ action: 'modal', params: ['close'] });

      this.isSubmitting = false;
      if (this.showLSSButton) {
        this.refreshTablesAction.emit('step7');
        setTimeout(() => {
          this.openLSSModal.emit({
            organizationId: this.incompleteLSSList[0].organizationId,
            countryId: this.incompleteLSSList[0].countryId,
          });
        }, 1000);
      }
      this.surveyUpdated = false;
      // this causes the survey to be refreshed - `surveyInterface` is a set() method defined above
      this.surveyDataInput = null;
      setTimeout(() => (this.surveyInterface = this.gOBSurveyInterface), 0);
    };

    this.isSubmitting = true;
    this.save()
      .then((success) => {
        this.notifySaveSuccess('GeneralOrganizationalBackgroundSurveyComponent-TITLE');
        openLSS();
      })
      .catch((error) => {
        if (error.error.errors.some((e) => e.errorCode === 'SURVEY_NOT_UPDATED_EXCEPTION')) {
          openLSS();
        } else {
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'GeneralOrganizationalBackgroundSurveyComponent-TITLE'
          );
        }
        this.isSubmitting = false;
        this.surveyUpdated = false;
      });
  }

  onCancel(currentModal: EventEmitter<string | MaterializeAction>): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      currentModal.emit({ action: 'modal', params: ['close'] });
      /*************
       * Step 6 Reset
       */

      /**
       * TODO: We are using stringify or (deep copy) to prevent gobFormStep6Saved from changing.
       * We need to investigate more why the object has been changing after an assignment
       * when gobFormStep6Saved changed causes the  form to not  properly reset it s value
       */

      this.surveyService.gobFormStep6.next(JSON.parse(JSON.stringify(this.gobFormStep6Saved)));
      this.resetFormToInput();
      this.surveyUpdated = false;
      this.surveyCanceled = true;
      this.displayLSSButton();
      this.hideOverlay();
    }
  }

  onSurveyChange(surveyUpdated: boolean) {
    this.surveyUpdated = surveyUpdated;
  }

  onChildFormEvent(field: string, status: SREvent) {
    if (field in this.gobForm) {
      switch (status.type) {
        case 'no_form':
          if (this.gobForm[field].valueControl.value !== true) {
            this.gobForm[field].valueControl.setValue(true);
            this.gobForm[field].valueControl.markAsDirty();
          }
          break;
        case 'is_form_empty':
          if (
            status.hasOwnProperty('payload') &&
            typeof status.payload === 'boolean' &&
            status.payload !== this.gobForm[field].valueControl.value
          ) {
            this.gobForm[field].valueControl.setValue(status.payload);
            this.gobForm[field].valueControl.markAsDirty();
          }
          break;
      }
    } // end if
  } // end onChildFormEvent

  handleUrlChange() {
    (this.gobForm.donateButtonDisabled.valueControl as FormControl).setValue(this.isDonateUrlValid());
  }

  isDonateUrlValid() {
    return this.gobForm.donateUrl.valueControl.invalid || !this.gobForm.donateUrl.valueControl.value;
  }

  toggleDonations() {
    if (this.gobForm.donateUrl.valueControl.valid && this.donateUrlExists()) {
      this.surveyUpdated = true;
      (this.gobForm.donateButtonDisabled.valueControl as FormControl).setValue(
        !this.gobForm.donateButtonDisabled.valueControl.value
      );
    } else {
      document.getElementById('donate_url').focus();
    }
  }

  displayUrlError() {
    return (
      !this.gobForm.donateUrl.valueControl.valid &&
      (this.gobForm.donateUrl.valueControl.touched ||
        (this.invalidOnInit && this.invalidOnInit.singles && this.invalidOnInit.singles.donateUrl))
    );
  }
  donateUrlExists() {
    return (
      this.gobForm['donateUrl'] &&
      this.gobForm['donateUrl'].valueControl &&
      this.gobForm['donateUrl'].valueControl.value
    );
  }

  affirmed() {
    return (
      (this.affirmation.one && this.affirmation.two) ||
      (this.affirmation.one && this.affirmation.two && this.affirmation.three)
    );
  }

  affirmationUpToDate(): boolean {
    if (!this.surveyDataInput) {
      return false;
    }
    const affirmDate: number = this.surveyDataInput.lastAffirmDate;
    const dueDate: number = this.surveyDataInput.nextAffirmDateDue;
    const now: number = Date.now();
    if (!affirmDate || !dueDate || dueDate <= now) {
      this.translateService.get('customAffirmationMessage2').subscribe((res: string) => {
        this.customAffirmationMessage = res;
      });
      this.authService.isSuper() ? (this.questionCount = 5) : (this.questionCount = 4);
      this.questionNumber = 4;
      this.affirmationCount = 2;
      return false;
    }
    // since the mandatory affirmation has been moved out of step 1, there is no need to update the numbers in the below subscribe.
    this.translateService.get('customAffirmationMessage1').subscribe((res: string) => {
      this.customAffirmationMessage = res;
    });
    this.authService.isSuper() ? (this.questionCount = 5) : (this.questionCount = 4);
    this.questionNumber = 4;
    this.affirmationCount = 1;
    return true;
  }

  isCAFDonationUrlNotValid() {
    return this.gobForm.cafDonationUrl.valueControl.invalid;
  }

  toggleCAFDonationEnabled() {
    if (!this.isCafDonationEnabled) {
      this.gobForm['cafDonationUrl'].valueControl.setValue(this.surveyDataInput.organizationDto.cafDonationUrl);
      this.isCafDonationEnabled = !this.isCafDonationEnabled;
    } else if (this.isCafDonationEnabled) {
      this.gobForm['cafDonationUrl'].valueControl.setValue('');
      this.isCafDonationEnabled = !this.isCafDonationEnabled;
    }
  }

  cafDonationUrlExists() {
    return (
      this.gobForm['cafDonationUrl'] &&
      this.gobForm['cafDonationUrl'].valueControl &&
      this.gobForm['cafDonationUrl'].valueControl.value
    );
  }

  parseDate(milliseconds: number, locale: string): string {
    const date = new Date(milliseconds);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(locale, options);
  }

  matchesUsaState(event: any): void {
    const inputStateName: string = event.target.value && event.target.value.toLowerCase();
    for (const country of this.countries) {
      const name: string = country.name.slice(15).toLowerCase();
      const abbreviation: string = country.longAbbreviation.slice(2).toLowerCase();
      if (country.name.match(/UNITED STATES:/) && (inputStateName === name || inputStateName === abbreviation)) {
        this.gobForm.hqAddress.valueControl.get('country').setValue(country.countryId);
        break;
      }
    }
  }

  sendDenyNotification() {
    this.affirmation.one = false;
    this.affirmation.two = false;
    if (!this.isDenyClicked) {
      this.surveyService
        .denyAffirmation(this.surveyDataInput.organizationId)
        .subscribe((resp) => (this.isDenyClicked = true));
    }
  }

  onStepClick(stepIndex: number, event: Event) {
    // Prevent page from changing
    event.preventDefault();
    this.selectStep(stepIndex - 1);
    if (stepIndex - 1 !== NEXT_SURVEY_STEP_INDEX) {
      this.showOverlay();
    }
  }

  selectStep(stepIndex: number) {
    if (stepIndex === NEXT_SURVEY_STEP_INDEX) {
      this.step9TabClicked();
      return;
    }

    // Pre modal actions
    if (stepIndex === this.MISSING_STEP_INDEX) {
      this.step8TabClicked();
    }

    // Open modal
    this.modalActions.steps[stepIndex].emit({ action: 'modal', params: ['open'] });

    // Post modal actions
    if (stepIndex === this.MISSING_STEP_INDEX) {
      this.displayLSSButton();
    }
  }

  // fetches the data on selecting modal tab 8
  step8TabClicked() {
    const survey: GeneralOrganizationBackgroundSurveyOutput = this.getGOBSurveyInputs();
    this.emptyOutdatedQuestions = [];
    this.surveyService.checkEmptyOutdatedStatus(this.surveyDataInput.organizationId, survey).subscribe((res) => {
      this.surveyDataOutput = <EmptyOutdatedQuestions>res;
      const questionList = Object.entries(this.surveyDataOutput.responseData.question_list);
      const nestedArr = questionList.map((item) =>
        item[1].map(
          (val) =>
            <SurveyNotification>{
              organizationId: this.surveyDataInput.organizationId,
              surveyName: this.translateService.instant('SurveyNotificationsComponent_GENERAL_ORGANIZATION'),
              surveyTypeFull: this.translateService.instant('SurveyNotificationsComponent_GENERAL'),
              stepNumber: val.stepNumber,
              questionNumber: val.questionNumber,
              status: val.valueEmpty
                ? this.translateService.instant('SurveyNotificationsComponent_EMPTY')
                : this.translateService.instant('SurveyNotificationsComponent_OUTDATED'),
            }
        )
      );
      this.emptyOutdatedQuestions = [].concat.apply([], nestedArr);
    });
  }

  step9TabClicked() {
    this.refreshTablesAction.emit('step9');
    const element = document.getElementById('lSSurveys');
    if (!element.classList.contains('active')) {
      element.click();
    }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // opens the selected modal and scrolls to the selected question
  public onSelectQuestion(stepNum: number, questionNum: number) {
    if (this.step8ModalActions || this.modalActions.error) {
      this.step8ModalActions.emit({ action: 'modal', params: ['close'] });
      this.modalActions.error.emit({ action: 'modal', params: ['close'] });
    }
    this.scrollToQuestion(stepNum, questionNum);
  }

  // header sort for the modal 8 tab
  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  getCurrencyCodeList() {
    this.surveyService.getCurrencyCodes().subscribe((res: CurrencyCodes) => {
      this.currencyCodeList = res.responseData.currency_list;
      this.currencyCodeList.sort();
    });
  }

  // get step 7 location and contacts data
  getLocationAndContacts() {
    this.surveyService.gobFormStep6.pipe(take(1)).subscribe((data: CountryGroup[]) => {
      if (Object.keys(data).length > 0 && this.authService.isOrgGeneral()) {
        this.locationAndContactsData = JSON.parse(JSON.stringify(data));
        this.displayLSSButton();
      } else {
        this.showLSSButton = false;
      }
    });
  }

  displayLSSButton() {
    if (this.authService.isOrgGeneral()) {
      this.locationList = [];
      if (this.step8ModalActions) {
        this.locationAndContactsData = JSON.parse(JSON.stringify(this.gobFormStep6Saved));
      }
      if (this.locationAndContactsData && Object.keys(this.locationAndContactsData).length > 0) {
        this.locationList = Object.values(this.locationAndContactsData).map((data: CountryGroup) => ({
          organizationId: this.surveyDataInput.organizationId,
          countryId: data.country.countryId,
        }));
      }
      if (this.locationList && this.locationList.length > 0) {
        this.checkLSSCompletedStatus();
      }
    }
  }

  // check for any incomplete LSS
  checkLSSCompletedStatus() {
    const emptyOutdatedLssObsList: Observable<[OrgLocation, EmptyOutdatedQuestions]>[] = this.locationList.map(
      (location) => {
        return this.lssSurveyService.getEmptyOutdatedLSSSurveyById(location.organizationId, location.countryId).pipe(
          catchError((error) => {
            handleErrors(
              error,
              this.translateService,
              this.notificationsService,
              'GeneralOrganizationalBackgroundSurveyComponent-TITLE'
            );
            this.isSubmitting = false;
            this.surveyUpdated = false;
            return from([null]);
          }),
          map((questions: EmptyOutdatedQuestions) => [location, questions])
        );
      }
    );
    forkJoin(emptyOutdatedLssObsList)
      .pipe(
        map((lssTupleList: [OrgLocation, EmptyOutdatedQuestions][]) => {
          // filter out invalid responses and complete LSS's
          const incompleteLssTupleList: [OrgLocation, EmptyOutdatedQuestions][] = lssTupleList.filter(
            ([_, questions]) => {
              if (!questions || !questions.responseData || !questions.responseData.question_list) {
                return false;
              }

              const questionList: string[] = Object.keys(questions.responseData.question_list);
              return questionList && questionList.length > 0;
            }
          );
          return incompleteLssTupleList.map(([location, _]) => location);
        })
      )
      .subscribe((incompleteLssList: OrgLocation[]) => {
        this.incompleteLSSList = incompleteLssList;
        if (this.incompleteLSSList && this.incompleteLSSList.length > 0) {
          this.showLSSButton = true;
        } else {
          this.showLSSButton = false;
        }
      });
  }

  getFormErrors() {
    this.gobFormErrors = [];
    for (const f in this.gobForm) {
      const gobFormControl = this.gobForm[f].valueControl as FormControl;
      this.gobFormErrors = this.updateErrors(gobFormControl, f, this.gobFormErrors);
    }

    // check for Affirmation error
    const isAffirmed: boolean = this.affirmed() || this.affirmationUpToDate() || this.authService.isSuper();
    if (!isAffirmed && !this.gobFormErrors.includes(AFFIRMATION_ERROR)) {
      this.gobFormErrors.push(AFFIRMATION_ERROR);
    } else if (isAffirmed && this.gobFormErrors.includes(AFFIRMATION_ERROR)) {
      this.gobFormErrors = this.gobFormErrors.filter((item) => {
        item !== AFFIRMATION_ERROR;
      });
    }

    // check for location contact error in Step 7
    const hasErrorStep7LocationContact: boolean = Object.keys(this.gobFormStep6NotSaved)
      .map(
        (countryCode: string): boolean =>
          !this.gobFormStep6NotSaved[countryCode].user1 ||
          (this.gobFormStep6NotSaved[countryCode].user1 &&
            this.gobFormStep6NotSaved[countryCode].user2 &&
            this.gobFormStep6NotSaved[countryCode].user1.userId === this.gobFormStep6NotSaved[countryCode].user2.userId)
      )
      .reduce((left: boolean, right: boolean): boolean => left || right, false);

    if (hasErrorStep7LocationContact && !this.gobFormErrors.includes(STEP7LOCATIONCONTACT_ERROR)) {
      this.gobFormErrors.push(STEP7LOCATIONCONTACT_ERROR);
    } else if (!hasErrorStep7LocationContact && this.gobFormErrors.includes(STEP7LOCATIONCONTACT_ERROR)) {
      this.gobFormErrors = this.gobFormErrors.filter((item) => {
        item !== STEP7LOCATIONCONTACT_ERROR;
      });
    }
  }

  errorButtonClicked(stepIndex: number): void {
    this.modalActions.steps[stepIndex].emit({ action: 'modal', params: ['close'] });
    this.prevStepIndex = stepIndex;
    this.prevStepModal = this.modalActions.steps[stepIndex];
    this.errStepDetails = [];

    this.gobFormErrors.forEach((gobErr) => {
      const errDetail: SurveyErrorCode = this.surveyService.getGOBErrorCodeList().find((err) => err.errCode === gobErr);

      if (errDetail && !this.isDuplicateError(errDetail)) {
        this.errStepDetails.push(errDetail);
      }
    });
    this.errStepDetails.sort(errSort);
    this.modalActions.error.emit({ action: 'modal', params: ['open'] });
  }

  // Used to check for duplicate step details errors (from multi-part questions) in errorButtonClicked()
  isDuplicateError(newError: SurveyErrorCode) {
    return this.errStepDetails.some(
      (error) => newError.stepNum === error.stepNum && newError.questionNum === error.questionNum
    );
  }

  updateCAFDonationData(currentModal: EventEmitter<string | MaterializeAction>) {
    if (this.isSubmitting) {
      return;
    }

    this.surveyService
      .updateOrgCAFDonationData(
        this.surveyDataInput.organizationId,
        this.isCafDonationEnabled,
        this.gobForm['cafDonationUrl'].valueControl.value
      )
      .subscribe(
        (res) => {
          this.notificationsService.success(
            this.translateService.instant('GeneralOrganizationalBackgroundSurveyComponent-TITLE'),
            this.translateService.instant('GeneralOrganizationalBackgroundSurveyComponent-TOAST_SUCCESS_CAF_SAVED')
          );
          currentModal.emit({ action: 'modal', params: ['close'] });

          this.isSubmitting = false;
          this.surveyUpdated = false;
          // this causes the survey to be refreshed - `surveyInterface` is a set() method defined above
          this.surveyDataInput = null;
          setTimeout(() => (this.surveyInterface = this.gOBSurveyInterface), 0);
          this.hideOverlay();
        },
        (error) => {
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'GeneralOrganizationalBackgroundSurveyComponent-TITLE'
          );
          this.isSubmitting = false;
          this.surveyUpdated = false;
        }
      );
  }
}
