<app-loader [loading]="loading"></app-loader>
<ng-template [ngIf]="latestGobSurvey">
  <div class="row">
    <div class="col s12">
      <nav class="breadcrumb-nav">
        <div class="nav-wrapper">
          <div class="col s12 breadcrumb-div">
            <a routerLink="/" class="breadcrumb">
              {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
            </a>
            <a class="breadcrumb sr-link">
              {{ orgName }}
            </a>
          </div>
        </div>
      </nav>
      <div class="divider"></div>
    </div>
  </div>

  <div class="page-container">
    <div class="row">
      <div class="col s8 offset-s2 m3 l2 center">
        <img
          alt="organization logo"
          src="{{ organization?.logoURI ? organization?.logoURI : 'assets/images/org-default.png' }}"
          class="collection-img responsive-img"
        />

        <div class="sr-bold donate-text">
          <a
            target="_blank"
            [href]="latestGobSurvey.donateUrl?.value | linkHttp"
            (click)="openDonateModal()"
            [ngClass]="{
              disabled:
                latestGobSurvey.donateButtonDisabled ||
                latestGobSurvey.donateUrl?.value === '' ||
                latestGobSurvey.donateUrl?.value === null
            }"
            class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
          >
            {{ 'COMMON_SEARCH-DONATE_CARD_1' | translate }}
          </a>
          <ng-template [ngIf]="iscafDonationEnabled">
            <a
              target="_blank"
              [href]="latestGobSurvey.organizationDto?.cafDonationUrl | linkHttp"
              class="big-donate-btn btn red lighten-1 waves-effect waves-light"
            >
              <span>{{ 'COMMON_SEARCH-DONATE-VIA' | translate }}</span>
              <img
                src="assets/images/caf-america-logo.png"
                alt="{{ 'COMMON_SEARCH-DONATE-VIA-CAF-AMERICA' | translate }}"
                class="caf-logo"
              />
            </a>
          </ng-template>
          <a
            target="_blank"
            (click)="requestGobSurveyUpdate()"
            [href]=""
            [ngClass]=""
            class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
          >
            {{ 'COMMON_SEARCH-REQUEST-SURVEY-UPDATE' | translate }}
          </a>
        </div>
      </div>

      <!-- Donation Modal -->
      <div
        id="donateModal"
        class="modal modal-fixed-footer"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="donateModalActions"
      >
        <donation-modal
          [orgName]="orgName"
          [orgId]="orgId"
          [donateUrl]="latestGobSurvey.donateUrl?.value"
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
        >
        </donation-modal>
      </div>

      <!--Tipping Modal-->
      <div
        id="tippingModal"
        class="modal modal-fixed-footer"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="tippingModalActions"
      >
        <givelively-donation
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
          [tippingText]="true"
        >
        </givelively-donation>
      </div>

      <div class="col s12 m9 l6">
        <div class="sr-bold black-text lighten-1">
          <h5>{{ orgName }}</h5>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_MISSION' | translate }}
          </span>

          <div class="sr-justify">
            <survey-response [value]="latestGobSurvey?.mission?.value">
              {{ latestGobSurvey?.mission?.value }}
            </survey-response>
          </div>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_HQ' | translate }}
          </span>

          <div>
            <survey-response [value]="address">
              {{ address | locationify | translate }}
            </survey-response>
          </div>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_SUMMARY' | translate }}
          </span>

          <div class="sr-justify sr-breakword">
            <survey-response [value]="latestGobSurvey?.summary?.value">
              <div [innerHTML]="latestGobSurvey?.summary?.value"></div>
            </survey-response>
          </div>
        </div>
      </div>
      <div class="col s12 m12 l4 center">
        <div class="col s12 m6 l12 left-padding center">
          <div class="card org-card">
            <div class="card-content">
              <div>
                <p class="center blue-text sr-bold text-size">
                  {{ 'OrgBackgroundComponent-Q_ANNUAL_BUDGET' | translate: { orgName: orgName } }}
                </p>
                <div class="divider"></div>

                <div class="grey-text">
                  <last-updated-icon-and-timestamp
                    [surveyType]="getSurveyType()"
                    [timestamp]="getTimestampForResponse('overallAnnualBudget')"
                  >
                  </last-updated-icon-and-timestamp>
                </div>

                <div
                  class="center sr-bold text"
                  *ngIf="latestGobSurvey?.overallAnnualBudget?.value?.amount; else noResponseOABudgetAmount"
                >
                  <survey-response [value]="money?.singles?.budget?.converted?.amount" class="center sr-bold text">
                    {{
                      money?.singles?.budget?.converted?.amount
                        | customCurrency: currency?.map[money?.singles?.budget?.converted?.currency]
                    }}
                  </survey-response>
                </div>
                <ng-template #noResponseOABudgetAmount>
                  <div class="center sr-bold text">
                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="col s12 m6 l12 right-padding center">
          <div class="card org-card">
            <div class="card-content">
              <div>
                <p class="center blue-text sr-bold text-size">
                  {{ 'OrgBackgroundComponent-Q_TOTAL_STAFF' | translate: { orgName: orgName } }}
                </p>
                <div class="divider"></div>

                <last-updated-icon-and-timestamp
                  [surveyType]="getSurveyType()"
                  [timestamp]="getTimestampForResponse('staffCount')"
                >
                </last-updated-icon-and-timestamp>

                <div class="center sr-bold text">
                  <survey-response [value]="latestGobSurvey?.staffCount?.value">
                    {{ latestGobSurvey?.staffCount?.value }}
                  </survey-response>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col s12 m6 l12 right-padding center">
          <share-buttons-custom [metadata]="organization?.metadata"> </share-buttons-custom>
        </div>
      </div>
    </div>

    <div class="row state-info">
      <div class="col s12 m8 l7 left">
        <div class="row report-note">
          <div class="col valign-wrapper">
            <i class="material-icons green-text text-darken-3">calendar_today</i
            >{{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_CURRENT' | translate }}
          </div>
          <div class="col valign-wrapper">
            <i class="material-icons orange-text text-darken-3">calendar_today</i
            >{{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_NO_RESPONSE' | translate }}
          </div>
        </div>
        <div class="report-note">
          <span>
            {{ 'COMMON_SEARCH-COLLAPSIBLE_FLAG_NOTE' | translate }}
          </span>
          <i class="tiny material-icons cursor">flag</i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m12 l6">
        <div>
          <ul materialize="collapsible" class="collapsible" data-collapsible="accordion">
            <li id="li0">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">contacts</i>
                {{ 'COMMON_SEARCH-SERVICES_PROVIDED_TITLE' | translate }}
              </div>

              <div class="collapsible-body text-size">
                <div class="lists row">
                  <div class="survey">
                    <div class="question">
                      <span class="subheading sr-bold">
                        {{ 'OrgBackgroundComponent-Q_SERVICES_PROVIDED_TITLE' | translate }}
                      </span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp [surveyType]="NA" [timestamp]="serviceList?.lastUpdatedDate">
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                    <div class="divider"></div>
                    <div *ngIf="!serviceList?.length">
                      <survey-response></survey-response>
                    </div>
                    <!-- <div class="my_table1">
                          <table class="centered">
                              <thead>
                                  <tr>
                                      <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_SERVICES' | translate}}</th>
                                      <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_DIRECT' | translate}}</th>
                                      <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_INDIRECT' | translate}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let item of serviceList">
                                      <td>
                                        <div>
                                          <i
                                          (click)="openReport('serviceList', 'OrgBackgroundComponent-Q_SERVICES_PROVIDED_TITLE')"
                                          class="material-icons statusFlag red-text" >flag</i>
                                          {{item?.serviceName}}
                                        </div>
                                      </td>
                                      <td class="response">
                                        {{item?.direct}} %
                                      </td>
                                      <td class="response">
                                        {{item?.indirect}} %
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div> -->
                    <!-- When services provided percentages are fixed, replace the table below with the commented table above-->
                    <div class="my_table1">
                      <ul *ngFor="let item of serviceList">
                        <li class="serviceList-item">
                          <i
                            (click)="openReport('serviceList', 'OrgBackgroundComponent-Q_SERVICES_PROVIDED_TITLE')"
                            class="material-icons statusFlag red-text"
                            >flag</i
                          >
                          {{ item?.serviceName }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li id="li1">
              <div class="collapsible-header text-size blue-text sr-bold" [ngClass]="{ active: !videoId }">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">contacts</i>
                {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_HQ' | translate }}
              </div>

              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      (click)="openReport('hqAddress', 'COMMON_SEARCH-TITLE_ADDRESS')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="subheading sr-bold">{{ 'COMMON_SEARCH-TITLE_ADDRESS' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('hqAddress')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>

                  <div class="row address">
                    <div class="col s12">
                      <survey-response
                        [value]="latestGobSurvey?.hqAddress?.value?.address1"
                        class="sr-justify response"
                      >
                        {{ latestGobSurvey?.hqAddress?.value?.address1 }}
                      </survey-response>
                    </div>

                    <div class="col s12 response">
                      {{ latestGobSurvey?.hqAddress?.value?.address2 }}
                    </div>

                    <div class="col s12">
                      <span class="sr-bold sr-justify response">{{ 'UNIVERSAL-ADDRESS_CITY' | translate }}:&nbsp;</span>
                      <survey-response [value]="latestGobSurvey?.hqAddress?.value?.city" class="sr-justify response">
                        {{ latestGobSurvey?.hqAddress?.value?.city }}
                      </survey-response>
                    </div>

                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqAddress?.value?.state" class="sr-justify response">
                        {{ latestGobSurvey?.hqAddress?.value?.state }}
                      </survey-response>
                    </div>

                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqAddress?.value?.zip" class="sr-justify response">
                        {{ latestGobSurvey?.hqAddress?.value?.zip }}
                      </survey-response>
                    </div>

                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}:&nbsp;</span
                      >
                      <survey-response
                        [value]="latestGobSurvey?.hqAddress?.value?.country?.name"
                        class="sr-justify response"
                      >
                        {{ latestGobSurvey?.hqAddress?.value?.country?.name }}
                      </survey-response>
                    </div>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('hqContact', 'COMMON_SEARCH-TITLE_POINT_OF_CONTACT')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="subheading sr-bold">{{ 'COMMON_SEARCH-TITLE_POINT_OF_CONTACT' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('hqContact')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>

                  <div class="row contact">
                    <div class="col s12">
                      <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-CONTACT_NAME' | translate }}:&nbsp;</span>
                      <survey-response
                        [value]="latestGobSurvey?.hqContact?.value?.firstName"
                        class="sr-justify response"
                      >
                        {{ latestGobSurvey?.hqContact?.value?.firstName }}
                      </survey-response>
                      <span class="response">
                        {{ latestGobSurvey?.hqContact?.value?.lastName }}
                      </span>
                    </div>

                    <div class="col s12">
                      <span class="sr-justify response sr-bold"
                        >{{ 'UNIVERSAL-CONTACT_TITLE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqContact?.value?.title" class="sr-justify response">
                        {{ latestGobSurvey?.hqContact?.value?.title }}
                      </survey-response>
                    </div>

                    <div class="col s12">
                      <span class="sr-justify response sr-bold"
                        >{{ 'UNIVERSAL-CONTACT_PHONE_NUMBERED_ONE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqContact?.value?.phone1" class="sr-justify response">
                        {{ latestGobSurvey?.hqContact?.value?.phone1 }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-justify response sr-bold"
                        >{{ 'UNIVERSAL-CONTACT_PHONE_NUMBERED_TWO' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqContact?.value?.phone2" class="sr-justify response">
                        {{ latestGobSurvey?.hqContact?.value?.phone2 }}
                      </survey-response>
                    </div>

                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-CONTACT_EMAIL_NUMBERED_ONE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestGobSurvey?.hqContact?.value?.email" class="sr-justify response">
                        {{ latestGobSurvey?.hqContact?.value?.email }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-CONTACT_EMAIL_NUMBERED_TWO' | translate }}:&nbsp;</span
                      >
                      <survey-response
                        [value]="latestGobSurvey?.hqContact?.value?.alternativeEmail"
                        class="sr-justify response"
                      >
                        {{ latestGobSurvey?.hqContact?.value?.alternativeEmail }}
                      </survey-response>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li id="li2">
              <div class="collapsible-header text-size blue-text sr-bold">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">info</i>
                {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_BACKGROUND' | translate }}
              </div>

              <div class="collapsible-body">
                <div class="survey">
                  <div>
                    <span class="subheading sr-bold">
                      {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_BASIC_INFO' | translate }}
                    </span>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('govIdOrEin', 'OrgBackgroundComponent-Q_ORG_ID')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_ORG_ID' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('govIdOrEin')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.govIdOrEin?.value" class="sr-justify response">
                    {{ latestGobSurvey?.govIdOrEin?.value }}
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('govIdOrEin2', 'OrgBackgroundComponent-Q_ORG_ID2')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_ORG_ID2' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('govIdOrEin2')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.govIdOrEin2?.value" class="sr-justify response">
                    {{ latestGobSurvey?.govIdOrEin2?.value }}
                  </survey-response>

                  <div>
                    <span class="subheading sr-bold">
                      {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_BACKGROUND_INFO' | translate }}
                    </span>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('mission', 'OrgBackgroundComponent-Q_MISSION')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">
                      {{ 'OrgBackgroundComponent-Q_MISSION' | translate }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('mission')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.mission?.value" class="sr-justify response">
                    {{ latestGobSurvey?.mission?.value }}
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('summary', 'OrgBackgroundComponent-Q_SUMMARY')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_SUMMARY' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('summary')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response
                    [value]="latestGobSurvey?.summary?.value"
                    class="sr-justify sr-breakword response summary"
                  >
                    <div [innerHTML]="latestGobSurvey?.summary?.value"></div>
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('orgType', 'OrgBackgroundComponent-Q_ORG_TYPE')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >
                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_ORG_TYPE' | translate }}</span>
                  </div>
                  <div class="divider"></div>
                  <div class="sr-justify response">
                    <div>
                      <span class="sr-bold">
                        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE1' | translate }}
                        :
                      </span>
                      <span>
                        <survey-response [value]="latestGobSurvey?.orgTypeFaithBased?.value">
                          <span *ngIf="latestGobSurvey?.orgTypeFaithBased?.value === true">
                            {{ 'UNIVERSAL-YES' | translate }}
                          </span>
                          <span *ngIf="latestGobSurvey?.orgTypeFaithBased?.value === false">
                            {{ 'UNIVERSAL-NO' | translate }}
                          </span>
                        </survey-response>
                      </span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('orgTypeFaithBased')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>

                    <div>
                      <span class="sr-bold">
                        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE4' | translate }}
                        :
                      </span>
                      <span>
                        <survey-response [value]="latestGobSurvey?.orgTypeInternationalNGO?.value">
                          <span *ngIf="latestGobSurvey?.orgTypeInternationalNGO?.value === true">
                            {{ 'UNIVERSAL-YES' | translate }}
                          </span>
                          <span *ngIf="latestGobSurvey?.orgTypeInternationalNGO?.value === false">
                            {{ 'UNIVERSAL-NO' | translate }}
                          </span>
                        </survey-response>
                      </span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('orgTypeInternationalNGO')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>

                    <div>
                      <span class="sr-bold">
                        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE2' | translate }}
                        :
                      </span>
                      <span>
                        <survey-response [value]="latestGobSurvey?.orgTypeNationalOrganization?.value">
                          <span *ngIf="latestGobSurvey?.orgTypeNationalOrganization?.value === true">
                            {{ 'UNIVERSAL-YES' | translate }}
                          </span>
                          <span *ngIf="latestGobSurvey?.orgTypeNationalOrganization?.value === false">
                            {{ 'UNIVERSAL-NO' | translate }}
                          </span>
                        </survey-response>
                      </span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('orgTypeNationalOrganization')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>

                    <div>
                      <span class="sr-bold">
                        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE3' | translate }}
                        :
                      </span>
                      <span>
                        <survey-response [value]="latestGobSurvey?.orgTypeLocalCivilSociety?.value">
                          <span *ngIf="latestGobSurvey?.orgTypeLocalCivilSociety?.value === true">
                            {{ 'UNIVERSAL-YES' | translate }}
                          </span>
                          <span *ngIf="latestGobSurvey?.orgTypeLocalCivilSociety?.value === false">
                            {{ 'UNIVERSAL-NO' | translate }}
                          </span>
                        </survey-response>
                      </span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('orgTypeLocalCivilSociety')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('annualBudgetType', 'OrgBackgroundComponent-Q_OVERALL_BUDGET')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_OVERALL_BUDGET' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('annualBudgetType')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.annualBudgetType?.value" class="sr-justify response">
                    {{ latestGobSurvey?.annualBudgetType?.value?.name }}
                  </survey-response>

                  <div>
                    <span class="subheading sr-bold">
                      {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_OTHER_INFO' | translate }}
                    </span>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('website', 'OrgBackgroundComponent-Q_ORG_WEBSITE')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_ORG_WEBSITE' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('website')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.website?.value" class="sr-justify response">
                    <a target="_blank" [href]="latestGobSurvey?.website?.value | linkHttp">{{
                      latestGobSurvey?.website?.value
                    }}</a>
                  </survey-response>

                  <!---- Facebook URL -->
                  <div class="question">
                    <i
                      (click)="openReport('website', 'OrgBackgroundComponent-Q_ORG_FACEBOOK_URL')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_ORG_FACEBOOK_URL' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('facebookUrl')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response sr-justify" *ngIf="latestGobSurvey?.facebookUrl?.value; else noResponseFBUrl">
                    <survey-response [value]="latestGobSurvey?.facebookUrl?.value" class="sr-justify response">
                      <a target="_blank" [href]="latestGobSurvey?.facebookUrl?.value | linkHttp">{{
                        latestGobSurvey?.facebookUrl?.value
                      }}</a>
                    </survey-response>
                  </div>
                  <ng-template #noResponseFBUrl>
                    <div
                      class="response sr-justify"
                      *ngIf="latestGobSurvey?.facebookUrl?.value == '' || latestGobSurvey?.facebookUrl?.value == null"
                    >
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                  </ng-template>
                  <!---- End Facebook URL -->

                  <div *ngIf="isCountryUSA">
                    <div class="question">
                      <i
                        (click)="openReport('guideStarUrl', 'OrgBackgroundComponent-Q_GUIDESTAR_URL')"
                        class="material-icons statusFlag red-text"
                        >flag</i
                      >

                      <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_GUIDESTAR_URL' | translate }}</span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('guideStarUrl')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                    <div class="divider"></div>
                    <survey-response [value]="guideStarUrl" class="sr-justify response">
                      <a target="_blank" [href]="guideStarUrl | linkHttp">{{ guideStarUrl }}</a>
                    </survey-response>

                    <div class="question">
                      <i
                        (click)="openReport('charityNavigatorUrl', 'OrgBackgroundComponent-Q_CHARITY_NAVIGATOR_URL')"
                        class="material-icons statusFlag red-text"
                        >flag</i
                      >

                      <span class="sr-bold question">{{
                        'OrgBackgroundComponent-Q_CHARITY_NAVIGATOR_URL' | translate
                      }}</span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="getSurveyType()"
                          [timestamp]="getTimestampForResponse('charityNavigatorUrl')"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                    <div class="divider"></div>
                    <survey-response [value]="charityNavigatorUrl" class="sr-justify response">
                      <a target="_blank" [href]="charityNavigatorUrl | linkHttp">{{ charityNavigatorUrl }}</a>
                    </survey-response>
                  </div>

                  <div class="question">
                    <i
                      (click)="openReport('fiscalYearEnding', 'OrgBackgroundComponent-Q_FISCAL_YEAR_ENDING')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_FISCAL_YEAR_ENDING' | translate
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('fiscalYearEnding')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.fiscalYearEnding?.value" class="sr-justify response">
                    {{ latestGobSurvey?.fiscalYearEnding?.value }}
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('staffCount', 'OrgBackgroundComponent-Q_TOTAL_STAFF')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_TOTAL_STAFF' | translate: { orgName: orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('staffCount')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.staffCount?.value" class="sr-justify response">
                    {{ latestGobSurvey?.staffCount?.value }}
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('hqStaffCount', 'OrgBackgroundComponent-Q_HQ_STAFF')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_HQ_STAFF' | translate: { orgName: orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('hqStaffCount')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.hqStaffCount?.value" class="sr-justify response">
                    {{ latestGobSurvey?.hqStaffCount?.value }}
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('twitterHandle', 'OrgBackgroundComponent-Q_TWITTER')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{ 'OrgBackgroundComponent-Q_TWITTER' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('twitterHandle')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.twitterHandle?.value" class="sr-justify response">
                    <a target="_blank" href="https://www.twitter.com/{{ latestGobSurvey?.twitterHandle?.value }}">{{
                      latestGobSurvey?.twitterHandle?.value
                    }}</a>
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('discriminationPolicy', 'OrgBackgroundComponent-Q_POLICY_DISCRIMINATION')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_POLICY_DISCRIMINATION' | translate: { orgName: orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('discriminationPolicy')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.discriminationPolicy?.value" class="sr-justify response">
                    <span *ngIf="latestGobSurvey?.discriminationPolicy?.value === true">
                      {{ 'UNIVERSAL-YES' | translate }}
                    </span>
                    <span *ngIf="latestGobSurvey?.discriminationPolicy?.value === false">
                      {{ 'UNIVERSAL-NO' | translate }}
                    </span>
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('religiousAffiliation', 'OrgBackgroundComponent-Q_AFFILIATION_RELIGIOUS')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_AFFILIATION_RELIGIOUS' | translate: { orgName: orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('religiousAffiliation')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.religiousAffiliation?.value" class="sr-justify response">
                    <span *ngIf="latestGobSurvey?.religiousAffiliation?.value === true">
                      {{ 'UNIVERSAL-YES' | translate }}
                    </span>
                    <span *ngIf="latestGobSurvey?.religiousAffiliation?.value === false">
                      {{ 'UNIVERSAL-NO' | translate }}
                    </span>
                  </survey-response>

                  <div class="question">
                    <i
                      (click)="openReport('whistleblowerPolicy', 'OrgBackgroundComponent-Q_POLICY_WHISTLEBLOWER')"
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >

                    <span class="sr-bold question">{{
                      'OrgBackgroundComponent-Q_POLICY_WHISTLEBLOWER' | translate: { orgName: orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="getSurveyType()"
                        [timestamp]="getTimestampForResponse('whistleblowerPolicy')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <survey-response [value]="latestGobSurvey?.whistleblowerPolicy?.value" class="sr-justify response">
                    <span *ngIf="latestGobSurvey?.whistleblowerPolicy?.value === true">
                      {{ 'UNIVERSAL-YES' | translate }}
                    </span>
                    <span *ngIf="latestGobSurvey?.whistleblowerPolicy?.value === false">
                      {{ 'UNIVERSAL-NO' | translate }}
                    </span>
                  </survey-response>
                </div>
              </div>
            </li>
            <li id="li3">
              <div
                id="orgBackgroundOrgIntroVideo"
                class="collapsible-header sr-bold blue-text text-size"
                [ngClass]="{ active: videoId }"
              >
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">video_library</i>
                {{ 'COMMON_SEARCH-SERVICES_BACKGROUND_VIDEO' | translate }}
              </div>

              <div class="collapsible-body text-size">
                <div class="lists row">
                  <div class="survey">
                    <div class="question">
                      <!--Video URL-->
                      <div class="question">
                        <i
                          class="material-icons statusFlag red-text"
                          (click)="
                            openReport(
                              'orgIntroVideoUrl',
                              this.translateService.instant('OrgBackgroundComponent-Q_ORG_VIDEO_URL', {
                                orgName: orgName
                              })
                            )
                          "
                        >
                          flag
                        </i>

                        <span class="sr-bold question">
                          {{ 'OrgBackgroundComponent-Q_ORG_VIDEO_URL' | translate: { orgName: orgName } }}
                        </span>
                      </div>
                      <div class="dt-stamp">
                        <span class="left">
                          <last-updated-icon-and-timestamp
                            [surveyType]="getSurveyType()"
                            [timestamp]="getTimestampForResponse('orgIntroVideoUrl')"
                          >
                          </last-updated-icon-and-timestamp>
                        </span>
                      </div>
                      <div class="divider"></div>
                      <div *ngIf="!latestGobSurvey?.orgIntroVideoUrl?.value">
                        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                      </div>
                      <div *ngIf="latestGobSurvey?.orgIntroVideoUrl?.value">
                        <survey-response [value]="latestGobSurvey?.orgIntroVideoUrl?.value" class="sr-justify response">
                          <parse-video-url
                            [source]="latestGobSurvey?.orgIntroVideoUrl?.value"
                            videoId="orgBackgroundOrgIntroVideo"
                            [autoplay]="videoId === 'orgBackgroundOrgIntroVideo'"
                          ></parse-video-url>
                        </survey-response>
                      </div>

                      <div id="orgBackgroundStaffIntroVideo" class="question">
                        <i
                          class="material-icons statusFlag red-text"
                          (click)="
                            openReport(
                              'staffIntroVideoUrl',
                              this.translateService.instant('OrgBackgroundComponent-Q_STAFF_VIDEO_URL', {
                                orgName: orgName
                              })
                            )
                          "
                        >
                          flag
                        </i>

                        <span class="sr-bold question">{{
                          'OrgBackgroundComponent-Q_STAFF_VIDEO_URL' | translate: { orgName: orgName }
                        }}</span>
                      </div>
                      <div class="dt-stamp">
                        <span class="left">
                          <last-updated-icon-and-timestamp
                            [surveyType]="getSurveyType()"
                            [timestamp]="getTimestampForResponse('staffIntroVideoUrl')"
                          >
                          </last-updated-icon-and-timestamp>
                        </span>
                      </div>
                      <div class="divider"></div>
                      <div *ngIf="!latestGobSurvey?.staffIntroVideoUrl?.value">
                        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                      </div>
                      <div *ngIf="latestGobSurvey?.staffIntroVideoUrl?.value">
                        <survey-response
                          [value]="latestGobSurvey?.staffIntroVideoUrl?.value"
                          class="sr-justify response"
                        >
                          <parse-video-url
                            [source]="latestGobSurvey?.staffIntroVideoUrl?.value"
                            videoId="orgBackgroundStaffIntroVideo"
                            [autoplay]="videoId === 'orgBackgroundStaffIntroVideo'"
                          ></parse-video-url>
                        </survey-response>
                      </div>
                      <!--ended Video URL-->
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp [surveyType]="NA" [timestamp]="serviceList?.lastUpdatedDate">
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col s12 m12 l6">
        <div class="">
          <ul materialize="collapsible" class="collapsible text-size" data-collapsible="accordion">
            <li>
              <div
                [ngClass]="{ active: latestGobSurvey?.locationContactList?.length }"
                class="collapsible-header blue-text sr-bold"
              >
                <i class="material-icons expand-more">expand_more</i
                >{{ 'OrgBackgroundComponent-COLLAPSIBLE_LOCATIONS_INVOLVED' | translate: { orgName: orgName } }}
              </div>

              <div class="collapsible-body">
                <ul class="lists row">
                  <li>
                    <div *ngIf="!latestGobSurvey?.locationContactList?.length">
                      <survey-response></survey-response>
                    </div>
                  </li>
                  <li class="my-list col s6" *ngFor="let location of latestGobSurvey?.locationContactList">
                    <a [routerLink]="['/org-location', latestGobSurvey.organizationId, location.value?.countryId]">
                      {{ location.value?.countryName }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div class="collapsible-header blue-text sr-bold">
                <i class="material-icons expand-more">expand_more</i>
                {{ 'OrgBackgroundComponent-COLLAPSIBLE_DISASTERS_INVOLVED' | translate: { orgName: orgName } }}
              </div>

              <div class="collapsible-body">
                <ul class="lists row">
                  <!-- todo discover source -->
                  <li class="my-list col s12 m6" *ngFor="let disasterSurvey of disasterSpecificSurvey">
                    <a
                      [routerLink]="[
                        '/org-disaster',
                        latestGobSurvey.organizationId,
                        disasterSurvey.disaster.disasterId
                      ]"
                      >{{ disasterSurvey.disaster.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<report-modal [reportActions]="reportActions"></report-modal>
