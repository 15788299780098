import { map, switchMap, zip } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ResponseProfileComponent } from '../responseProfile/response-profile.component';
import { handleErrors } from '../../common/error';
import { GlobalLoaderFacade } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { ReportInputModel, ReportSurveyTypes } from '../reportModal/report.model';

import { ListComponentService } from '../../dashboard/servicesComponets/configureLists/list/list.service';
import {
  RELIEF_RECOVERY_CONFIG,
  VETTING_AGENCIES_CONFIG,
} from '../../dashboard/servicesComponets/configureLists/lists.component';
import { VettingAgencyInfo } from '../../models/VettingAgencyInfo';

import { ReliefRecoveryItem } from '../../models/ReliefRecoveryItem';

import {
  Collection,
  ConvertedMoney,
  Dictionary,
  LocationSpecificSurveyInput,
  LocationSpecificSurveyServiceListValue,
  Money,
  Organization,
  Service,
} from '../../models';
import { ServicesProvidedService } from '../../dashboard/servicesComponets/service/service.service';

import { OrganizationService } from '../../dashboard/servicesComponets/organizations/organization.service';

import { LocationSpecificSurveyService } from '../../dashboard/servicesComponets/surveys/locationSpecificSurvey/location-specific-survey.service';

import { DocumentService } from '../../sharedServices/documentService/document.service';

import { DonationCampaignByLocationService } from '../donationHelper/donation-campaign-by-location.service';
import { formatStringList, validateResponseField } from '../survey-response-helpers';

import { AppSettings } from '../../common/config';
import { GeneralOrganizationBackgroundSurveyInput } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.model';

import { GeneralOrganizationBackgroundSurveyService } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';
import { Address } from '../../pipes/locationPipe';
import { LocationMapComponent } from '../../common/locationMap/location-map.component';
import { MaterializeAction } from 'angular2-materialize';
import { CountryListService } from '../../dashboard/servicesComponets/surveys/shared/countryList.service';
import { CurrencyService } from 'app/sharedServices/currencyService/currency.service';
import { CurrencyPickerService } from 'app/header/currencyPicker/currency-picker.service';
declare let $: any;

interface StaffTotals {
  local: number;
  nonLocal: number;
  volunteer: number;
  hiredMgmt: number;
  hiredProf: number;
  hiredSupp: number;
}

interface OrgLocationModel {
  staffTotals: StaffTotals;
  engagements: string[];
  memberships: string[];
}

@Component({
  selector: 'org-location',
  templateUrl: './org-location.component.html',
  styleUrls: ['./org-location.component.css', '../common-search.css'],
  providers: [ListComponentService, DonationCampaignByLocationService],
})
export class OrgLocationComponent extends ResponseProfileComponent implements OnInit {
  public orgId: number;
  public locationId: number;
  public context: OrgLocationModel = {
    staffTotals: {
      local: null,
      nonLocal: null,
      volunteer: null,
      hiredMgmt: null,
      hiredProf: null,
      hiredSupp: null,
    },
    engagements: null,
    memberships: null,
  };
  public organization: Organization;
  public orgLocationMetadata: string;
  public address: Address;
  public latestGobSurvey: GeneralOrganizationBackgroundSurveyInput;
  public latestLssSurvey: LocationSpecificSurveyInput;
  public reliefRecoveryItems: { [id: number]: ReliefRecoveryItem } = {};
  public serviceProvidedItems: { [id: number]: Service } = {};
  vettingAgencyInfo = [];

  public AppSettings = AppSettings;
  public disasterStageNames: { [id: string]: string } = {};
  public reportActions: EventEmitter<ReportInputModel> = new EventEmitter<ReportInputModel>();
  public videoId: string = '';
  title: string = 'OrgLocationComponent-TITLE';
  loading: boolean;
  subscriptions: Dictionary<Subscription> = {};
  donateButtonDisabled: boolean;
  donateModalActions = new EventEmitter<string | MaterializeAction>();
  tippingModalActions = new EventEmitter<string | MaterializeAction>();
  isSubmitting: boolean = false;
  hideAcceptImportedGoodsSection = false;
  public iscafDonationEnabled: boolean = false;

  money: Collection<ConvertedMoney> = {
    singles: {
      budget: null,
    },
  };

  @ViewChild(LocationMapComponent, { static: false }) locationsMap: LocationMapComponent;

  params = [
    {
      onOpen: (el) => {
        if (el.attr('id') === 'li7' && this.locationsMap) {
          this.locationsMap.resize();
        }
      },
    },
  ];

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private lssSurveyService: LocationSpecificSurveyService,
    private servicesProvidedService: ServicesProvidedService,
    private organizationService: OrganizationService,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private listService: ListComponentService,
    private globalLoader: GlobalLoaderFacade,
    private documentService: DocumentService,
    private donationCampaignByLocationService: DonationCampaignByLocationService,
    private countryListService: CountryListService,
    private currencyService: CurrencyService,
    private currencyPickerService: CurrencyPickerService,
    private route: ActivatedRoute
  ) {
    super(currencyService, currencyPickerService, notificationsService, translateService);
  }

  ngOnInit() {
    this.globalLoader.start();
    this.loading = true;

    this.addSubscription('route', this.getRouteSubscription.bind(this));
  }

  ngAfterViewInit() {
    $('.collapsible').collapsible({
      accordion: true,
    });
  }

  private getRouteSubscription(): Subscription {
    return combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        map(([params, queryParams]) => ({
          params,
          queryParams,
        })),
        switchMap(({ params, queryParams }) => {
          this.orgId = +params['org_id'];
          this.locationId = +params['loc_id'];
          this.videoId = queryParams['videoId'];
          const lssSurveyObservable = this.lssSurveyService.getMostRecentSurvey(+params['org_id'], +params['loc_id']);
          const servicesProvidedObservable = this.servicesProvidedService.getServices();
          const orgLocationMetadataObservable = this.organizationService.getOrgLocationMetadata(
            +params['org_id'],
            +params['loc_id']
          );
          const organizationObservable = this.organizationService.getOrganization(+params['org_id']);
          const reliefItemsObservable = this.listService.getDefault(RELIEF_RECOVERY_CONFIG.endpoint);
          const vettingAgencyInfoOservable = this.listService.getDefault(VETTING_AGENCIES_CONFIG.endpoint);
          const locationDonationObservable = this.donationCampaignByLocationService.getDonationCampaignByLocation(
            +params['loc_id']
          );

          return lssSurveyObservable.pipe(
            zip(
              this.gobService.getMostRecentSurvey(this.orgId),
              organizationObservable,
              orgLocationMetadataObservable,
              reliefItemsObservable,
              vettingAgencyInfoOservable,
              servicesProvidedObservable,
              locationDonationObservable,
              (
                survey,
                gob,
                org,
                orgLocationMetadata,
                reliefTypeList,
                vettingAgencyInfo,
                servicesProvidedList,
                locationDonations
              ) => ({
                survey,
                gob,
                org,
                orgLocationMetadata,
                reliefTypeList,
                vettingAgencyInfo,
                servicesProvidedList,
                locationDonations,
              })
            )
          );
        })
      )
      .subscribe(
        (success: any) => {
          this.globalLoader.complete();
          this.loading = false;
          this.latestGobSurvey = success.gob;
          this.latestLssSurvey = success.survey;
          this.organization = success.org;
          this.orgLocationMetadata = success.orgLocationMetadata;
          this.donateButtonDisabled = this.latestLssSurvey.donateButtonDisabled;
          this.iscafDonationEnabled = this.latestGobSurvey.organizationDto.cafDonationEnabled;
          // filter services if both direct and indirect proportions are 0.
          if (success.survey && success.survey.serviceList && success.survey.serviceList.value) {
            this.latestLssSurvey.serviceList.value = this.latestLssSurvey.serviceList.value.filter(
              (service) => service.directProportion || service.indirectProportion
            ) as [LocationSpecificSurveyServiceListValue];
          }

          this.address = {
            city: this.organization.city,
            state: this.organization.state,
            country: this.organization.country,
          };

          for (const entry of success.reliefTypeList) {
            this.reliefRecoveryItems[entry.id] = entry;
          }
          success.vettingAgencyInfo.forEach((val) => {
            val.showAgency = false;
            this.vettingAgencyInfo.push(val);
          });
          for (const service of success.servicesProvidedList) {
            this.serviceProvidedItems[service.serviceId] = service;
          }

          this.setContext();

          // Set displayable names for disaster stages
          this.disasterStageNames['mitigationStage'] = this.translateService.instant('SurveyCommon-Q_DS_MITI');
          this.disasterStageNames['reliefStage'] = this.translateService.instant('SurveyCommon-Q_DS_RELI');
          this.disasterStageNames['recoveryStage'] = this.translateService.instant('SurveyCommon-Q_DS_RECO');
          this.disasterStageNames['responseStage'] = this.translateService.instant('SurveyCommon-Q_DS_RESP');
          this.disasterStageNames['preparedStage'] = this.translateService.instant('SurveyCommon-Q_DS_PREP');

          this.checkForCountry();

          setTimeout(() => {
            const element = document.getElementById(this.videoId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 1000);
        },
        (error) => {
          this.globalLoader.complete();
          this.loading = false;
          handleErrors(error, this.translateService, this.notificationsService, 'OrgLocationComponent-TITLE');
        }
      );
  }

  private setContext() {
    if (this.latestLssSurvey) {
      this.context.staffTotals.local = this.getOverviewStaffCount('localStaff');
      this.context.staffTotals.nonLocal = this.getOverviewStaffCount('nonLocalStaff');
      this.context.staffTotals.volunteer = this.getOverviewStaffCount('volunteerStaff');
      this.context.staffTotals.hiredMgmt = this.getHiredStaffCount('mgmtStaffCount');
      this.context.staffTotals.hiredProf = this.getHiredStaffCount('professionalStaffCount');
      this.context.staffTotals.hiredSupp = this.getHiredStaffCount('supportStaffCount');
      this.context.engagements = this.getEngagements();
      this.context.memberships = this.getMemberships();
      this.initMoneyAmts();
      this.addSubscription('currency', this.getCurrencySubscription.bind(this));
    }
  }

  private getCurrencySubscription(): Subscription {
    return this.setupCurrencyContext().subscribe(([conversions, formats]) => {
      this.setCurrencies(conversions.currency, formats);
      this.money = conversions.money;
    });
  }

  private initMoneyAmts(): void {
    const singleFunds: Dictionary<Money> = {
      budget: validateResponseField<Money>(this.latestLssSurvey.overallBudget),
    };

    for (const [category, value] of Object.entries(singleFunds)) {
      this.money.singles[category] = new ConvertedMoney(value);
    }
  }

  isActive() {
    if (
      $('#li1').hasClass('active') ||
      $('#li2').hasClass('active') ||
      $('#li3').hasClass('active') ||
      $('#li4').hasClass('active') ||
      $('#li5').hasClass('active') ||
      $('#li6').hasClass('active')
    ) {
      console.log('active');
      return true;
    }
    console.log('not active');
    return false;
  }

  openReport(surveyQuestionKey: string, surveyQuestionTranslationKey: string): void {
    this.reportActions.emit({
      surveyQuestionKey,
      surveyQuestionTranslationKey,
      orgName: this.organization.name,
      surveyId: this.latestLssSurvey.surveySubmissionId,
      surveyType: ReportSurveyTypes.LSS,
    });
  }

  openDonateModal() {
    this.donateModalActions.emit({ action: 'modal', params: ['open'] });
  }

  // Translate currency symbol to a code object for the customCurrency pipe

  checkForCountry() {
    const countryId = this.latestLssSurvey.country.countryId;
    this.countryListService
      .isCountryUSA(countryId)
      .subscribe((result) => (this.hideAcceptImportedGoodsSection = result));
  }

  requestLssSurveyUpdate(): void {
    this.lssSurveyService.requestLSSSurveyUpdate(this.orgId, this.locationId).subscribe(
      (request) => {
        if (request.status === 0) {
          this.notificationsService.success(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST'),
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-SENT')
          );
        }
      },
      (err) => {
        if (err.error.errors[0].errorCode === 'SURVEY_REQUEST_INFO_ALREADY_SENT') {
          this.notificationsService.alert(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-ALREADY-SENT')
          );
        } else {
          handleErrors(err, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_LSS');
        }
      }
    );
  }

  getOverviewStaffCount(type: string): number {
    if (type === 'localStaff' || type === 'nonLocalStaff' || type === 'volunteerStaff') {
      if (this.latestLssSurvey && this.latestLssSurvey[type]) {
        const mgmt = this.latestLssSurvey[type].value.mgmtStaffCount;
        const professional = this.latestLssSurvey[type].value.professionalStaffCount;
        const support = this.latestLssSurvey[type].value.supportStaffCount;

        // Only return null if all 3 responses are blank.
        // Otherwise, treat blanks as 0's.
        if (
          (mgmt === undefined || mgmt === null) &&
          (professional === undefined || professional === null) &&
          (support === undefined || support === null)
        ) {
          return null;
        }
        return (mgmt ? mgmt : 0) + (professional ? professional : 0) + (support ? support : 0);
      }
    }
    return null;
  }

  getHiredStaffCount(type: string): number {
    if (type === 'mgmtStaffCount' || type === 'professionalStaffCount' || type === 'supportStaffCount') {
      if (this.latestLssSurvey) {
        const local = this.latestLssSurvey.localStaff ? this.latestLssSurvey.localStaff.value[type] : null;
        const nonLocal = this.latestLssSurvey.nonLocalStaff ? this.latestLssSurvey.nonLocalStaff.value[type] : null;

        // Only return null if both responses are blank.
        // Otherwise, treat blanks as 0's.
        if ((local === undefined || local === null) && (nonLocal === undefined || nonLocal === null)) {
          return null;
        }
        return (local ? local : 0) + (nonLocal ? nonLocal : 0);
      }
    }
    return null;
  }

  private getEngagements(): string[] {
    if (this.latestLssSurvey && this.latestLssSurvey.engagement) {
      const list: string[] = this.latestLssSurvey.engagement.value.engagementList;
      return formatStringList(list);
    }
    return null;
  }

  private getMemberships(): string[] {
    if (this.latestLssSurvey && this.latestLssSurvey.membershipList) {
      const list: string[] = this.latestLssSurvey.membershipList.value;
      return formatStringList(list);
    }
    return null;
  }
}
