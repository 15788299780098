<app-loader [loading]="loading"></app-loader>
<ng-template [ngIf]="latestGobSurvey && latestLssSurvey">
  <div class="row">
    <!-- Breadcrumb -->
    <nav class="breadcrumb-nav">
      <div class="nav-wrapper">
        <div class="col l7 m7 s12 breadcrumb-div">
          <a routerLink="/" class="breadcrumb">
            {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
          </a>
          <a [routerLink]="['/organization', organization?.organizationId]" class="breadcrumb">
            {{ organization?.name }}
          </a>
          <a class="breadcrumb">{{ latestLssSurvey?.country?.name }}</a>
        </div>
        <div class="col l5 m5 s12 right-align breadcrumb-div" *ngIf="latestLssSurvey && latestLssSurvey?.country">
          <a [routerLink]="['/location', latestLssSurvey?.country?.countryId]" class="orgs-link text-size">{{
            'OrgLocationComponent-LINK' | translate: { location: latestLssSurvey?.country?.name }
          }}</a>
        </div>
      </div>
    </nav>
    <div class="divider"></div>
  </div>
  <div class="page-container">
    <div class="row">
      <div class="col s8 offset-s2 m3 l2 center">
        <img
          alt="organization logo"
          src="{{ organization?.logoURI ? organization?.logoURI : 'assets/images/org-default.png' }}"
          class="collection-img responsive-img"
        />

        <div class="sr-bold donate-text text-wrapping">
          <a
            target="_blank"
            [href]="latestGobSurvey.donateUrl?.value | linkHttp"
            (click)="openDonateModal()"
            [ngClass]="{ disabled: donateButtonDisabled || latestGobSurvey.donateUrl?.value === null }"
            class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
          >
            {{
              'OrgLocationComponent-DONATE_CARD'
                | translate: { orgName: organization?.name, location: latestLssSurvey?.country?.name }
            }}
          </a>
          <ng-template [ngIf]="iscafDonationEnabled">
            <a
              target="_blank"
              [href]="latestGobSurvey.organizationDto?.cafDonationUrl | linkHttp"
              class="big-donate-btn btn red lighten-1 waves-effect waves-light"
            >
              <span>{{ 'COMMON_SEARCH-DONATE-VIA' | translate }}</span>
              <img
                src="assets/images/caf-america-logo.png"
                alt="{{ 'COMMON_SEARCH-DONATE-VIA-CAF-AMERICA' | translate }}"
                class="caf-logo"
              />
            </a>
          </ng-template>
        </div>

        <div class="sr-bold donate-text">
          <a
            target="_blank"
            (click)="requestLssSurveyUpdate()"
            [href]=""
            [ngClass]=""
            class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
          >
            {{ 'COMMON_SEARCH-REQUEST-SURVEY-UPDATE' | translate }}
          </a>
        </div>

        <!-- Learn more about org link -->
        <div class="both-padding text-wrapping">
          <a [routerLink]="['/organization', organization?.organizationId]" class="text-size">
            {{ 'COMMON_SEARCH-LEARN_MORE_ABOUT_ORG' | translate: { orgName: organization?.name } }}
          </a>
        </div>
      </div>
      <!-- Donation Modal -->
      <div
        id="donateModal"
        class="modal modal-fixed-footer"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="donateModalActions"
      >
        <donation-modal
          [orgName]="organization?.name"
          [orgId]="organization?.organizationId"
          [donateUrl]="latestGobSurvey.donateUrl?.value"
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
        >
        </donation-modal>
      </div>
      <!--Tipping Modal-->
      <div
        id="tippingModal"
        class="modal modal-fixed-footer"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="tippingModalActions"
      >
        <givelively-donation
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
          [tippingText]="true"
        >
        </givelively-donation>
      </div>

      <div class="col s12 m9 l6">
        <div class="sr-bold black-text lighten-1">
          <h5>
            {{
              'OrgLocationComponent-DESCRIPTION_1'
                | translate: { orgName: organization?.name, state: latestLssSurvey?.country?.name }
            }}
          </h5>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_MISSION' | translate }}
          </span>

          <div class="sr-justify">
            <survey-response [value]="organization?.mission">
              {{ organization?.mission }}
            </survey-response>
          </div>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_HQ' | translate }}
          </span>

          <div>
            <survey-response [value]="address">
              {{ address | locationify | translate }}
            </survey-response>
          </div>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgBackgroundComponent-Q_SUMMARY' | translate }}
          </span>

          <div class="sr-justify sr-breakword">
            <survey-response [value]="organization?.summary" [innerHtml]="organization?.summary"> </survey-response>
          </div>
          <div class="sr-justify sr-breakword" *ngIf="!oganization?.summary">
            <survey-response [value]="organization?.summary"> </survey-response>
          </div>
        </div>

        <div class="description-element">
          <span class="sr-bold">
            {{ 'OrgLocationComponent-DESCRIPTION_3' | translate: { state: latestLssSurvey?.country?.name } }}
          </span>

          <div class="sr-justify">
            <survey-response [value]="latestLssSurvey?.activityHistory?.value">
              {{ latestLssSurvey?.activityHistory?.value }}
            </survey-response>
          </div>
        </div>
      </div>

      <div class="col s12 m12 l4 center">
        <!-- Card 1 : Staff count -->
        <div class="col s12 m6 l12 left-padding center">
          <div class="card org-card">
            <div class="card-content">
              <p class="center blue-text sr-bold text-size">
                {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_1' | translate }}
              </p>
              <div class="divider"></div>
              <div class="grey-text">
                <last-updated-icon-and-timestamp
                  [surveyType]="NA"
                  [timestamp]="latestLssSurvey?.localStaff?.lastUpdatedDate"
                >
                </last-updated-icon-and-timestamp>
              </div>
              <div class="center">
                <table class="centered">
                  <tr>
                    <td class="sr-bold">{{ 'COMMON_SEARCH-STAFF_COUNT_CARD_2' | translate }}</td>
                    <td>
                      <survey-response
                        [value]="context?.staffTotals?.local"
                        *ngIf="context?.staffTotals?.local !== null; else noResponseSurvRespComp"
                      >
                        {{ context?.staffTotals?.local }}
                      </survey-response>
                    </td>
                  </tr>
                  <tr>
                    <td class="sr-bold">
                      {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_3' | translate }}
                    </td>
                    <td>
                      <survey-response
                        [value]="context?.staffTotals?.nonLocal"
                        *ngIf="context?.staffTotals?.nonLocal !== null; else noResponseSurvRespComp"
                      >
                        {{ context?.staffTotals?.nonLocal }}
                      </survey-response>
                    </td>
                  </tr>
                  <tr>
                    <td class="sr-bold">
                      {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_4' | translate }}
                    </td>
                    <td>
                      <survey-response
                        [value]="context?.staffTotals?.volunteer"
                        *ngIf="context?.staffTotals?.volunteer !== null; else noResponseSurvRespComp"
                      >
                        {{ context?.staffTotals?.volunteer }}
                      </survey-response>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Card 2 : Overall budget -->
        <div class="col s12 m6 l12 right-padding center">
          <div class="card org-card">
            <div class="card-content">
              <div>
                <p class="center blue-text sr-bold text-size">
                  <span>{{
                    'OrgLocationComponent-BUDGET' | translate: { location: latestLssSurvey?.country?.name }
                  }}</span>
                  <span *ngIf="latestLssSurvey?.fiscalYearEnding?.value">
                    {{
                      'OrgLocationComponent-FISCAL_YEAR' | translate: { year: latestLssSurvey?.fiscalYearEnding?.value }
                    }}
                  </span>
                </p>
              </div>
              <div class="divider"></div>
              <last-updated-icon-and-timestamp
                [surveyType]="NA"
                [timestamp]="latestLssSurvey?.overallBudget?.lastUpdatedDate"
              >
              </last-updated-icon-and-timestamp>

              <div class="center sr-bold text">
                <survey-response [value]="money?.singles?.budget?.converted?.amount">
                  {{
                    money?.singles?.budget?.converted?.amount
                      | customCurrency: currency?.map[money?.singles?.budget?.converted?.currency]
                  }}
                </survey-response>
              </div>
            </div>
          </div>
        </div>

        <!-- Share Buttons -->
        <div class="col s12 m6 l12 right-padding center">
          <share-buttons-custom [metadata]="orgLocationMetadata"> </share-buttons-custom>
        </div>
      </div>
    </div>

    <!-- display LSS information -->
    <div class="row state-info">
      <div class="col s12 m12 l8 left">
        <div class="row report-note">
          <div class="col valign-wrapper">
            <i class="material-icons green-text text-darken-3">calendar_today</i>
            {{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_CURRENT' | translate }}
          </div>
          <div class="col valign-wrapper">
            <i class="material-icons orange-text text-darken-3">calendar_today</i>
            {{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_NO_RESPONSE' | translate }}
          </div>
        </div>
        <div class="report-note">
          <span>
            {{ 'COMMON_SEARCH-COLLAPSIBLE_FLAG_NOTE' | translate }}
          </span>
          <i class="tiny material-icons cursor">flag</i>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12 l8">
        <ul materialize="collapsible" [materializeParams]="params" class="collapsible" data-collapsible="accordion">
          <!-- Provided services -->
          <li id="li0">
            <div class="collapsible-header sr-bold blue-text text-size">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">contacts</i>
              {{ 'COMMON_SEARCH-SERVICES_PROVIDED_TITLE' | translate }}
            </div>
            <div class="collapsible-body text-size">
              <div class="lists row">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'serviceList',
                          this.translateService.instant('OrgLocationComponent-SERVICES_PROVIDED_TITLE', {
                            orgName: organization?.name,
                            location: latestLssSurvey?.country?.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="subheading sr-bold">
                      {{
                        'OrgLocationComponent-SERVICES_PROVIDED_TITLE'
                          | translate: { orgName: organization?.name, location: latestLssSurvey?.country?.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="NA"
                        [timestamp]="latestLssSurvey?.serviceList?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="divider"></div>
                  <div *ngIf="!latestLssSurvey.serviceList?.value?.length">
                    <survey-response></survey-response>
                  </div>
                  <div class="my_table1" *ngIf="latestLssSurvey?.serviceList?.value?.length">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_SERVICES' | translate }}</th>
                          <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_DIRECT' | translate }}</th>
                          <th class="question sr-bold">{{ 'SurveyCommon-Q_SP_INDIRECT' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of latestLssSurvey?.serviceList?.value">
                          <td>
                            <div>
                              <img
                                *ngIf="
                                  (item.serviceProvided.file && item.serviceProvided.file.uri) ||
                                  (item.serviceProvided.name | serviceIcons)
                                "
                                class="services-provided-icon-xs"
                                src="{{
                                  item.serviceProvided.file && item.serviceProvided.file.uri
                                    ? item.serviceProvided.file.uri
                                    : (item.serviceProvided.name | serviceIcons)
                                }}"
                              />
                              {{ serviceProvidedItems[item?.serviceProvided.serviceId]?.name }}
                            </div>
                          </td>
                          <td class="response">{{ item?.directProportion ? item?.directProportion : 0 }} %</td>
                          <td class="response">{{ item?.indirectProportion ? item?.indirectProportion : 0 }} %</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="li1">
            <div class="collapsible-header text-size blue-text sr-bold" [ngClass]="{ active: !videoId }">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">contacts</i>
              {{ 'OrgLocationComponent-LOCAL_CONTACT_TITLE' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <div class="question">
                  <i
                    (click)="openReport('officeAddress', 'COMMON_SEARCH-TITLE_ADDRESS')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  <span class="subheading sr-bold">
                    {{ 'COMMON_SEARCH-TITLE_ADDRESS' | translate }}
                  </span>
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.officeAddress?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="row address">
                  <div class="col s12">
                    <survey-response
                      [value]="latestLssSurvey?.officeAddress?.value?.address1"
                      class="sr-justify response"
                    >
                      {{ latestLssSurvey?.officeAddress?.value?.address1 }}
                    </survey-response>
                  </div>
                  <div class="col s12 response">
                    {{ latestLssSurvey?.officeAddress?.value?.address2 }}
                  </div>
                  <div class="col s12">
                    <span class="sr-bold sr-justify response"> {{ 'UNIVERSAL-ADDRESS_CITY' | translate }}: </span>
                    <survey-response [value]="latestLssSurvey?.officeAddress?.value?.city" class="sr-justify response">
                      {{ latestLssSurvey?.officeAddress?.value?.city }}
                    </survey-response>
                  </div>
                  <div class="col s12">
                    <span class="sr-bold sr-justify response">
                      {{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}:
                    </span>
                    <survey-response [value]="latestLssSurvey?.officeAddress?.value?.state" class="sr-justify response">
                      {{ latestLssSurvey?.officeAddress?.value?.state }}
                    </survey-response>
                  </div>
                </div>
                <div class="question">
                  <i
                    (click)="openReport('repContact', 'COMMON_SEARCH-TITLE_POINT_OF_CONTACT')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  <span class="subheading sr-bold">{{ 'COMMON_SEARCH-TITLE_POINT_OF_CONTACT' | translate }}</span>
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.repContact?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="row contact">
                  <div class="col s12">
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-CONTACT_NAME' | translate }}:</span>
                    <survey-response
                      [value]="latestLssSurvey?.repContact?.value?.firstName"
                      class="sr-justify response"
                    >
                      {{ latestLssSurvey?.repContact?.value?.firstName }}
                    </survey-response>
                    <span class="response">
                      {{ latestLssSurvey?.repContact?.value?.lastName }}
                    </span>
                  </div>
                  <div class="col s12">
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-CONTACT_TITLE' | translate }}:</span>
                    <survey-response [value]="latestLssSurvey?.repContact?.value?.title" class="sr-justify response">
                      {{ latestLssSurvey?.repContact?.value?.title }}
                    </survey-response>
                  </div>
                  <div class="col s12">
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-CONTACT_PHONE' | translate }}:</span>
                    <survey-response [value]="latestLssSurvey?.repContact?.value?.phone1" class="sr-justify response">
                      {{ latestLssSurvey?.repContact?.value?.phone1 }}
                    </survey-response>
                  </div>
                  <div class="col s12">
                    <span class="sr-bold sr-justify response">{{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}:</span>
                    <survey-response [value]="latestLssSurvey?.repContact?.value?.email" class="sr-justify response">
                      {{ latestLssSurvey?.repContact?.value?.email }}
                    </survey-response>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="li2">
            <div class="collapsible-header text-size blue-text sr-bold">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">history</i>
              {{ 'OrgLocationComponent-HISTORY_TITLE' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <div class="question">
                  <i
                    (click)="
                      openReport(
                        'activityHistory',
                        this.translateService.instant('OrgLocationComponent-Q_HISTORY_ACTIVITY', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  <span class="sr-bold question">
                    {{
                      'OrgLocationComponent-Q_HISTORY_ACTIVITY'
                        | translate: { orgName: organization?.name, state: latestLssSurvey?.country?.name }
                    }}</span
                  >
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.activityHistory?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify">
                  <survey-response [value]="latestLssSurvey?.activityHistory?.value">
                    {{ latestLssSurvey?.activityHistory?.value }}
                  </survey-response>
                </div>

                <div class="question">
                  <i
                    (click)="
                      openReport(
                        'startOpDate',
                        this.translateService.instant('OrgLocationComponent-Q_BEGAN_OPERATION_DATE', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  <span class="sr-bold question">
                    {{
                      'OrgLocationComponent-Q_BEGAN_OPERATION_DATE'
                        | translate: { orgName: organization?.name, state: latestLssSurvey?.country?.name }
                    }}</span
                  >
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.startOpDate?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify">
                  <survey-response [value]="latestLssSurvey?.startOpDate?.value">
                    {{ latestLssSurvey?.startOpDate?.value | date: 'yyyy' }}
                  </survey-response>
                </div>
              </div>
            </div>
          </li>
          <li id="li3">
            <div class="collapsible-header blue-text sr-bold">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">list</i>
              {{ 'OrgLocationComponent-LOCAL_ASSETS_TITLE' | translate }}
            </div>
            <div class="collapsible-body body-margin">
              <div class="survey">
                <div class="subheading question sr-bold">
                  {{ 'OrgLocationComponent-LOCAL_ASSETS_TITLE' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.reliefTypeList?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div
                  *ngIf="
                    latestLssSurvey?.reliefTypeList && latestLssSurvey?.reliefTypeList?.value.length != 0;
                    else noResponse
                  "
                >
                  <div class="my_table1">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th class="question sr-bold">
                            {{ 'OrgLocationComponent-RELIEF_ITEMS_TITLE' | translate }}
                          </th>
                          <th class="question sr-bold">
                            {{ 'OrgLocationComponent-QUANTITY_TITLE' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of latestLssSurvey?.reliefTypeList?.value">
                          <td>
                            <div>
                              <i
                                (click)="openReport('reliefTypeList', 'OrgLocationComponent-LOCAL_ASSETS_TITLE')"
                                class="material-icons statusFlag red-text"
                                >flag</i
                              >
                              {{ reliefRecoveryItems[item?.typeId]?.name }}
                            </div>
                          </td>
                          <td class="response">
                            {{ item?.itemCount }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="!hideAcceptImportedGoodsSection">
                  <div class="subheading question sr-bold">
                    <i
                      (click)="
                        openReport(
                          'acceptDonationsOfImportedGoods',
                          this.translateService.instant('OrgLocationComponent-Q_ACCEPT_DONATIONS_OF_IMPORTED_GOODS', {
                            orgName: organization?.name,
                            country: latestLssSurvey?.country?.name
                          })
                        )
                      "
                      class="material-icons statusFlag red-text"
                      >flag</i
                    >
                    {{
                      'OrgLocationComponent-Q_ACCEPT_DONATIONS_OF_IMPORTED_GOODS'
                        | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                    }}
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="NA"
                        [timestamp]="latestLssSurvey?.acceptDonationsOfImportedGoods?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <!-- Accept Donations of Imported Goods -->
                  <div
                    *ngIf="
                      latestLssSurvey?.acceptDonationsOfImportedGoods?.value !== null;
                      else noResponseAcceptImportedGoods
                    "
                  >
                    <div class="question sr-bold sr-justify">
                      {{
                        'OrgLocationComponent-Q_DOES_ORG_ACCEPT_DONATIONS_OF_IMPORTED_GOODS_INTO_LOC'
                          | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                      }}
                    </div>
                    <div
                      class="response sr-justify"
                      *ngIf="
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value !== null &&
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods
                      "
                    >
                      {{ 'UNIVERSAL-YES' | translate }}
                    </div>
                    <div
                      class="response sr-justify"
                      *ngIf="
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods !== null &&
                        !latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods
                      "
                    >
                      {{ 'UNIVERSAL-NO' | translate }}
                    </div>
                  </div>
                  <ng-template #noResponseAcceptImportedGoods>
                    <div
                      class="response sr-justify"
                      *ngIf="latestLssSurvey?.acceptDonationsOfImportedGoods?.value === null"
                    >
                      <div class="question sr-bold sr-justify">
                        {{
                          'OrgLocationComponent-Q_DOES_ORG_ACCEPT_DONATIONS_OF_IMPORTED_GOODS_INTO_LOC'
                            | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                        }}
                      </div>
                      <div
                        class="response sr-justify"
                        *ngIf="latestLssSurvey?.acceptDonationsOfImportedGoods?.value === null"
                      >
                        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                      </div>
                    </div>
                  </ng-template>
                  <!-- Previously Accepted Donations of Imported Goods -->
                  <div *ngIf="latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods">
                    <div class="question sr-bold sr-justify">
                      {{
                        'OrgLocationComponent-Q_ORG_PREV_IMPORTED_GOODS_INTO_LOC'
                          | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                      }}
                    </div>
                    <div
                      class="response sr-justify"
                      *ngIf="
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods &&
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.hasPreviouslyAcceptedImportedGoods ==
                          null
                      "
                    >
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                    <div
                      class="response sr-justify"
                      *ngIf="
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.hasPreviouslyAcceptedImportedGoods !==
                          null &&
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.hasPreviouslyAcceptedImportedGoods
                      "
                    >
                      {{ 'UNIVERSAL-YES' | translate }}
                    </div>
                    <div
                      class="response sr-justify"
                      *ngIf="
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.hasPreviouslyAcceptedImportedGoods !==
                          null &&
                        !latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.hasPreviouslyAcceptedImportedGoods
                      "
                    >
                      {{ 'UNIVERSAL-NO' | translate }}
                    </div>
                  </div>
                  <!-- Full name of the Importer of Record  -->
                  <div
                    class="response sr-justify"
                    *ngIf="
                      latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods &&
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.acceptImportedGoods !== null &&
                        latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.importerOfRecord !== '';
                      else noResponseImporterName
                    "
                  >
                    <span class="sr-justify response sr-bold"
                      >{{ 'OrgLocationComponent-Q_FULL_NAME_OF_THE_IMPORTER_OF_GOODS' | translate }}:</span
                    >
                    <survey-response
                      [value]="latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.importerOfRecord"
                      class="sr-justify response"
                    >
                      {{ latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.importerOfRecord }}
                    </survey-response>
                  </div>
                  <ng-template #noResponseImporterName>
                    <div
                      class="response sr-justify"
                      *ngIf="latestLssSurvey?.acceptDonationsOfImportedGoods?.value?.importerOfRecord === ''"
                    >
                      <span class="sr-justify response sr-bold"
                        >{{ 'OrgLocationComponent-Q_FULL_NAME_OF_THE_IMPORTER_OF_GOODS' | translate }}:</span
                      >
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </li>
          <li id="li4">
            <div class="collapsible-header sr-bold blue-text text-size">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">info</i>
              {{ 'OrgLocationComponent-ACTIVITY_MEMBERSHIPS_CPIE_TITLE' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <span class="subheading sr-bold">{{ 'OrgLocationComponent-INFORMATION_TITLE' | translate }}</span>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'disasterStageOfWork',
                        this.translateService.instant('OrgLocationComponent-Q_DISASTER_STAGES', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_DISASTER_STAGES' | translate: { orgName: organization?.name } }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.disasterStageOfWork?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.disasterStageOfWork?.value">
                  <div *ngFor="let work of latestLssSurvey?.disasterStageOfWork?.value | keys">
                    <span class="sr-bold">
                      {{ disasterStageNames[work.key] }}
                    </span>
                    <span class="response">
                      {{ work.value != null ? work.value : ('COMMON_SEARCH-NO_RESPONSE' | translate) }}
                    </span>
                  </div>
                </div>
                <div class="response sr-justify" *ngIf="!latestLssSurvey?.disasterStageOfWork?.value">
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'disasterResponsePlan',
                        this.translateService.instant('OrgLocationComponent-Q_EMERGENCY_PLAN', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_EMERGENCY_PLAN' | translate: { orgName: organization?.name } }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.disasterResponsePlan?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify" *ngIf="!latestLssSurvey?.disasterResponsePlan">
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div
                  class="response sr-justify"
                  *ngIf="latestLssSurvey?.disasterResponsePlan?.value?.hasValue === false"
                >
                  {{ 'OrgLocationComponent-NO_PLAN_REPORTED' | translate }}
                </div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.disasterResponsePlan?.value?.hasValue">
                  <div
                    class="response sr-justify"
                    *ngIf="latestLssSurvey?.disasterResponsePlan?.value?.fileId !== null; else noResponseFileId"
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                    <a
                      class="btn"
                      target="_blank"
                      href="{{
                        documentService.getDocumentDownloadLink(latestLssSurvey?.disasterResponsePlan?.value?.fileId)
                      }}"
                    >
                      {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                    </a>
                  </div>
                  <div
                    class="response sr-justify"
                    *ngIf="
                      latestLssSurvey?.disasterResponsePlan?.value?.webLink !== null &&
                        latestLssSurvey?.disasterResponsePlan?.value?.webLink !== '';
                      else noResponseWebLink
                    "
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-DOCUMENT_URL' | translate }}:</span>
                    {{ latestLssSurvey?.disasterResponsePlan?.value?.webLink }}
                  </div>
                </div>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="openReport('membershipList', 'OrgLocationComponent-Q_LIST_MEMBERSHIPS')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_LIST_MEMBERSHIPS' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.membershipList?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div *ngIf="context?.memberships !== null; else noResponseText">
                  <div class="response sr-justify chip-container">
                    <div class="chip" *ngFor="let memberOrg of context?.memberships">
                      {{ memberOrg }}
                    </div>
                  </div>
                </div>

                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'engagement',
                        this.translateService.instant('OrgLocationComponent-Q_PARTNERSHIPS_ENGAGEMENTS', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'OrgLocationComponent-Q_PARTNERSHIPS_ENGAGEMENTS'
                      | translate: { orgName: organization?.name, state: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.engagement?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div
                  class="response sr-justify chip-container"
                  *ngIf="context?.engagements !== null; else noResponseText"
                >
                  <div class="chip" *ngFor="let memberOrg of context?.engagements">
                    {{ memberOrg }}
                  </div>
                </div>
                <!-- Child Protection Emergency Plan -->
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'childProtectionEmergencyPlan',
                        this.translateService.instant('OrgLocationComponent-Q_CHILDPROTECTION_EMERGECY_PLAN', {
                          orgName: organization?.name,
                          country: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'OrgLocationComponent-Q_CHILDPROTECTION_EMERGECY_PLAN'
                      | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.childProtectionEmergencyPlan?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div
                  class="response sr-justify"
                  *ngIf="latestLssSurvey?.childProtectionEmergencyPlan?.value.hasValue == null"
                >
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div
                  class="response sr-justify"
                  *ngIf="latestLssSurvey?.childProtectionEmergencyPlan?.value?.hasValue === false"
                >
                  {{ 'OrgLocationComponent-NO_PLAN_REPORTED' | translate }}
                </div>
                <!-- CPiE File Upload and Web Link -->
                <div class="response sr-justify" *ngIf="latestLssSurvey?.childProtectionEmergencyPlan?.value?.hasValue">
                  <div
                    class="response sr-justify"
                    *ngIf="
                      !(latestLssSurvey?.childProtectionEmergencyPlan?.value?.fileId == null);
                      else noResponseFileId
                    "
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                    <a
                      class="btn"
                      target="_blank"
                      href="{{
                        documentService.getDocumentDownloadLink(
                          latestLssSurvey?.childProtectionEmergencyPlan?.value?.fileId
                        )
                      }}"
                    >
                      {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                    </a>
                  </div>
                  <div
                    class="response sr-justify"
                    *ngIf="
                      latestLssSurvey?.childProtectionEmergencyPlan?.value?.webLink !== null &&
                        latestLssSurvey?.childProtectionEmergencyPlan?.value?.webLink !== '';
                      else noResponseWebLink
                    "
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-DOCUMENT_URL' | translate }}:</span>
                    {{ latestLssSurvey?.childProtectionEmergencyPlan?.value?.webLink }}
                  </div>
                </div>
                <!-- CPiE Comment -->
                <div
                  class="response sr-justify"
                  *ngIf="
                    !latestLssSurvey?.childProtectionEmergencyPlan?.value?.hasValue &&
                      latestLssSurvey?.childProtectionEmergencyPlan?.value?.hasValue !== null &&
                      latestLssSurvey?.childProtectionEmergencyPlan?.value?.comment !== '';
                    else noResponseComment
                  "
                >
                  <span class="sr-justify response sr-bold"
                    >{{ 'OrgLocationComponent-CHILDPROTECTION_EMERGECY_PLAN_COMMENT' | translate }}:</span
                  >
                  <survey-response
                    [value]="latestLssSurvey?.childProtectionEmergencyPlan?.value?.comment"
                    class="sr-justify response"
                  >
                    {{ latestLssSurvey?.childProtectionEmergencyPlan?.value?.comment }}
                  </survey-response>
                </div>
                <ng-template #noResponseComment>
                  <div
                    class="response sr-justify"
                    *ngIf="latestLssSurvey?.childProtectionEmergencyPlan?.value?.comment == ''"
                  >
                    <span class="sr-justify response sr-bold"
                      >{{ 'OrgLocationComponent-CHILDPROTECTION_EMERGECY_PLAN_COMMENT' | translate }}:</span
                    >
                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                  </div>
                </ng-template>

                <!-- Vetting Agencies -->
                <div class="sr-justify question sr-bold">
                  <i
                    (click)="openReport('vettingAgencies', 'OrgLocationComponent-Q_LIST_VETTINGAGENCIES')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_VETTINGAGENCIES' | translate }}
                </div>

                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.vettingAgencies?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>

                <div class="divider"></div>
                <div *ngIf="latestLssSurvey?.vettingAgencies; else noResponse">
                  <div class="response sr-justify custom-collapsible">
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let agency of latestLssSurvey?.vettingAgencies; let i = index">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="sr-bold question sr-justify">
                              <i
                                (click)="openReport('vettingAgencies', 'OrgLocationComponent-Q_LIST_VETTINGAGENCIES')"
                                class="material-icons statusFlag red-text"
                                >flag</i
                              >
                              {{ agency.value.agencyName }}
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                          <!-- Table -->
                          <div class="my_table2 va_table">
                            <table class="centered striped responsive-table">
                              <thead>
                                <tr>
                                  <th class="header-cell">
                                    {{ 'UNIVERSAL-START_DATE_LABEL' | translate }}
                                  </th>
                                  <th class="header-cell">
                                    {{ 'UNIVERSAL-EXPIRATION_DATE_LABEL' | translate }}
                                  </th>
                                  <th class="header-cell">
                                    {{ 'LocationSpecificSurveyComponent-STEP_5_VA_LETTERS' | translate }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="cell" *ngIf="agency.value.startDate">
                                    {{ agency.value.startDate | date: 'MMM dd yyyy':'UTC' }}
                                  </td>
                                  <td class="cell" *ngIf="!agency.value.startDate">
                                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                                  </td>
                                  <td class="cell" *ngIf="agency.value.expirationDate">
                                    {{ agency.value.expirationDate | date: 'MMM dd yyyy':'UTC' }}
                                  </td>
                                  <td class="cell" *ngIf="!agency.value.expirationDate">
                                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                                  </td>
                                  <td class="cell" *ngIf="agency.value.vettingAgencyLetterDtos.length > 0">
                                    <div *ngFor="let letter of agency.value.vettingAgencyLetterDtos; let l = index">
                                      <!-- VA Letter File -->
                                      <div class="sr-bold">
                                        {{
                                          'LocationSpecificSurveyComponent-STEP_5_LETTER_FILE'
                                            | translate: { index: l + 1 }
                                        }}
                                      </div>
                                      <p *ngIf="!(letter.fileId == null)" class="spacing-div">
                                        <a
                                          class="waves-effect waves-light btn custom-btn"
                                          target="_blank"
                                          href="{{ documentService.getDocumentDownloadLink(letter.fileId) }}"
                                        >
                                          <i class="material-icons hide-on-large-only hide-on-med-only white-icon"
                                            >save</i
                                          >
                                          <span class="hide-on-small-only">{{
                                            'UNIVERSAL-DOWNLOAD_NAME_FILE' | translate: { index: l + 1 }
                                          }}</span>
                                        </a>
                                      </p>
                                    </div>
                                  </td>
                                  <td class="cell" *ngIf="agency.value.vettingAgencyLetterDtos.length === 0">
                                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </ng-template>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <ng-template #noResponse>
                  <div class="response sr-justify">
                    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                  </div>
                </ng-template>
                <!-- End of vetting agencies -->
              </div>
            </div>
          </li>
          <li id="li5">
            <div class="collapsible-header sr-bold blue-text text-size">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">face</i>
              {{ 'OrgLocationComponent-STAFFING_DETAILS_TITLE' | translate }}
            </div>
            <div class="collapsible-body body-margin">
              <div class="survey">
                <div class="subheading question sr-bold">
                  <i
                    (click)="openReport('totalPaidStaff', 'UNIVERSAL-SURVEY_QUESTIONS_PAID_STAFF')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'UNIVERSAL-SURVEY_QUESTIONS_PAID_STAFF' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="
                        latestLssSurvey?.localStaff?.lastUpdatedDate > latestLssSurvey?.nonLocalStaff?.lastUpdatedDate
                          ? latestLssSurvey?.nonLocalStaff?.lastUpdatedDate
                          : latestLssSurvey?.localStaff?.lastUpdatedDate
                      "
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="my_table2">
                  <table class="centered">
                    <thead>
                      <tr>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="response">
                        <td>
                          <survey-response
                            [value]="context?.staffTotals?.hiredMgmt"
                            *ngIf="context?.staffTotals?.hiredMgmt !== null; else noResponseSurvRespComp"
                          >
                            {{ context?.staffTotals?.hiredMgmt }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response
                            [value]="context?.staffTotals?.hiredProf"
                            *ngIf="context?.staffTotals?.hiredProf !== null; else noResponseSurvRespComp"
                          >
                            {{ context?.staffTotals?.hiredProf }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response
                            [value]="context?.staffTotals?.hiredSupp"
                            *ngIf="context?.staffTotals?.hiredSupp !== null; else noResponseSurvRespComp"
                          >
                            {{ context?.staffTotals?.hiredSupp }}
                          </survey-response>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="subheading question sr-bold">
                  <i
                    (click)="
                      openReport(
                        'localStaff',
                        this.translateService.instant('UNIVERSAL-SURVEY_QUESTIONS_LOCAL_STAFF', {
                          location: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'UNIVERSAL-SURVEY_QUESTIONS_LOCAL_STAFF' | translate: { location: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.localStaff?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="my_table2">
                  <table class="centered">
                    <thead>
                      <tr>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="response">
                        <td>
                          <survey-response [value]="latestLssSurvey?.localStaff?.value?.mgmtStaffCount">
                            {{ latestLssSurvey?.localStaff?.value?.mgmtStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.localStaff?.value?.professionalStaffCount">
                            {{ latestLssSurvey?.localStaff?.value?.professionalStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.localStaff?.value?.supportStaffCount">
                            {{ latestLssSurvey?.localStaff?.value?.supportStaffCount }}
                          </survey-response>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="subheading question sr-bold">
                  <i
                    (click)="
                      openReport(
                        'nonLocalStaff',
                        this.translateService.instant('UNIVERSAL-SURVEY_QUESTIONS_NON_LOCAL_STAFF', {
                          location: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'UNIVERSAL-SURVEY_QUESTIONS_NON_LOCAL_STAFF'
                      | translate: { location: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.nonLocalStaff?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="my_table2">
                  <table class="centered">
                    <thead>
                      <tr>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="response">
                        <td>
                          <survey-response [value]="latestLssSurvey?.nonLocalStaff?.value?.mgmtStaffCount">
                            {{ latestLssSurvey?.nonLocalStaff?.value?.mgmtStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.nonLocalStaff?.value?.professionalStaffCount">
                            {{ latestLssSurvey?.nonLocalStaff?.value?.professionalStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.nonLocalStaff?.value?.supportStaffCount">
                            {{ latestLssSurvey?.nonLocalStaff?.value?.supportStaffCount }}
                          </survey-response>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="subheading question sr-bold">
                  <i
                    (click)="
                      openReport(
                        'volunteerStaff',
                        this.translateService.instant('UNIVERSAL-SURVEY_QUESTIONS_VOLUNTEER_STAFF', {
                          location: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'UNIVERSAL-SURVEY_QUESTIONS_VOLUNTEER_STAFF' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.volunteerStaff?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="my_table2">
                  <table class="centered">
                    <thead>
                      <tr>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                          </div>
                        </th>
                        <th>
                          <div class="sr-bold question">
                            {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="response">
                        <td>
                          <survey-response [value]="latestLssSurvey?.volunteerStaff?.value?.mgmtStaffCount">
                            {{ latestLssSurvey?.volunteerStaff?.value?.mgmtStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.volunteerStaff?.value?.professionalStaffCount">
                            {{ latestLssSurvey?.volunteerStaff?.value?.professionalStaffCount }}
                          </survey-response>
                        </td>
                        <td>
                          <survey-response [value]="latestLssSurvey?.volunteerStaff?.value?.supportStaffCount">
                            {{ latestLssSurvey?.volunteerStaff?.value?.supportStaffCount }}
                          </survey-response>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </li>
          <li id="li6">
            <div class="collapsible-header sr-bold blue-text text-size">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">payments</i>
              {{ 'OrgLocationComponent-FINANCIAL_PROGRAM_TITLE' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <span class="subheading sr-bold">
                  {{ 'OrgLocationComponent-FINANCE_TITLE' | translate }}
                </span>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'overallBudget',
                        this.translateService.instant('OrgLocationComponent-Q_OVERALL_BUDGET', {
                          orgName: organization?.name,
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'OrgLocationComponent-Q_OVERALL_BUDGET'
                      | translate: { orgName: organization?.name, state: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.overallBudget?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response class="sr-justify response" [value]="money?.singles?.budget?.converted?.amount">
                  {{
                    money?.singles?.budget?.converted?.amount
                      | customCurrency: currency?.map[money?.singles?.budget?.converted?.currency]
                  }}
                </survey-response>
                <div class="sr-bold question">
                  <i
                    (click)="openReport('fiscalYearEnding', 'OrgLocationComponent-Q_FISCAL_YEAR')"
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_FISCAL_YEAR' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.fiscalYearEnding?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response class="sr-justify response" [value]="latestLssSurvey?.fiscalYearEnding?.value">
                  {{ latestLssSurvey?.fiscalYearEnding?.value }}
                </survey-response>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'annualReport',
                        this.translateService.instant('OrgLocationComponent-Q_ANNUAL_PROGRAM', {
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{ 'OrgLocationComponent-Q_ANNUAL_PROGRAM' | translate: { state: latestLssSurvey?.country?.name } }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.annualReport?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify" *ngIf="!latestLssSurvey?.annualReport">
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.annualReport?.value?.hasValue === false">
                  {{ 'OrgLocationComponent-NO_ANNUAL_REPORTS_REPORTED' | translate }}
                </div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.annualReport?.value?.hasValue">
                  <div
                    class="response sr-justify"
                    *ngIf="latestLssSurvey?.annualReport?.value?.fileId !== null; else noResponseFileId"
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                    <a
                      class="btn"
                      target="_blank"
                      href="{{ documentService.getDocumentDownloadLink(latestLssSurvey?.annualReport?.value?.fileId) }}"
                    >
                      {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                    </a>
                  </div>
                  <div
                    class="response sr-justify"
                    *ngIf="
                      latestLssSurvey?.annualReport?.value?.webLink !== null &&
                        latestLssSurvey?.annualReport?.value?.webLink !== '';
                      else noResponseWebLink
                    "
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-DOCUMENT_URL' | translate }}:</span>
                    {{ latestLssSurvey?.annualReport?.value?.webLink }}
                  </div>
                </div>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'monitorProtocol',
                        this.translateService.instant('OrgLocationComponent-Q_EVALUATION_PROTOCOL', {
                          state: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  {{
                    'OrgLocationComponent-Q_EVALUATION_PROTOCOL' | translate: { state: latestLssSurvey?.country?.name }
                  }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.monitorProtocol?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div class="response sr-justify" *ngIf="!latestLssSurvey?.monitorProtocol">
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.monitorProtocol?.value?.hasValue === false">
                  {{ 'OrgLocationComponent-NO_PROTOCOL_REPORTED' | translate }}
                </div>
                <div class="response sr-justify" *ngIf="latestLssSurvey?.monitorProtocol?.value?.hasValue">
                  <div
                    class="response sr-justify"
                    *ngIf="latestLssSurvey?.monitorProtocol?.value?.fileId !== null; else noResponseFileId"
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                    <a
                      class="btn"
                      target="_blank"
                      href="{{
                        documentService.getDocumentDownloadLink(latestLssSurvey?.monitorProtocol?.value?.fileId)
                      }}"
                    >
                      {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                    </a>
                  </div>
                  <div
                    class="response sr-justify"
                    *ngIf="
                      latestLssSurvey?.monitorProtocol?.value?.webLink !== null &&
                        latestLssSurvey?.monitorProtocol?.value?.webLink !== '';
                      else noResponseWebLink
                    "
                  >
                    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-DOCUMENT_URL' | translate }}:</span>
                    {{ latestLssSurvey?.monitorProtocol?.value?.webLink }}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="li7">
            <div class="collapsible-header text-size blue-text sr-bold">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">info_outline</i>
              {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_OTHER_INFO' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <div class="sr-bold subheader">
                  {{ 'OrgBackgroundComponent-COLLAPSIBLE_TITLE_BASIC_INFO' | translate }}
                </div>
                <div class="divider"></div>
                <div class="question">
                  <i
                    (click)="openReport('govIdOrEin', 'OrgBackgroundComponent-Q_ORG_ID')"
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  <span class="sr-bold question">
                    {{ 'OrgBackgroundComponent-Q_ORG_ID' | translate }}
                  </span>
                </div>

                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.govIdOrEin?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response [value]="latestLssSurvey?.govIdOrEin?.value" class="sr-justify response">
                  {{ latestLssSurvey?.govIdOrEin?.value }}
                </survey-response>

                <div class="question">
                  <i
                    (click)="openReport('govIdOrEin2', 'OrgBackgroundComponent-Q_ORG_ID2')"
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  <span class="sr-bold question">
                    {{ 'OrgBackgroundComponent-Q_ORG_ID2' | translate }}
                  </span>
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.govIdOrEin2?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response [value]="latestLssSurvey?.govIdOrEin2?.value" class="sr-justify response">
                  {{ latestLssSurvey?.govIdOrEin2?.value }}
                </survey-response>
                <!--Step 6 Q1 -->
                <div class="sr-bold subheader">
                  {{ 'OrgLocationComponent-Q_OPERATING_PROCEDURES' | translate }}
                </div>
                <div class="divider"></div>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'contractManagement',
                        this.translateService.instant(
                          'LocationSpecificSurveyComponent-STEP_6_Q_PROCUREMENT_CONTRACT_MGMT',
                          { orgName: organization?.name, location: latestLssSurvey?.country?.name }
                        )
                      )
                    "
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  {{ 'OrgLocationComponent-Q_CONTRACT_MANAGEMENT' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.contractManagement?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <div *ngIf="!latestLssSurvey?.contractManagement?.value">
                  {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                </div>
                <div *ngIf="latestLssSurvey?.contractManagement?.value" class="sr-justify response">
                  {{ latestLssSurvey?.contractManagement?.value }}
                </div>
                <!--End Step 6 Q1-->
                <!--Step 6 Q2 -->
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'corruptionPrevention',
                        this.translateService.instant('LocationSpecificSurveyComponent-STEP_6_Q_CORRUPTION_CONFLICTS', {
                          orgName: organization?.name,
                          location: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  {{ 'OrgLocationComponent-Q_CORRUPTION_CONFLICT_INTEREST' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.preventCorruption?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response [value]="latestLssSurvey?.preventCorruption?.value" class="sr-justify response">
                  {{ latestLssSurvey?.preventCorruption?.value }}
                </survey-response>
                <!--End Step 6 Q2 -->
                <!--Step 6 Q3-->
                <div class="sr-bold subheader">
                  {{ 'OrgLocationComponent-Q_ACCESSIBILITY' | translate }}
                </div>
                <div class="divider"></div>
                <div class="sr-bold question sr-justify">
                  {{
                    this.translateService.instant('OrgLocationComponent-Q_DISABILITY_ACCESS', {
                      orgName: organization?.name,
                      location: latestLssSurvey?.country?.name
                    })
                  }}
                </div>
                <div class="divider"></div>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'disabilityCommunicationsAccess',
                        this.translateService.instant(
                          'LocationSpecificSurveyComponent-STEP_6_SECTION_PROVIDE_FOR_DISABLED',
                          { orgName: organization?.name, location: latestLssSurvey?.country?.name }
                        )
                      )
                    "
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  {{ 'OrgLocationComponent-Q_DISABILITY_COMMUNICATIONS_ACCESS' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.disabilityCommunicationAccess?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response
                  [value]="latestLssSurvey?.disabilityCommunicationAccess?.value"
                  class="sr-justify response"
                >
                  {{ latestLssSurvey?.disabilityCommunicationAccess?.value }}
                </survey-response>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'disabilityPhysicalAccess',
                        this.translateService.instant(
                          'LocationSpecificSurveyComponent-STEP_6_SECTION_PROVIDE_FOR_DISABLED',
                          { orgName: organization?.name, location: latestLssSurvey?.country?.name }
                        )
                      )
                    "
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  {{ 'OrgLocationComponent-Q_DISABILITY_PHYSICAL_ACCESS' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.disabilityPhysicalAccess?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response [value]="latestLssSurvey?.disabilityPhysicalAccess?.value" class="sr-justify response">
                  {{ latestLssSurvey?.disabilityPhysicalAccess?.value }}
                </survey-response>
                <div class="sr-bold question sr-justify">
                  <i
                    (click)="
                      openReport(
                        'disabilityProgramAccess',
                        this.translateService.instant(
                          'LocationSpecificSurveyComponent-STEP_6_SECTION_PROVIDE_FOR_DISABLED',
                          { orgName: organization?.name, location: latestLssSurvey?.country?.name }
                        )
                      )
                    "
                    class="material-icons statusFlag red-text"
                  >
                    flag
                  </i>
                  {{ 'OrgLocationComponent-Q_DISABILITY_PROGRAM_ACCESS' | translate }}
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.disabilityProgramAccess?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <survey-response [value]="latestLssSurvey?.disabilityProgramAccess?.value" class="sr-justify response">
                  {{ latestLssSurvey?.disabilityProgramAccess?.value }}
                </survey-response>
                <!--End step 6 Q3-->
              </div>
            </div>
          </li>
          <li id="li8">
            <div class="collapsible-header sr-bold blue-text text-size">
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">location_on</i>
              {{ 'OrgLocationComponent-OPERATING_LOCATIONS_TITLE' | translate }}
            </div>
            <div class="collapsible-body">
              <div class="survey">
                <div class="question">
                  <i
                    (click)="
                      openReport(
                        'operatingLocationsList',
                        this.translateService.instant('OrgLocationComponent-OPERATING_LOCATIONS_TEXT', {
                          orgName: organization?.name,
                          country: latestLssSurvey?.country?.name
                        })
                      )
                    "
                    class="material-icons statusFlag red-text"
                    >flag</i
                  >
                  <span class="subheading sr-bold">
                    {{
                      'OrgLocationComponent-OPERATING_LOCATIONS_TEXT'
                        | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                    }}
                  </span>
                </div>
                <div class="dt-stamp">
                  <span class="left">
                    <last-updated-icon-and-timestamp
                      [surveyType]="NA"
                      [timestamp]="latestLssSurvey?.operatingLocationList?.lastUpdatedDate"
                    >
                    </last-updated-icon-and-timestamp>
                  </span>
                </div>
                <div class="divider"></div>
                <location-map
                  *ngIf="
                    latestLssSurvey?.operatingLocationList?.value &&
                      latestLssSurvey?.operatingLocationList?.value.length;
                    else noLocationMap
                  "
                  [source]="latestLssSurvey?.operatingLocationList?.value"
                  [country]="latestLssSurvey?.country?.name"
                  [multi]="true"
                  [editable]="false"
                  [showInfoWindow]="false"
                >
                </location-map>
                <ng-template #noLocationMap>
                  <div *ngIf="latestLssSurvey?.operatingInEntireCountry; else noResponseText">
                    <span class="sr-justify response">
                      {{
                        'OrgLocationComponent-OPERATING_ALL_OF_LOCATION'
                          | translate: { orgName: organization?.name, country: latestLssSurvey?.country?.name }
                      }}
                    </span>
                  </div>
                </ng-template>
              </div>
            </div>
          </li>
          <li id="li9">
            <div
              id="orgLocationActivityHistoryVideo"
              class="collapsible-header sr-bold blue-text text-size"
              [ngClass]="{ active: videoId }"
            >
              <i class="material-icons expand-more">expand_more</i>
              <i class="material-icons">video_library</i>
              {{ 'COMMON_SEARCH-SERVICES_LOCATION_VIDEO' | translate }}
            </div>

            <div class="collapsible-body text-size">
              <div class="lists row">
                <div class="survey">
                  <div class="question">
                    <!--Video URL-->
                    <div class="question">
                      <i
                        (click)="
                          openReport(
                            'activityHistoryVideoUrl',
                            this.translateService.instant('OrgLocationComponent-HISTORY_VIDEO_URL_TEXT', {
                              country: latestLssSurvey?.country?.name
                            })
                          )
                        "
                        class="material-icons statusFlag red-text"
                        >flag</i
                      >

                      <span class="sr-bold question">{{
                        'OrgLocationComponent-HISTORY_VIDEO_URL_TEXT'
                          | translate: { country: latestLssSurvey?.country?.name }
                      }}</span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="NA"
                          [timestamp]="latestLssSurvey?.activityHistoryVideoUrl?.lastUpdatedDate"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                    <div class="divider"></div>
                    <div *ngIf="!latestLssSurvey?.activityHistoryVideoUrl?.value">
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                    <div *ngIf="latestLssSurvey?.activityHistoryVideoUrl?.value">
                      <survey-response
                        [value]="latestLssSurvey?.activityHistoryVideoUrl?.value"
                        class="sr-justify response"
                      >
                        <parse-video-url
                          [source]="latestLssSurvey?.activityHistoryVideoUrl?.value"
                          videoId="orgLocationActivityHistoryVideo"
                          [autoplay]="videoId === 'orgLocationActivityHistoryVideo'"
                        ></parse-video-url>
                      </survey-response>
                    </div>

                    <div id="orgLocationCurrentProgramVideo" class="question">
                      <i
                        (click)="
                          openReport(
                            'currentProgramVideoUrl',
                            this.translateService.instant('OrgLocationComponent-CURRENT_PROGRAM_VIDEO_URL_TEXT', {
                              country: latestLssSurvey?.country?.name
                            })
                          )
                        "
                        class="material-icons statusFlag red-text"
                        >flag</i
                      >

                      <span class="sr-bold question">{{
                        'OrgLocationComponent-CURRENT_PROGRAM_VIDEO_URL_TEXT'
                          | translate: { country: latestLssSurvey?.country?.name }
                      }}</span>
                    </div>
                    <div class="dt-stamp">
                      <span class="left">
                        <last-updated-icon-and-timestamp
                          [surveyType]="NA"
                          [timestamp]="latestLssSurvey?.currentProgramVideoUrl?.lastUpdatedDate"
                        >
                        </last-updated-icon-and-timestamp>
                      </span>
                    </div>
                    <div class="divider"></div>
                    <div *ngIf="!latestLssSurvey?.currentProgramVideoUrl?.value">
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                    <div *ngIf="latestLssSurvey?.currentProgramVideoUrl?.value">
                      <survey-response
                        [value]="latestLssSurvey?.currentProgramVideoUrl?.value"
                        class="sr-justify response"
                      >
                        <parse-video-url
                          [source]="latestLssSurvey?.currentProgramVideoUrl?.value"
                          videoId="orgLocationCurrentProgramVideo"
                          [autoplay]="videoId === 'orgLocationCurrentProgramVideo'"
                        ></parse-video-url>
                      </survey-response>
                    </div>
                    <!--ended Video URL-->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <social-network
        class="col s12 m12 l4 right"
        [orgId]="organization?.organizationId"
        [locationId]="latestLssSurvey?.country?.countryId"
        [disasterId]="null"
      ></social-network>
    </div>
  </div>
</ng-template>
<report-modal [reportActions]="reportActions"></report-modal>

<ng-template #noResponseSurvRespComp>
  <survey-response>
    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
  </survey-response>
</ng-template>

<ng-template #noResponseText>
  <div class="response sr-justify">
    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
  </div>
</ng-template>

<ng-template #noResponseFileId>
  <div class="response sr-justify">
    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
  </div>
</ng-template>

<ng-template #noResponseWebLink>
  <div class="response sr-justify">
    <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-DOCUMENT_URL' | translate }}:</span>
    {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
  </div>
</ng-template>
