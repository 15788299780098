<div class="card" *ngIf="noErrorResponse">
  <div class="card-content white-text">
    <div class="card-title center cyan">
      {{ 'GeneralOrganizationalBackgroundSurveyComponent-TITLE' | translate }}
    </div>
    <div class="divider"></div>
    <div *ngIf="!surveyDataInput">
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div *ngIf="surveyDataInput">
      <p class="black-text">
        {{ 'SurveyCommon-CARD_INSTRUCTIONS' | translate }}
        {{ 'SurveyCommon-CARD_INSTRUCTIONS_1' | translate }}
      </p>
      <p class="black-text updated-mark" *ngIf="surveyDataInput.lastUpdatedUser && surveyDataInput.lastUpdatedDate">
        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType === UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_BY_SR_ADMIN'
              | translate
                : {
                    when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
                  }
          }}
        </ng-template>
        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType !== UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_AND_WHO'
              | translate
                : {
                    who: surveyDataInput.lastUpdatedUser.email,
                    when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
                  }
          }}
        </ng-template>
      </p>

      <!-- Affirmation Confirm -->
      <div class="col s12">
        <div class="input-field col s12">
          <div *ngIf="!affirmationUpToDate()">
            <!-- Only each quarter -->
            <div class="card black-text">
              <div class="card-stacked">
                <div class="card-content">
                  <span class="card-title affirmation-title red-text sr-bold">{{
                    'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-TITLE_1' | translate
                  }}</span>
                  <p>
                    {{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-TEXT_1' | translate }}
                  </p>
                </div>
                <div class="card-action affirmation-buttons">
                  <div class="row">
                    <input
                      type="checkbox"
                      class="filled-in"
                      id="filled-in-box"
                      [checked]="affirmation.two"
                      (change)="(affirmation.two = !affirmation.two) && (isDenyClicked = false)"
                    />
                    <label for="filled-in-box"
                      >{{ 'GeneralOrganizationalBackgroundSurvey-CHECKBOX-TEXT' | translate }}
                    </label>
                  </div>

                  <div class="row">
                    <button
                      class="waves-effect waves-light btn-flat red darken-3 white-text"
                      (click)="sendDenyNotification()"
                    >
                      <span>{{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-DENY' | translate }}</span>
                    </button>
                    <button
                      type="submit"
                      class="waves-effect waves-light btn-flat green darken-3 white-text"
                      (click)="(affirmation.one = true) && onDelaySaveAndExit(step1ModalActions)"
                      [disabled]="!affirmation.two"
                    >
                      <span>{{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-AGREE' | translate }}</span>
                    </button>
                  </div>

                  <div class="row" *ngIf="isDenyClicked && !affirmation.one">
                    <div class="sr-error-area shift-margin">
                      {{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-REJECTED-MESSAGE' | translate }}
                    </div>
                  </div>

                  <div class="row" *ngIf="affirmation.two && affirmation.one">
                    <div class="affirmation-alert card green white-text">
                      <i class="material-icons left" style="padding-top: 2px">check_circle</i>
                      <h6>
                        {{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-ACCEPTED_1' | translate }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul class="collection">
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(1, $event)">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(2, $event)">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_2_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(3, $event)">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_3_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(4, $event)">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(5, $event)">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_5_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(6, $event)">
            {{
              'GeneralOrganizationalBackgroundSurveyComponent-STEP_6_TITLE'
                | translate: { orgName: surveyDataInput.organizationName }
            }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(7, $event)">
            {{
              'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_TITLE'
                | translate: { orgName: surveyDataInput.organizationName }
            }}
          </a>
        </li>
        <li class="collection-item grey lighten-3" id="step8">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(8, $event)">
            {{ 'SurveyCommon-STEP_8_TITLE' | translate }}</a
          >
        </li>
        <li class="collection-item grey lighten-3" id="step9">
          <a class="modal-trigger modal-trigger-gob" href="" (click)="onStepClick(9, $event)">
            {{ 'SurveyCommon-STEP_9_TITLE' | translate }}</a
          >
        </li>
      </ul>

      <p *ngIf="surveyDataInput.outdated" class="red-text">
        {{ 'UNIVERSAL-SURVEY_LABEL_SURVEY_OUTDATED_WARNING' | translate }}
      </p>
    </div>
  </div>
</div>

<!-- Modal's overlay -->
<div id="overlay" [style.display]="overlayDisplay" [class.overlay-visible]="overlayVisible"></div>

<div *ngIf="surveyDataInput">
  <!-- Modal step 1: Edit Basic Information -->
  <div
    id="step1-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step1ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="1"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>

      <div class="row margin">
        <div id="ques11" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: questionCount } }}
        </div>
        <div class="spacing-div col s12">
          <label for="org_name" class="sr-label">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_Q_ORGANIZATION_NAME' | translate }}
          </label>
          <div class="input-field col s12">
            <i class="material-icons prefix">business</i>
            <input
              id="org_name"
              type="text"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="gobForm['organizationName'].valueControl"
              [placeholder]="'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_Q_ORGANIZATION_NAME' | translate"
            />
          </div>
          <last-edited-metadata [formElement]="gobForm['organizationName']"></last-edited-metadata>
        </div>

        <div id="ques12" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: questionCount } }}
        </div>
        <div class="spacing-div col s12">
          <label for="org_id" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_ID' | translate }}
          </label>
          <div class="input-field col s12">
            <i class="material-icons prefix">vpn_key</i>
            <input
              id="org_id"
              type="text"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="gobForm['govIdOrEin'].valueControl"
              [placeholder]="'CommonGOBSurvey-Q_ORG_ID' | translate"
            />
          </div>
          <last-edited-metadata [formElement]="gobForm['govIdOrEin']"></last-edited-metadata>
        </div>

        <div id="ques13" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: questionCount } }}
        </div>
        <div class="spacing-div col s12">
          <label for="org_id2" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_ID2' | translate }}
          </label>
          <div class="input-field col s12">
            <i class="material-icons prefix">vpn_key</i>
            <input
              id="org_id2"
              type="text"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="gobForm['govIdOrEin2'].valueControl"
              [placeholder]="'CommonGOBSurvey-Q_ORG_ID2' | translate"
            />
          </div>
          <last-edited-metadata [formElement]="gobForm['govIdOrEin2']"></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <div class="input-field col s12">
            <div id="ques14" class="red-text question-count">
              {{
                'CommonGOBSurvey-Q-QUESTION_NUMBER'
                  | translate
                    : {
                        QuestionNumber: questionNumber,
                        QuestionCount: questionCount
                      }
              }}
            </div>
            <div class="clearfix"></div>
            <div class="card">
              <div class="card-stacked">
                <div class="card-content">
                  <span class="card-title affirmation-title">{{
                    'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-TITLE_2'
                      | translate: { AffirmationCount: affirmationCount }
                  }}</span>
                  <p class="sr-bold">
                    {{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-HINT-TEXT' | translate }}
                  </p>
                  <div [innerHTML]="'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-TEXT_2' | translate"></div>
                </div>
                <div class="card-action affirmation-buttons">
                  <button
                    class="waves-effect waves-light btn-flat green darken-3 white-text"
                    (click)="affirmation.three = true"
                  >
                    <span>{{ 'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-UNDERSTOOD' | translate }}</span>
                  </button>
                </div>
                <div class="row" *ngIf="affirmation.three">
                  <div class="affirmation-alert card green">
                    <i class="material-icons white-text left" style="padding-top: 2px">check_circle</i>
                    <h6 class="white-text">
                      {{
                        'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-ACCEPTED_2'
                          | translate: { AffirmationCount: affirmationCount }
                      }}
                    </h6>
                  </div>
                </div>

                <div class="row" *ngIf="(!affirmationUpToDate() && !affirmed()) || !affirmed()">
                  <div class="affirmation-alert card red">
                    <i class="material-icons white-text left" style="padding-top: 2px">error</i>
                    <h6 class="white-text">
                      {{
                        'GeneralOrganizationalBackgroundSurvey-AFFIRMATION-REJECTED'
                          | translate
                            : {
                                CustomAffirmationMessage: customAffirmationMessage
                              }
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template [ngIf]="authService.isSuper()">
          <div id="ques15" class="red-text question-count">
            {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate : { QuestionNumber: 5,
            QuestionCount: questionCount } }}
          </div>
          <div class="spacing-div col s12">
            <input 
              id="caf_donation_enabled" 
              class="filled-in" 
              name="caf_donation_enabled" 
              type="checkbox"
              [checked]="isCafDonationEnabled" 
              (change)="toggleCAFDonationEnabled()" />
        
            <label for="caf_donation_enabled" class="sr-label">
              {{
              'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_CAF-DONATION-CHECKBOX-TEXT'
              | translate : { orgName: surveyDataInput.organizationName } }}
            </label>
          </div>
          <div class="spacing-div col s12" *ngIf="isCafDonationEnabled">
            <label for="org_caf_url" class="sr-label">
              {{
              'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_Q_CAF_DONATION_URL'
              | translate }}
            </label>
            <div class="input-field col s12" [ngClass]="{
                      'sr-has-error':
                        !gobForm['cafDonationUrl'].valueControl.valid &&
                        (gobForm['cafDonationUrl'].valueControl.touched || invalidOnInit?.singles?.cafDonationUrl)
                    }">
              <i class="material-icons prefix">link</i>
              <input 
                [placeholder]="'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_Q_CAF_DONATION_URL' | translate"
                id="org_caf_url" 
                materialize="characterCounter" 
                length="256" 
                maxlength="256" 
                type="url"
                [formControl]="gobForm['cafDonationUrl'].valueControl" />
              <div class="blue-text info shift-margin" *ngIf="isCafDonationEnabled && !cafDonationUrlExists()">
                {{
                'GeneralOrganizationalBackgroundSurveyComponent-STEP_1_CAF_DONATION_URL_EMPTY_FIELD'
                | translate }}
              </div>
              <div class="sr-error-area shift-margin" *ngIf="
                        !gobForm['cafDonationUrl'].valueControl.valid &&
                        (gobForm['cafDonationUrl'].valueControl.touched || invalidOnInit?.singles?.cafDonationUrl)
                      ">
                <div *ngIf="gobForm['cafDonationUrl'].valueControl.errors.pattern">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row caf-submit">
            <button type="submit" 
              class="waves-effect waves-light btn-flat green darken-3 white-text"
              (click)="updateCAFDonationData(step1ModalActions)" 
              [disabled]="isCAFDonationUrlNotValid() || canNotSaveAndExit()">
              <span>{{ 'UNIVERSAL-NAVIGATION_SUBMIT' | translate }}</span>
            </button>
          </div>
       </ng-template>
      </div>
   </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step1ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified() || !affirmed()"
        (click)="onSaveAndExit(step1ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [0])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [0])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit() || !affirmed()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 2: Edit Central headquarters Address -->
  <div
    id="step2-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step2ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="2"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_2_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div id="ques21" class="row margin">
        <label for="step2_address_1" class="sr-label">
          {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">home</i>
          <input
            id="step2_address_1"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="gobForm['hqAddress'].valueControl.controls['address1']"
            [placeholder]="'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 }"
          />
        </div>
        <label for="address2" class="sr-label">
          {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 } }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">home</i>
          <input
            id="address2"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="gobForm['hqAddress'].valueControl.controls['address2']"
            [placeholder]="'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 }"
          />
        </div>

        <label for="city" class="sr-label">
          {{ 'UNIVERSAL-ADDRESS_CITY' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">location_city</i>
          <input
            id="city"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqAddress'].valueControl.controls['city']"
            [placeholder]="'UNIVERSAL-ADDRESS_CITY' | translate"
          />
        </div>

        <label for="state_province" class="sr-label">
          {{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">location_city</i>
          <input
            id="state_province"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            (blur)="matchesUsaState($event)"
            [formControl]="gobForm['hqAddress'].valueControl.controls['state']"
            [placeholder]="'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate"
          />
        </div>

        <label for="zip" class="sr-label">
          {{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">room</i>
          <input
            id="zip"
            type="text"
            materialize="characterCounter"
            length="16"
            maxlength="16"
            [formControl]="gobForm['hqAddress'].valueControl.controls['zip']"
            [placeholder]="'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate"
          />
        </div>

        <div class="input-field spacing col s12 shift-padding">
          <div class="col m2 s12 label-text">
            {{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}
          </div>
          <select
            id="countriesList"
            class="browser-default col m6 s12"
            [formControl]="gobForm['hqAddress'].valueControl.controls['country']"
          >
            <option [ngValue]="null">
              {{ 'SurveyCommon-NO_COUNTRY_SELECTED' | translate }}
            </option>
            <option *ngFor="let country of countries" [ngValue]="country.countryId">
              {{ country.name }}
            </option>
          </select>
        </div>
        <last-edited-metadata class="no-left-spacing" [formElement]="gobForm['hqAddress']"></last-edited-metadata>
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step2ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step2ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [1])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [1])"
          class="modal-trigger modal-trigger-gob modal-action modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [1])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 3: Edit Central Headquarters Point of Contact -->
  <div
    id="step3-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step3ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="3"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_3_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div id="ques31" class="row margin">
        <label for="first_name" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">face</i>
          <input
            id="first_name"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqContact'].valueControl.controls['firstName']"
            [placeholder]="'UNIVERSAL-CONTACT_FIRST_NAME' | translate"
          />
        </div>

        <label for="last_name" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">face</i>
          <input
            id="last_name"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqContact'].valueControl.controls['lastName']"
            [placeholder]="'UNIVERSAL-CONTACT_LAST_NAME' | translate"
          />
        </div>

        <label for="title" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_TITLE' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">mode_edit</i>
          <input
            id="title"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqContact'].valueControl.controls['title']"
            [placeholder]="'UNIVERSAL-CONTACT_TITLE' | translate"
          />
        </div>

        <label for="phone_1" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_PHONE_NUMBERED' | translate: { num: '1' } }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">call</i>
          <input
            id="phone_1"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqContact'].valueControl.controls['phone1']"
            [placeholder]="'UNIVERSAL-CONTACT_PHONE_NUMBERED' | translate: { num: '1' }"
          />
          <p class="blue-text info shift-margin">
            {{ 'UNIVERSAL-INSTRUCTIONS_PHONE_FULL' | translate }}
          </p>
          <div
            class="shift-margin sr-error-area"
            *ngIf="
              !gobForm['hqContact'].valueControl.controls['phone1'].valid &&
              gobForm['hqContact'].valueControl.controls['phone1'].touched
            "
          >
            <div *ngIf="gobForm['hqContact'].valueControl.controls['phone1'].errors.phoneFormat">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
            </div>
          </div>
        </div>
        <label for="phone_2" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_PHONE_NUMBERED' | translate: { num: '2' } }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">call</i>
          <input
            id="phone_2"
            type="text"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [formControl]="gobForm['hqContact'].valueControl.controls['phone2']"
            [placeholder]="'UNIVERSAL-CONTACT_PHONE_NUMBERED' | translate: { num: '2' }"
          />
          <p class="blue-text info shift-margin">
            {{ 'UNIVERSAL-INSTRUCTIONS_PHONE_FULL' | translate }}
          </p>
          <div
            class="shift-margin sr-error-area"
            *ngIf="
              !gobForm['hqContact'].valueControl.controls['phone2'].valid &&
              gobForm['hqContact'].valueControl.controls['phone2'].touched
            "
          >
            <div *ngIf="gobForm['hqContact'].valueControl.controls['phone2'].errors.phoneFormat">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
            </div>
          </div>
        </div>
        <label for="email_1" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_EMAIL_NUMBERED' | translate: { num: '1' } }}
        </label>
        <div
          class="input-field spacing col s12"
          [ngClass]="{
            'sr-has-error':
              !gobForm['hqContact'].valueControl.controls['email'].valid &&
              (gobForm['hqContact'].valueControl.controls['email'].touched || invalidOnInit?.maps?.hqContact?.email)
          }"
        >
          <i class="material-icons prefix">email</i>
          <input
            id="email_1"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="gobForm['hqContact'].valueControl.controls['email']"
            [placeholder]="'UNIVERSAL-CONTACT_EMAIL_NUMBERED' | translate: { num: '1' }"
          />
          <div
            class="sr-error-area shift-margin"
            *ngIf="
              !gobForm['hqContact'].valueControl.controls['email'].valid &&
              (gobForm['hqContact'].valueControl.controls['email'].touched || invalidOnInit?.maps?.hqContact?.email)
            "
          >
            <div *ngIf="gobForm['hqContact'].valueControl.controls['email'].errors.mailFormat">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
        <label for="email_2" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_EMAIL_NUMBERED' | translate: { num: '2' } }}
        </label>
        <div
          class="input-field spacing col s12"
          [ngClass]="{
            'sr-has-error':
              !gobForm['hqContact'].valueControl.controls['alternativeEmail'].valid &&
              (gobForm['hqContact'].valueControl.controls['alternativeEmail'].touched ||
                invalidOnInit?.maps?.hqContact?.alternativeEmail)
          }"
        >
          <i class="material-icons prefix">email</i>
          <input
            id="email_2"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="gobForm['hqContact'].valueControl.controls['alternativeEmail']"
            [placeholder]="'UNIVERSAL-CONTACT_EMAIL_NUMBERED' | translate: { num: '2' }"
          />
          <div
            class="sr-error-area shift-margin"
            *ngIf="
              !gobForm['hqContact'].valueControl.controls['alternativeEmail'].valid &&
              (gobForm['hqContact'].valueControl.controls['alternativeEmail'].touched ||
                invalidOnInit?.maps?.hqContact?.alternativeEmail)
            "
          >
            <div *ngIf="gobForm['hqContact'].valueControl.controls['alternativeEmail'].errors.mailFormat">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
        <last-edited-metadata class="no-left-spacing" [formElement]="gobForm['hqContact']"></last-edited-metadata>
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step3ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step3ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [2])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [2])"
          class="modal-trigger modal-trigger-gob modal-action modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [2])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 4: Edit Organization Background Information -->
  <div
    id="step4-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step4ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="4"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <div id="ques41" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <label for="org_mission" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_MISSION_STATEMENT' | translate }} (
            {{ 'UNIVERSAL-MAX_CHARACTERS' | translate: { number: 600 } }}
            )
          </label>
          <div
            [ngClass]="{
              'sr-has-error': !gobForm['mission'].valueControl.valid && gobForm['mission'].valueControl.value
            }"
            class="input-field col s12"
          >
            <textarea
              id="org_mission"
              class="materialize-textarea"
              materialize="characterCounter"
              length="600"
              maxlength="600"
              [formControl]="gobForm['mission'].valueControl"
              placeholder="{{ 'CommonGOBSurvey-Q_ORG_MISSION_STATEMENT' | translate }}(  {{
                'UNIVERSAL-MAX_CHARACTERS' | translate: { number: 600 }
              }} )"
            >
            </textarea>
            <div
              class="sr-error-area shift-margin"
              *ngIf="!gobForm['mission'].valueControl.valid && gobForm['mission'].valueControl.value"
            >
              <div *ngIf="gobForm['mission'].valueControl.hasError('maxlength')">
                {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 600 } }}
              </div>
            </div>
          </div>
          <last-edited-metadata class="no-left-spacing textfield" [formElement]="gobForm['mission']">
          </last-edited-metadata>
        </div>

        <div id="ques42" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="rich-text-input-label">
            {{ 'CommonGOBSurvey-Q_ORG_SUMMARY' | translate }} ({{
              'UNIVERSAL-FORMATTED_MAX_CHARACTERS' | translate: { number: 1200 }
            }})
          </div>
          <div
            [ngClass]="{
              'sr-has-error': !gobForm['summary'].valueControl.valid && gobForm['summary'].valueControl.value
            }"
            class="input-field col s12"
          >
            <rich-text-editor id="summary" [form]="gobForm['summary'].valueControl"> </rich-text-editor>
            <div
              class="sr-error-area shift-margin"
              *ngIf="!gobForm['summary'].valueControl.valid && gobForm['summary'].valueControl.value"
            >
              <div *ngIf="gobForm['summary'].valueControl.hasError('maxlength')">
                {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 1200 } }}
              </div>
            </div>
          </div>
          <last-edited-metadata class="no-left-spacing textfield" [formElement]="gobForm['summary']">
          </last-edited-metadata>
        </div>
        <div id="ques43" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 4 } }}
        </div>
        <!-- Modal step 4: Org Intro Video URL -->
        <div class="spacing-div col s12">
          <label for="org_video_url" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_INTRO_URL' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['orgIntroVideoUrl'].valueControl.valid &&
                (gobForm['orgIntroVideoUrl'].valueControl.touched || invalidOnInit?.singles?.orgIntroVideoUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              id="org_video_url"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [formControl]="gobForm['orgIntroVideoUrl'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['orgIntroVideoUrl'].valueControl.valid &&
                (gobForm['orgIntroVideoUrl'].valueControl.touched || invalidOnInit?.singles?.orgIntroVideoUrl)
              "
            >
              <div *ngIf="gobForm['orgIntroVideoUrl'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
              <div *ngIf="gobForm['orgIntroVideoUrl'].valueControl.hasError('vimeoYoutubeUrlFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_VIMEO_YOUTUBE_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['orgIntroVideoUrl']"></last-edited-metadata>
        </div>
        <div class="spacing-div col s12">
          <label for="staff_video_url" class="sr-label">
            {{ 'CommonGOBSurvey-Q_STAFF_INTRO_URL' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['staffIntroVideoUrl'].valueControl.valid &&
                (gobForm['staffIntroVideoUrl'].valueControl.touched || invalidOnInit?.singles?.staffIntroVideoUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              id="staff_video_url"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [formControl]="gobForm['staffIntroVideoUrl'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['staffIntroVideoUrl'].valueControl.valid &&
                (gobForm['staffIntroVideoUrl'].valueControl.touched || invalidOnInit?.singles?.staffIntroVideoUrl)
              "
            >
              <div *ngIf="gobForm['staffIntroVideoUrl'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
              <div *ngIf="gobForm['staffIntroVideoUrl'].valueControl.hasError('vimeoYoutubeUrlFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_VIMEO_YOUTUBE_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['staffIntroVideoUrl']"></last-edited-metadata>
        </div>

        <div id="ques44" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 4 } }}
        </div>
        <div class="input-field col s12">
          <div class="sr-bold">{{ 'CommonGOBSurvey-Q_ORG_TYPE' | translate }}:</div>
          <p>
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_NOTE' | translate }}
            {{ surveyDataInput.organizationName }}
          </p>
        </div>

        <div class="spacing col s12">
          <p>
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE1' | translate }}
          </p>
          <input
            [formControl]="gobForm['orgTypeFaithBased'].valueControl"
            [value]="true"
            name="faith_based_radio"
            type="radio"
            id="faith_based_radio_yes"
            class="with-gap"
          />
          <label for="faith_based_radio_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <input
            [formControl]="gobForm['orgTypeFaithBased'].valueControl"
            [value]="false"
            name="faith_based_radio"
            type="radio"
            id="faith_based_radio_no"
            class="with-gap"
          />
          <label for="faith_based_radio_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
          <last-edited-metadata class="no-left-spacing no-padding" [formElement]="gobForm['orgTypeFaithBased']">
          </last-edited-metadata>
        </div>

        <div class="spacing col s12">
          <p>
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE2' | translate }}
          </p>
          <input
            [formControl]="gobForm['orgTypeNationalOrganization'].valueControl"
            [value]="true"
            name="national_organization_radio"
            type="radio"
            id="national_organization_radio_yes"
            class="with-gap"
          />
          <label for="national_organization_radio_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <input
            [formControl]="gobForm['orgTypeNationalOrganization'].valueControl"
            [value]="false"
            name="national_organization_radio"
            type="radio"
            id="national_organization_radio_no"
            class="with-gap"
          />
          <label for="national_organization_radio_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
          <last-edited-metadata
            class="no-left-spacing no-padding"
            [formElement]="gobForm['orgTypeNationalOrganization']"
          ></last-edited-metadata>
        </div>

        <div class="spacing col s12">
          <p>
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE3' | translate }}
          </p>
          <input
            [formControl]="gobForm['orgTypeLocalCivilSociety'].valueControl"
            [value]="true"
            name="local_civil_society_radio"
            type="radio"
            id="local_civil_society_radio_yes"
            class="with-gap"
          />
          <label for="local_civil_society_radio_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <input
            [formControl]="gobForm['orgTypeLocalCivilSociety'].valueControl"
            [value]="false"
            name="local_civil_society_radio"
            type="radio"
            id="local_civil_society_radio_no"
            class="with-gap"
          />
          <label for="local_civil_society_radio_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
          <last-edited-metadata class="no-left-spacing no-padding" [formElement]="gobForm['orgTypeLocalCivilSociety']">
          </last-edited-metadata>
        </div>

        <div class="spacing col s12">
          <p>
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_4_Q_ORG_TYPE_CB_TYPE4' | translate }}
          </p>
          <input
            [formControl]="gobForm['orgTypeInternationalNGO'].valueControl"
            [value]="true"
            name="international_ngo_radio"
            type="radio"
            id="international_ngo_radio_yes"
            class="with-gap"
          />
          <label for="international_ngo_radio_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <input
            [formControl]="gobForm['orgTypeInternationalNGO'].valueControl"
            [value]="false"
            name="international_ngo_radio"
            type="radio"
            id="international_ngo_radio_no"
            class="with-gap"
          />
          <label for="international_ngo_radio_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
          <last-edited-metadata class="no-left-spacing no-padding" [formElement]="gobForm['orgTypeInternationalNGO']">
          </last-edited-metadata>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step4ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step4ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [3])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [3])"
          class="modal-trigger modal-trigger-gob modal-action modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [3])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 5: Edit Budget Information -->

  <div
    id="step5-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step5ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="5"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_5_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <div id="ques51" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 7 } }}
        </div>
        <br />
        <div class="spacing-div col s12">
          <label for="org_website" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_WEBSITE' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['website'].valueControl.valid &&
                (gobForm['website'].valueControl.touched || invalidOnInit?.singles?.website)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              [placeholder]="'CommonGOBSurvey-Q_ORG_WEBSITE' | translate"
              id="org_website"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [formControl]="gobForm['website'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['website'].valueControl.valid &&
                (gobForm['website'].valueControl.touched || invalidOnInit?.singles?.website)
              "
            >
              <div *ngIf="gobForm['website'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['website']"></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <label for="donate_url" class="sr-label">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_5_Q_DONATE_URL' | translate }}
          </label>
          <div class="input-field col s12" [ngClass]="{ 'sr-has-error': displayUrlError() }">
            <i class="material-icons prefix">link</i>
            <input
              type="url"
              (ngModelChange)="handleUrlChange($event)"
              [placeholder]="'GeneralOrganizationalBackgroundSurveyComponent-STEP_5_Q_DONATE_URL' | translate"
              id="donate_url"
              materialize="characterCounter"
              length="512"
              maxlength="512"
              [formControl]="gobForm['donateUrl'].valueControl"
            />
            <div class="sr-error-area shift-margin" *ngIf="displayUrlError()">
              <div *ngIf="gobForm['donateUrl'].valueControl.invalid">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['donateUrl']"></last-edited-metadata>
        </div>
        <div class="spacing-div col s12">
          <label for="enableDonateButtonBox" class="sr-label">
            {{
              'GeneralOrganizationalBackgroundSurvey-FUNDRAISE-CHECKBOX-TEXT'
                | translate: { orgName: surveyDataInput.organizationName }
            }}
          </label>
          <div class="input-field col s12">
            <input
              type="checkbox"
              class="filled-in enableDonateButtonBox"
              id="enableDonateButtonBox"
              name="donateButtonDisabled"
              [attr.disabled]="isDonateUrlValid()"
              [formControl]="gobForm['donateButtonDisabled'].valueControl"
            />
            <label for="enableDonateButtonBox" class="enableDonateButtonBoxLabel" (click)="toggleDonations()">
              <span *ngIf="gobForm.donateButtonDisabled.valueControl.value">OFF</span>
              <span *ngIf="!gobForm.donateButtonDisabled.valueControl.value">ON</span>
            </label>
          </div>
        </div>

        <div class="spacing-div col s12">
          <label for="facebook_url" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_FACEBOOK_URL' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['facebookUrl'].valueControl.valid &&
                (gobForm['facebookUrl'].valueControl.touched || invalidOnInit?.singles?.facebookUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              type="url"
              [placeholder]="'CommonGOBSurvey-Q_ORG_FACEBOOK_URL' | translate"
              id="facebook_url"
              materialize="characterCounter"
              length="512"
              maxlength="512"
              [formControl]="gobForm['facebookUrl'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['facebookUrl'].valueControl.valid &&
                (gobForm['facebookUrl'].valueControl.touched || invalidOnInit?.singles?.facebookUrl)
              "
            >
              <div *ngIf="gobForm['facebookUrl'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['facebookUrl']"></last-edited-metadata>
        </div>

        <div id="ques52" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 7 } }}
        </div>
        <br />
        <div class="spacing-div col s12">
          <label for="annual_budget" class="sr-label">
            {{
              'CommonGOBSurvey-Q_ORG_OVERALL_ANNUAL_BUDGET' | translate: { orgName: surveyDataInput.organizationName }
            }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['overallAnnualBudget'].valueControl.valid &&
                (gobForm['overallAnnualBudget'].valueControl.touched || invalidOnInit?.singles?.overallAnnualBudget)
            }"
          >
            <select
              id="currencyCodeList"
              class="browser-default col s4 m3 l2"
              [formControl]="gobForm['overallAnnualBudget'].valueControl.controls['currency']"
            >
              <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
                {{ currencyCode }}
              </option>
            </select>
            <div class="col s8 m9 l10">
              <input
                [ngClass]="{
                  fieldWithLongLabel: !gobForm['overallAnnualBudget'].valueControl.value
                }"
                id="annual_budget"
                type="text"
                materialize="characterCounter"
                length="15"
                maxlength="15"
                [formControl]="gobForm['overallAnnualBudget'].valueControl.controls['amount']"
                [placeholder]="
                  'CommonGOBSurvey-Q_ORG_OVERALL_ANNUAL_BUDGET'
                    | translate: { orgName: surveyDataInput.organizationName }
                "
              />

              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !gobForm['overallAnnualBudget'].valueControl.controls['amount'].valid &&
                  (gobForm['overallAnnualBudget'].valueControl.controls['amount'].touched ||
                    invalidOnInit?.maps?.overallAnnualBudget?.amount)
                "
              >
                <div *ngIf="gobForm['overallAnnualBudget'].valueControl.controls['amount'].errors.pattern">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
                </div>
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['overallAnnualBudget']"></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <label class="sr-label" for="fiscal_year">
            {{ 'CommonGOBSurvey-Q_ORG_FISCAL_YR_ENDING' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['fiscalYearEnding'].valueControl.valid && gobForm['fiscalYearEnding'].valueControl.value
            }"
          >
            <i class="material-icons prefix">today</i>
            <input
              type="date"
              min="1000-01-01"
              max="3000-01-01"
              id="fiscal_year"
              [formControl]="gobForm['fiscalYearEnding'].valueControl"
              [placeholder]="'CommonGOBSurvey-Q_ORG_FISCAL_YR_ENDING' | translate"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="!gobForm['fiscalYearEnding'].valueControl.valid && gobForm['fiscalYearEnding'].valueControl.value"
            >
              <div *ngIf="gobForm['fiscalYearEnding'].valueControl.hasError('dateFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
              </div>
              <div *ngIf="gobForm['fiscalYearEnding'].valueControl.hasError('fiscalYearDate')">
                {{ 'UNIVERSAL-FORM_ERRORS_FISCAL_YEAR_DATE' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['fiscalYearEnding']"></last-edited-metadata>
        </div>

        <div id="ques53" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 7 } }}
        </div>
        <div class="spacing-div col s12">
          <label for="paid_staff" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_TOTAL_PAID_STAFF' | translate: { orgName: surveyDataInput.organizationName } }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['staffCount'].valueControl.valid &&
                (gobForm['staffCount'].valueControl.touched || invalidOnInit?.singles?.staffCount)
            }"
          >
            <i class="material-icons prefix">people</i>
            <input
              [ngClass]="{
                fieldWithLongLabel: !gobForm['staffCount'].valueControl.value
              }"
              id="paid_staff"
              type="text"
              materialize="characterCounter"
              length="6"
              maxlength="6"
              [formControl]="gobForm['staffCount'].valueControl"
              [placeholder]="
                'CommonGOBSurvey-Q_ORG_TOTAL_PAID_STAFF' | translate: { orgName: surveyDataInput.organizationName }
              "
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['staffCount'].valueControl.valid &&
                (gobForm['staffCount'].valueControl.touched || invalidOnInit?.singles?.staffCount)
              "
            >
              <div *ngIf="gobForm['staffCount'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NUMBER' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['staffCount']"></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <label for="paid_staff_hq" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_TOTAL_PAID_STAFF_HQ' | translate: { orgName: surveyDataInput.organizationName } }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['hqStaffCount'].valueControl.valid &&
                (gobForm['hqStaffCount'].valueControl.touched || invalidOnInit?.singles?.hqStaffCount)
            }"
          >
            <i class="material-icons prefix">people</i>
            <input
              [ngClass]="{
                fieldWithLongLabel: !gobForm['hqStaffCount'].valueControl.value
              }"
              id="paid_staff_hq"
              type="text"
              materialize="characterCounter"
              length="6"
              maxlength="6"
              [formControl]="gobForm['hqStaffCount'].valueControl"
              [placeholder]="
                'CommonGOBSurvey-Q_ORG_TOTAL_PAID_STAFF_HQ' | translate: { orgName: surveyDataInput.organizationName }
              "
            />

            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !gobForm['hqStaffCount'].valueControl.valid &&
                (gobForm['hqStaffCount'].valueControl.touched || invalidOnInit?.singles?.hqStaffCount)
              "
            >
              <div *ngIf="gobForm['hqStaffCount'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NUMBER' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['hqStaffCount']"></last-edited-metadata>
        </div>

        <div id="ques54" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 7 } }}
        </div>
        <div class="spacing-div col s12">
          <label for="twitter_handle" class="sr-label">
            {{ 'CommonGOBSurvey-Q_ORG_TWITTER_HANDLE' | translate }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !gobForm['twitterHandle'].valueControl.valid && gobForm['twitterHandle'].valueControl.value
            }"
          >
            <i class="prefix">
              <img src="assets/images/twitter-icon.png" />
            </i>
            <input
              [ngClass]="{
                fieldWithLongLabel: !gobForm['twitterHandle'].valueControl.value
              }"
              id="twitter_handle"
              type="text"
              materialize="characterCounter"
              length="128"
              maxlength="128"
              [formControl]="gobForm['twitterHandle'].valueControl"
              [placeholder]="'CommonGOBSurvey-Q_ORG_TWITTER_HANDLE' | translate"
            />

            <div
              class="sr-error-area shift-margin"
              *ngIf="!gobForm['twitterHandle'].valueControl.valid && gobForm['twitterHandle'].valueControl.value"
            >
              <div *ngIf="gobForm['twitterHandle'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_ILLEGAL_TWITTER_USERNAME' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="gobForm['twitterHandle']"></last-edited-metadata>
        </div>

        <div id="ques55" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 5, QuestionCount: 7 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="col s12">
            <p class="shift-margin">
              {{
                'CommonGOBSurvey-Q_ORG_DISCRIMINATION_POLICY' | translate: { orgName: surveyDataInput.organizationName }
              }}
            </p>
            <input
              [formControl]="gobForm['discriminationPolicy'].valueControl"
              [value]="true"
              name="non_discrimination_policy"
              type="radio"
              id="non_discrimination_yes_rb"
              class="with-gap"
            />
            <label for="non_discrimination_yes_rb" class="shift-margin">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="gobForm['discriminationPolicy'].valueControl"
              [value]="false"
              name="non_discrimination_policy"
              type="radio"
              id="non_discrimination_no_rb"
              class="with-gap"
            />
            <label for="non_discrimination_no_rb" class="shift-margin">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>
          <last-edited-metadata [formElement]="gobForm['discriminationPolicy']"></last-edited-metadata>
        </div>

        <div id="ques56" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 6, QuestionCount: 7 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="col s12">
            <p class="shift-margin">
              {{
                'CommonGOBSurvey-Q_ORG_RELIGIOUS_AFFLIATION' | translate: { orgName: surveyDataInput.organizationName }
              }}
            </p>
            <input
              [formControl]="gobForm['religiousAffiliation'].valueControl"
              [value]="true"
              name="religious_affiliation"
              type="radio"
              id="religious_yes_rb"
              class="with-gap"
            />
            <label for="religious_yes_rb" class="shift-margin">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="gobForm['religiousAffiliation'].valueControl"
              [value]="false"
              name="religious_affiliation"
              type="radio"
              id="religious_no_rb"
              class="with-gap"
            />
            <label for="religious_no_rb" class="shift-margin">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>
          <last-edited-metadata [formElement]="gobForm['religiousAffiliation']"></last-edited-metadata>
        </div>

        <div id="ques57" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 7, QuestionCount: 7 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="col s12">
            <p class="shift-margin">
              {{
                'CommonGOBSurvey-Q_ORG_WHISTLEBLOWER_POLICY' | translate: { orgName: surveyDataInput.organizationName }
              }}
            </p>
            <input
              [formControl]="gobForm['whistleblowerPolicy'].valueControl"
              [value]="true"
              name="whistleblower_policy"
              type="radio"
              id="whistleblower_yes_rb"
              class="with-gap"
            />
            <label for="whistleblower_yes_rb" class="shift-margin">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="gobForm['whistleblowerPolicy'].valueControl"
              [value]="false"
              name="whistleblower_policy"
              type="radio"
              id="whistleblower_no_rb"
              class="with-gap"
            />
            <label for="whistleblower_no_rb" class="shift-margin">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>
          <last-edited-metadata [formElement]="gobForm['whistleblowerPolicy']"></last-edited-metadata>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step5ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step5ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [4])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [4])"
          class="modal-trigger modal-trigger-gob modal-action modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>

        <button
          (click)="onModalAction(onPrev, [4])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>
  <!-- Modal step 6: Edit Countries In Which This Organization Works -->
  <div
    id="step6-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step6ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="6"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{
          'GeneralOrganizationalBackgroundSurveyComponent-STEP_6_TITLE'
            | translate: { orgName: surveyDataInput.organizationName }
        }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <p id="ques61" class="blue-text info">
        {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_6_NOTE' | translate }}
      </p>
      <div
        class="sr-error-area"
        *ngIf="
          !gobForm['userManagementFormValid'].valueControl.valid &&
          gobForm['userManagementFormValid'].valueControl.dirty
        "
      >
        <p>{{ 'UNIVERSAL-FORM_ERRORS_ADD_USER_INCOMPLETE_TOP' | translate }}</p>
      </div>
      <gob-user-management
        (status)="onChildFormEvent('userManagementFormValid', $event)"
        [parentEvent$]="userFormAction.asObservable()"
        [organizationId]="surveyDataInput.organizationId"
      ></gob-user-management>
      <div
        class="col s12 sr-error-area"
        *ngIf="
          !gobForm['userManagementFormValid'].valueControl.valid &&
          gobForm['userManagementFormValid'].valueControl.dirty
        "
      >
        <p>{{ 'UNIVERSAL-FORM_ERRORS_ADD_USER_INCOMPLETE_BOTTOM' | translate }}</p>
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step6ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step6ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [5])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [5])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [5])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 7: Edit Countries In Which This Organization Works -->
  <div
    id="step7-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    (click)="surveyCanceled = false"
    [materializeParams]="modalParams"
    [materializeActions]="step7ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="7"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{
          'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_TITLE'
            | translate: { orgName: surveyDataInput.organizationName }
        }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <gob-location-operation
        [locationContactList]="surveyDataInput.locationContactList"
        [surveyCanceled]="surveyCanceled"
        (surveyUpdated)="onSurveyChange($event)"
      >
      </gob-location-operation>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step7ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        (click)="onSaveAndExit(step7ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [6])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [6])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn cyan"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [6])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal step 8: End of Survey reminder about Empty or Outdated Questions -->
  <div
    id="step8-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    (click)="surveyCanceled = false"
    [materializeParams]="modalParams"
    [materializeActions]="step8ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="8"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'SurveyCommon-STEP_8_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div class="card">
        <div class="card-content black-text">
          <div class="card-text-wrapper grey lighten-3">
            <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
              <thead>
                <tr class="sr-base-table-row override-th-alignment">
                  <th *ngFor="let head of headElements; let i = index" (click)="setSortedField(head.sortName)">
                    {{ head.name | translate }}
                    <i class="tiny material-icons">swap_vert</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let msg of emptyOutdatedQuestions
                      | genericSortPipe: sortField:sortDescending
                      | paginate
                        : {
                            itemsPerPage: itemsPerPageArg,
                            currentPage: selectedPage
                          }
                  "
                >
                  <td>
                    <a href="#" (click)="onModalAction(onSelectQuestion, [msg.stepNumber, msg.questionNumber])">
                      {{ msg.surveyName }}</a
                    >
                  </td>
                  <td>{{ msg.surveyTypeFull }}</td>
                  <td>{{ msg.stepNumber }}</td>
                  <td>{{ msg.questionNumber }}</td>
                  <td>{{ msg.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col s12 m12">
        <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(step8ModalActions)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtReturn"></ng-content>
      </button>
      <div class="right">
        <button
          (click)="onModalAction(onPrev, [7])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3 left"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
        <button
          class="modal-trigger waves-effect waves-light btn red darken-3 left"
          *ngIf="canNotSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [7])"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          type="submit"
          class="waves-effect waves-light btn cyan darken-3"
          (click)="onSaveAndExit(step8ModalActions)"
          [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
        >
          <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
        </button>

        <button
          class="waves-effect waves-light btn cyan darken-3"
          *ngIf="authService.isOrgGeneral() && showLSSButton"
          (click)="onSaveAndOpenLSS(step8ModalActions)"
          [disabled]="canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtSaveAndOpenLSS"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- GOB error Modal -->
  <div
    id="gob-error-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    (click)="surveyCanceled = false"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.error"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="-1"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
        (errorModal)="closeErrorModal()"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'SurveyCommon-ERROR-MODAL_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div *ngIf="errStepDetails?.length > 0">
        <p class="black-text">
          {{ 'SurveyCommon-ERROR-MODAL_FIX_ERROR_TEXT' | translate }}
        </p>
        <div class="card">
          <div class="card-content black-text">
            <div class="card-text-wrapper grey lighten-3">
              <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
                <thead>
                  <tr class="sr-base-table-row">
                    <th>
                      {{ 'SurveyCommon-ERROR-MODAL_STEP_QUESTION_HEADER' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let err of errStepDetails
                        | paginate
                          : {
                              itemsPerPage: itemsPerPageArg,
                              currentPage: errSelectedPage,
                              id: 'errPage'
                            }
                    "
                  >
                    <td>
                      <a
                        [routerLink]="do_nothing"
                        (click)="onModalAction(onSelectQuestion, [err.stepNum, err.questionNum])"
                      >
                        {{
                          'SurveyCommon-ERROR-MODAL_STEP_DETAIL'
                            | translate
                              : {
                                  stepNum: err.stepNum,
                                  questionNum: err.questionNum,
                                  questionCount: err.questionCount
                                }
                        }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col s12 m12">
          <pagination-controls (pageChange)="errSelectedPage = $event" id="errPage"> </pagination-controls>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button
        type="submit"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(prevStepModal)"
        [disabled]="canNotSaveAndExit() || !surveyHasBeenModified()"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>

      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="onCancel(modalActions.error)"
        [disabled]="isSubmitting"
      >
        <ng-content *ngTemplateOutlet="btnTxtReturn"></ng-content>
      </button>
      <div class="right">
        <button
          (click)="onModalAction(onPrev, [-1, prevStepIndex])"
          class="modal-trigger modal-trigger-gob modal-close waves-effect waves-light btn yellow darken-3"
          [disabled]="isSubmitting || canNotSaveAndExit()"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #btnTxtCancel>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
  >
    clear
  </i>
</ng-template>
<ng-template #btnTxtErr>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
  >
    error
  </i>
</ng-template>
<ng-template #btnTxtNext>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
  >
    chevron_right
  </i>
</ng-template>
<ng-template #btnTxtPrev>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_PREV' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_PREV' | translate"
  >
    chevron_left
  </i>
</ng-template>
<ng-template #btnTxtReturn>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
  >
    clear
  </i>
</ng-template>
<ng-template #btnTxtSave>
  <i
    class="material-icons hide-on-large-only"
    [title]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
  >
    save
  </i>
  <span class="hide-on-small-only hide-on-med-only">{{ 'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate }}</span>
</ng-template>
<ng-template #btnTxtSaveAndOpenLSS>
  <i
    class="material-icons hide-on-large-only"
    [title]="'UNIVERSAL-NAVIGATION_SAVE_AND_OPEN_LSS' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_SAVE_AND_OPEN_LSS' | translate"
  >
    shortcut
  </i>
  <span class="hide-on-small-only hide-on-med-only">{{ 'UNIVERSAL-NAVIGATION_SAVE_AND_OPEN_LSS' | translate }}</span>
</ng-template>
