<app-loader [loading]="loading"></app-loader>
<ng-template [ngIf]="latestGobSurvey && latestDssSurvey">
  <div class="row">
    <div class="col s12">
      <nav class="breadcrumb-nav">
        <div class="nav-wrapper">
          <div class="col l7 m7 s12 breadcrumb-div">
            <a routerLink="/" class="breadcrumb">
              {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
            </a>
            <a [routerLink]="['/organization/', context.orgId]" class="breadcrumb sr-link">
              {{ context.orgName }}
            </a>
            <a [routerLink]="['/disaster/', context.disaster.disasterId]" class="breadcrumb sr-link">
              {{ context.disaster?.name }}
            </a>
          </div>
          <div class="col l5 m5 s12 right-align breadcrumb-div">
            <a [routerLink]="['/disaster/', context.disaster.disasterId]" class="orgs-link text-size">
              {{ 'OrgDisasterComponent-LINK' | translate: { disasterName: context.disaster?.name } }}
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="col s12 divider"></div>
  </div>
  <div class="row">
    <div class="col s8 m3 offset-s2" [class.l2]="context.plansToRespond">
      <img alt="organization logo" src="{{ orgLogoURI }}" class="collection-img" /> <br />
      <div class="sr-bold donate-text">
        <a
          target="_blank"
          [href]="latestGobSurvey.donateUrl?.value | linkHttp"
          (click)="openDonateModal()"
          [ngClass]="{
            disabled:
              context.donateButtonDisabled ||
              latestGobSurvey.donateUrl?.value === '' ||
              latestGobSurvey.donateUrl?.value === null
          }"
          class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
        >
          {{ 'OrgDisasterComponent-DONATE_CARD_1' | translate }}
          {{
            'OrgDisasterComponent-DONATE_CARD_2'
              | translate: { orgName: context.orgName, disasterName: context.disaster.name }
          }}
        </a>
        <ng-template [ngIf]="iscafDonationEnabled">
          <a
            target="_blank"
            [href]="latestGobSurvey.organizationDto?.cafDonationUrl | linkHttp"
            class="big-donate-btn btn red lighten-1 waves-effect waves-light"
            >
              <span>{{ 'COMMON_SEARCH-DONATE-VIA' | translate }}</span>
              <img
                src="assets/images/caf-america-logo.png"
                alt="{{ 'COMMON_SEARCH-DONATE-VIA-CAF-AMERICA' | translate }}"
                class="caf-logo"
              />
          </a>
        </ng-template>
      </div>
      <div class="sr-bold donate-text">
        <a
          target="_blank"
          (click)="requestDssSurveyUpdate()"
          [href]=""
          [ngClass]=""
          class="big-donate-btn btn blue lighten-1 waves-effect waves-light"
        >
          {{ 'COMMON_SEARCH-REQUEST-SURVEY-UPDATE' | translate }}
        </a>
      </div>
      <!-- Learn more about org link -->
      <div class="both-padding center-align">
        <a [routerLink]="['/organization/', context.orgId]" class="text-size">
          {{ 'COMMON_SEARCH-LEARN_MORE_ABOUT_ORG' | translate: { orgName: context.orgName } }}
        </a>
      </div>
    </div>

    <!-- Donation Modal -->
    <div
      id="donateModal"
      class="modal modal-fixed-footer"
      materialize="modal"
      [materializeParams]="[{ dismissible: true }]"
      [materializeActions]="donateModalActions"
    >
      <donation-modal
        [orgName]="context.orgName"
        [orgId]="context.orgId"
        [donateUrl]="latestGobSurvey?.donateUrl?.value"
        [donateModalActions]="donateModalActions"
        [tippingModalActions]="tippingModalActions"
      >
      </donation-modal>
    </div>
    <!--Tipping Modal-->
    <div
      id="tippingModal"
      class="modal modal-fixed-footer"
      materialize="modal"
      [materializeParams]="[{ dismissible: true }]"
      [materializeActions]="tippingModalActions"
    >
      <givelively-donation
        [donateModalActions]="donateModalActions"
        [tippingModalActions]="tippingModalActions"
        [tippingText]="true"
      >
      </givelively-donation>
    </div>

    <div class="col s12 m9 text-size" [class.l6]="context.plansToRespond">
      <h5 class="blue-text lighten-1">
        {{
          'OrgDisasterComponent-DESCRIPTION_1'
            | translate: { orgName: context.orgName, disasterName: context.disaster.name }
        }}
      </h5>

      <div class="col s12 m12 both-padding">
        <div class="description-element both-padding">
          <div class="sr-justify">
            <survey-response [value]="latestGobSurvey?.mission?.value">
              {{ latestGobSurvey?.mission?.value }}
            </survey-response>
          </div>
        </div>
        <div class="description-element both-padding">
          <span class="sr-bold">
            {{ 'OrgDisasterComponent-DESCRIPTION_3' | translate }}
          </span>
          <div>
            <survey-response [value]="address">
              {{ address | locationify | translate }}
            </survey-response>
          </div>
        </div>
        <div *ngIf="context?.plansToRespond" class="description-element both-padding">
          <span class="sr-bold">
            {{ 'OrgDisasterComponent-DESCRIPTION_4' | translate }}
          </span>
          <survey-response [value]="latestDssSurvey?.solicitDonation?.value?.isCurrentlySolicitingDonation">
            {{
              latestDssSurvey?.solicitDonation?.value?.isCurrentlySolicitingDonation
                ? ('UNIVERSAL-YES' | translate)
                : ('UNIVERSAL-NO' | translate)
            }}
          </survey-response>
        </div>
        <div class="description-element both-padding">
          <span class="sr-bold">{{ 'OrgDisasterComponent-DESCRIPTION_5' | translate }}</span>
          <survey-response
            *ngIf="context?.plansToRespond; else noPlanToRespond"
            [value]="latestDssSurvey?.reliefRecoveryService?.value?.isCurrentlyProvidingService"
          >
            {{
              latestDssSurvey?.reliefRecoveryService?.value?.isCurrentlyProvidingService
                ? ('UNIVERSAL-YES' | translate)
                : ('UNIVERSAL-NO' | translate)
            }}
          </survey-response>
          <ng-template #noPlanToRespond>
            {{ 'OrgDisasterComponent-NO_RESPONSE_PLANNED' | translate }}
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col s12 m12 text-size" [class.l4]="context.plansToRespond">
      <ng-container *ngIf="context?.plansToRespond">
        <div class="col s12 m6 l12 left-padding center">
          <div class="card org-card">
            <div class="card-content">
              <p class="center blue-text sr-bold text-size">
                {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_1' | translate }}
              </p>
              <div class="divider"></div>
              <div class="grey-text">
                <last-updated-icon-and-timestamp
                  [surveyType]="dss"
                  [timestamp]="getTimestampForResponse('dss', 'localStaff')"
                >
                </last-updated-icon-and-timestamp>
              </div>
              <div class="top-padding">
                <div>
                  <span class="sr-bold"> {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_2' | translate }}: </span>
                  <survey-response [value]="context.localStaffCount">
                    {{ context.localStaffCount }}
                  </survey-response>
                </div>
                <div>
                  <span class="sr-bold"> {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_3' | translate }}: </span>
                  <survey-response [value]="context.nonLocalStaffCount">
                    {{ context.nonLocalStaffCount }}
                  </survey-response>
                </div>
                <div>
                  <span class="sr-bold"> {{ 'COMMON_SEARCH-STAFF_COUNT_CARD_4' | translate }}: </span>
                  <survey-response [value]="context.volunteerStaffCount">
                    {{ context.volunteerStaffCount }}
                  </survey-response>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col s12 m6 l12 right-padding center">
          <div class="card org-card">
            <div class="card-content row">
              <p class="center blue-text sr-bold text-size">
                {{ 'OrgDisasterComponent-MONEY_CARD_0' | translate }}
              </p>
              <div class="divider"></div>
              <div class="grey-text">
                <last-updated-icon-and-timestamp
                  [surveyType]="dss"
                  [timestamp]="getTimestampForResponse('dss', 'raisedFund')"
                >
                </last-updated-icon-and-timestamp>
              </div>
              <div class="top-padding">
                <div>
                  <span class="sr-bold">
                    {{ 'OrgDisasterComponent-MONEY_CARD_1' | translate }}
                  </span>
                  <survey-response [value]="latestDssSurvey.raisedFund?.value?.fund">
                    {{
                      money?.singles?.raised?.converted?.amount
                        | customCurrency: currency?.map[money?.singles?.raised?.converted?.currency]
                    }}
                  </survey-response>
                </div>
                <div>
                  <span class="sr-bold">
                    {{ 'OrgDisasterComponent-MONEY_CARD_2' | translate }}
                  </span>
                  <survey-response [value]="latestDssSurvey.spentFund?.value?.fund">
                    {{
                      money?.singles?.spent?.converted?.amount
                        | customCurrency: currency?.map[money?.singles?.raised?.converted?.currency]
                    }}
                  </survey-response>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <share-buttons-custom [metadata]="orgDisasterMetadata"> </share-buttons-custom>
    </div>
  </div>
  <!-- display DSS information -->
  <ng-container *ngIf="context?.plansToRespond">
    <div class="row state-info">
      <div class="col s12 m8 l7 left">
        <div class="row report-note">
          <div class="col valign-wrapper">
            <i class="material-icons green-text text-darken-3">calendar_today</i
            >{{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_CURRENT' | translate }}
          </div>
          <div class="col valign-wrapper">
            <i class="material-icons orange-text text-darken-3">calendar_today</i
            >{{ 'COMMON_SEARCH-COLLAPSIBLE_STATUS_NO_RESPONSE' | translate }}
          </div>
        </div>
        <div class="report-note">
          <span>
            {{ 'COMMON_SEARCH-COLLAPSIBLE_FLAG_NOTE' | translate }}
          </span>
          <i class="tiny material-icons cursor">flag</i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m12 l8">
        <div class="survey_info">
          <ul materialize="collapsible" [materializeParams]="params" class="collapsible" data-collapsible="accordion">
            <li id="li0">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">contacts</i>
                {{ 'COMMON_SEARCH-SERVICES_PROVIDED_TITLE' | translate }}
              </div>
              <div class="collapsible-body text-size">
                <div class="survey row">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'serviceList',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_TITLE_0', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold sub-heading">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_TITLE_0'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'serviceList')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div *ngIf="!latestDssSurvey.serviceList?.value?.length">
                    <survey-response></survey-response>
                  </div>
                  <div *ngIf="latestDssSurvey.serviceList?.value?.length">
                    <table class="bordered services-provided-table">
                      <thead>
                        <tr class="sr-bold">
                          <th data-field="icon" class="icon-cell"></th>
                          <th data-field="name">
                            {{ 'COMMON_SEARCH-SERVICES_PROVIDED_COLUMN_NAME_TITLE' | translate }}
                          </th>
                          <th data-field="direct" class="value-cell centered-cell">
                            {{ 'COMMON_SEARCH-SERVICES_PROVIDED_COLUMN_DIRECT_TITLE' | translate }}
                          </th>
                          <th data-field="indirect" class="value-cell centered-cell">
                            {{ 'COMMON_SEARCH-SERVICES_PROVIDED_COLUMN_INDIRECT_TITLE' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of latestDssSurvey.serviceList?.value">
                          <td>
                            <img
                              *ngIf="
                                (item.serviceProvided.file && item.serviceProvided.file.uri) ||
                                (item.serviceProvided.name | serviceIcons)
                              "
                              class="services-provided-icon-xs"
                              src="{{
                                item.serviceProvided.file && item.serviceProvided.file.uri
                                  ? item.serviceProvided.file.uri
                                  : (item.serviceProvided.name | serviceIcons)
                              }}"
                            />
                          </td>
                          <td>{{ item.serviceProvided.name }}</td>
                          <td>
                            <div>
                              <div class="center">{{ item?.directProportion ? item?.directProportion : 0 }} %</div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div class="center">{{ item?.indirectProportion ? item?.indirectProportion : 0 }} %</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
            <li id="li1">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">contacts</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_1' | translate: { disasterName: context.disaster.name } }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="openReport('hqAddress', 'COMMON_SEARCH-TITLE_ADDRESS')"
                    >
                      flag
                    </i>
                    <span class="subheading sr-bold">{{ 'COMMON_SEARCH-TITLE_ADDRESS' | translate }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'hqAddress')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="row address">
                    <div class="col s12">
                      <survey-response
                        [value]="latestDssSurvey?.hqAddress?.value?.address1"
                        class="sr-justify response"
                      >
                        {{ latestDssSurvey?.hqAddress?.value?.address1 }}
                      </survey-response>
                    </div>
                    <div class="col s12 response">
                      {{ latestDssSurvey?.hqAddress?.value?.address2 }}
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response">{{ 'UNIVERSAL-ADDRESS_CITY' | translate }}:&nbsp;</span>
                      <survey-response [value]="latestDssSurvey?.hqAddress?.value?.city" class="sr-justify response">
                        {{ latestDssSurvey?.hqAddress?.value?.city }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestDssSurvey?.hqAddress?.value?.state" class="sr-justify response">
                        {{ latestDssSurvey?.hqAddress?.value?.state }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestDssSurvey?.hqAddress?.value?.zip" class="sr-justify response">
                        {{ latestDssSurvey?.hqAddress?.value?.zip }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}:&nbsp;</span
                      >
                      <survey-response
                        [value]="latestDssSurvey?.hqAddress?.value?.country?.name"
                        class="sr-justify response"
                      >
                        {{ latestDssSurvey?.hqAddress?.value?.country?.name }}
                      </survey-response>
                    </div>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="openReport('leadContact', 'OrgDisasterComponent-COLLAPSIBLE_TITLE_1_2')"
                    >
                      flag
                    </i>
                    <span class="subheading sr-bold">
                      {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_1_2' | translate }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'leadContact')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="row contact">
                    <div class="col s12">
                      <span class="sr-bold question">{{ 'UNIVERSAL-CONTACT_NAME' | translate }}:&nbsp;</span>
                      <survey-response
                        [value]="latestDssSurvey?.leadContact?.value?.firstName"
                        class="sr-justify response"
                      >
                        {{ latestDssSurvey?.leadContact?.value?.firstName }}
                      </survey-response>
                      <span class="response">
                        {{ latestDssSurvey?.leadContact?.value?.lastName }}
                      </span>
                    </div>
                    <div class="col s12">
                      <span class="sr-justify response sr-bold"
                        >{{ 'UNIVERSAL-CONTACT_TITLE' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestDssSurvey?.leadContact?.value?.title" class="sr-justify response">
                        {{ latestDssSurvey?.leadContact?.value?.title }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-bold sr-justify response"
                        >{{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}:&nbsp;</span
                      >
                      <survey-response [value]="latestDssSurvey?.leadContact?.value?.email" class="sr-justify response">
                        {{ latestDssSurvey?.leadContact?.value?.email }}
                      </survey-response>
                    </div>
                    <div class="col s12">
                      <span class="sr-justify response sr-bold"
                        >{{ 'UNIVERSAL-CONTACT_PHONE' | translate }}:&nbsp;</span
                      >
                      <survey-response
                        [value]="latestDssSurvey?.leadContact?.value?.phone1"
                        class="sr-justify response"
                      >
                        {{ latestDssSurvey?.leadContact?.value?.phone1 }}
                      </survey-response>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li id="li2">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">local_activity</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_2' | translate: { disasterName: context.disaster.name } }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'reliefRecoveryService',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_1', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_1'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'reliefRecoveryService')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <survey-response
                      [value]="latestDssSurvey?.reliefRecoveryService?.value?.isCurrentlyProvidingService"
                      class="sr-justify response"
                    >
                      <span *ngIf="latestDssSurvey?.reliefRecoveryService?.value?.isCurrentlyProvidingService === true">
                        {{ 'UNIVERSAL-YES' | translate }}
                      </span>
                      <span
                        *ngIf="latestDssSurvey?.reliefRecoveryService?.value?.isCurrentlyProvidingService === false"
                      >
                        {{ 'UNIVERSAL-NO' | translate }}
                      </span>
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'reliefRecoveryService',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_2', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">{{
                      'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_2'
                        | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'reliefRecoveryService')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <survey-response
                      [value]="latestDssSurvey?.reliefRecoveryService?.value?.startServiceDate"
                      class="sr-justify response"
                    >
                      {{ latestDssSurvey?.reliefRecoveryService?.value?.startServiceDate }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'reliefRecoveryService',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_3', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_3'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'reliefRecoveryService')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <survey-response
                      [value]="latestDssSurvey?.reliefRecoveryService?.value?.stopServiceDate"
                      class="sr-justify response"
                    >
                      {{ latestDssSurvey?.reliefRecoveryService?.value?.stopServiceDate }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'reliefRecoveryService',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_4', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">{{
                      'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_4'
                        | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'activityHistory')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <survey-response [value]="latestDssSurvey?.activityHistory?.value" class="sr-justify response">
                      {{ latestDssSurvey?.activityHistory?.value }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'disasterStageOfWork',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_5', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{ 'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_5' | translate: { orgName: context.orgName } }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'disasterStageOfWork')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <div class="response sr-justify" *ngIf="latestDssSurvey?.disasterStageOfWork?.value">
                      <div *ngFor="let work of latestDssSurvey?.disasterStageOfWork?.value | keys">
                        <span class="sr-bold">
                          {{ disasterStageNames[work.key] }}
                        </span>
                        <span class="response">
                          {{
                            work.value != null
                              ? work.value
                                ? ('UNIVERSAL-YES' | translate)
                                : ('UNIVERSAL-NO' | translate)
                              : ('COMMON_SEARCH-NO_RESPONSE' | translate)
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="response sr-justify" *ngIf="!latestDssSurvey?.disasterStageOfWork?.value">
                      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                    </div>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'stakeholder',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_6', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">{{
                      'OrgDisasterComponent-COLLAPSIBLE_QUESTION_2_6' | translate: { orgName: context.orgName }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'stakeholder')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="col s12">
                    <survey-response [value]="latestDssSurvey?.stakeholder?.value" class="sr-justify response">
                      {{ latestDssSurvey?.stakeholder?.value }}
                    </survey-response>
                  </div>
                </div>
              </div>
            </li>
            <li id="li3">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">payments</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_3' | translate }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'solicitDonation',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_1', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_1'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'solicitDonation')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      class="sr-justify response"
                      [value]="
                        latestDssSurvey?.solicitDonation?.value?.isNotSolicitingDonation ||
                        latestDssSurvey?.solicitDonation?.value?.isCurrentlySolicitingDonation
                      "
                    >
                      {{
                        context?.isSolicitingDonations ? ('UNIVERSAL-YES' | translate) : ('UNIVERSAL-NO' | translate)
                      }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'solicitDonation',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_2', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_2'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'solicitDonation')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.solicitDonation?.value?.startSolicitDonationDate"
                      class="sr-justify response"
                    >
                      {{ latestDssSurvey?.solicitDonation?.value?.startSolicitDonationDate }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'solicitDonation',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_3', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_3'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'solicitDonation')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.solicitDonation?.value?.stopSolicitDonationDate"
                      class="sr-justify response"
                    >
                      {{ latestDssSurvey?.solicitDonation?.value?.stopSolicitDonationDate }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'raisedFund',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_4', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_4'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'raisedFund')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.raisedFund?.value?.hasValue ? true : null"
                      class="sr-justify response"
                    >
                      {{
                        money?.singles?.raised?.converted?.amount
                          | customCurrency: currency?.map[money?.singles?.raised?.converted?.currency]
                      }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'spentFund',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_5', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_5'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'spentFund')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.spentFund?.value?.hasValue ? true : null"
                      class="sr-justify response"
                    >
                      {{
                        money?.singles?.spent?.converted?.amount
                          | customCurrency: currency?.map[money?.singles?.spent?.converted?.currency]
                      }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'earnedInterest',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_6', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_6'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'earnedInterest')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.earnedInterest?.value?.hasValue ? true : null"
                      class="sr-justify response"
                    >
                      {{
                        money?.singles?.interest?.converted?.amount
                          | customCurrency: currency?.map[money?.singles?.interest?.converted?.currency]
                      }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'earnedInterestSpent',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_7', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_7'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'earnedInterestSpent')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response [value]="latestDssSurvey?.earnedInterestSpent?.value" class="sr-justify response">
                      {{ latestDssSurvey?.earnedInterestSpent?.value }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'transferredFund',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_8', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_3_8'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="getTimestampForResponse('dss', 'transferredFund')"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response
                      [value]="latestDssSurvey?.transferredFund?.value?.hasValue"
                      class="sr-justify response"
                    >
                      {{
                        latestDssSurvey?.transferredFund?.value?.hasValue
                          ? ('UNIVERSAL-YES' | translate)
                          : ('OrgDisasterComponent-NO_GRANTS_REPORTED' | translate)
                      }}
                    </survey-response>
                    <div *ngIf="latestDssSurvey?.transferredFund?.value?.hasValue">
                      <div class="response sr-justify" *ngIf="latestDssSurvey?.transferredFund?.value?.fileId">
                        <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                        <a
                          class="btn"
                          target="_blank"
                          href="{{
                            documentService.getDocumentDownloadLink(latestDssSurvey?.transferredFund?.value?.fileId)
                          }}"
                        >
                          {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                        </a>
                      </div>
                      <div
                        *ngIf="
                          !latestDssSurvey?.transferredFund?.value?.fileId &&
                          latestDssSurvey?.transferredFund?.value?.fundTransferList.length === 0
                        "
                        class="response sr-justify"
                      >
                        <span class="sr-justify response sr-bold">{{ 'UNIVERSAL-FILE' | translate }}:</span>
                        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                      </div>
                      <div *ngIf="latestDssSurvey?.transferredFund?.value?.fundTransferList">
                        <span class="sr-bold">{{ 'OrgDisasterComponent-COLLAPSIBLE_ANSWER_3_8' | translate }}</span>
                        <span *ngIf="latestDssSurvey?.transferredFund?.value?.fundTransferList.length === 0">
                          {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                        </span>
                        <ul *ngIf="latestDssSurvey?.transferredFund?.value?.fundTransferList">
                          <li
                            *ngFor="
                              let transfer of latestDssSurvey?.transferredFund?.value?.fundTransferList;
                              let i = index
                            "
                          >
                            {{
                              transfer.receivingOrganization
                                ? transfer.receivingOrganization +
                                  ': ' +
                                  (money?.arrays?.transfers[i]?.converted?.amount
                                    | customCurrency: currency?.map[money?.arrays?.transfers[i]?.converted?.currency])
                                : ('COMMON_SEARCH-NO_RESPONSE' | translate)
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li id="li4">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">face</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_4' | translate }}
              </div>
              <div class="collapsible-body body-margin">
                <div class="survey">
                  <div class="subheading sr-bold">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="openReport('paidStaff', 'UNIVERSAL-SURVEY_QUESTIONS_PAID_STAFF')"
                    >
                      flag
                    </i>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_PAID_STAFF' | translate }}
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="
                          latestDssSurvey?.localStaff?.lastUpdatedDate > latestDssSurvey?.nonLocalStaff?.lastUpdatedDate
                            ? latestDssSurvey?.nonLocalStaff?.lastUpdatedDate
                            : latestDssSurvey?.localStaff?.lastUpdatedDate
                        "
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="my_table2">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="response">
                          <td>
                            <survey-response [value]="getTotalMgmtStaffCount()">
                              {{ getTotalMgmtStaffCount() }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="getTotalProfessionalStaffCount()">
                              {{ getTotalProfessionalStaffCount() }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="getTotalSupportStaffCount()">
                              {{ getTotalSupportStaffCount() }}
                            </survey-response>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="subheading sr-bold">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'localStaff',
                          this.translateService.instant('UNIVERSAL-SURVEY_QUESTIONS_LOCAL_STAFF', {
                            location: locations
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_LOCAL_STAFF' | translate: { location: locations } }}
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.localStaff?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="my_table2">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="response">
                          <td>
                            <survey-response [value]="latestDssSurvey?.localStaff?.value?.mgmtStaffCount">
                              {{ latestDssSurvey?.localStaff?.value?.mgmtStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.localStaff?.value?.professionalStaffCount">
                              {{ latestDssSurvey?.localStaff?.value?.professionalStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.localStaff?.value?.supportStaffCount">
                              {{ latestDssSurvey?.localStaff?.value?.supportStaffCount }}
                            </survey-response>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="subheading sr-bold">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'nonLocalStaff',
                          this.translateService.instant('UNIVERSAL-SURVEY_QUESTIONS_NON_LOCAL_STAFF', {
                            location: locations
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_NON_LOCAL_STAFF' | translate: { location: locations } }}
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.nonLocalStaff?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="my_table2">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="response">
                          <td>
                            <survey-response [value]="latestDssSurvey?.nonLocalStaff?.value?.mgmtStaffCount">
                              {{ latestDssSurvey?.nonLocalStaff?.value?.mgmtStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.nonLocalStaff?.value?.professionalStaffCount">
                              {{ latestDssSurvey?.nonLocalStaff?.value?.professionalStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.nonLocalStaff?.value?.supportStaffCount">
                              {{ latestDssSurvey?.nonLocalStaff?.value?.supportStaffCount }}
                            </survey-response>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="subheading sr-bold">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="openReport('volunteerStaff', 'UNIVERSAL-SURVEY_QUESTIONS_VOLUNTEER_STAFF')"
                    >
                      flag
                    </i>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_VOLUNTEER_STAFF' | translate }}
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.volunteerStaff?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="my_table2">
                    <table class="centered">
                      <thead>
                        <tr>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                            </div>
                          </th>
                          <th>
                            <div class="sr-bold question">
                              {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="response">
                          <td>
                            <survey-response [value]="latestDssSurvey?.volunteerStaff?.value?.mgmtStaffCount">
                              {{ latestDssSurvey?.volunteerStaff?.value?.mgmtStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.volunteerStaff?.value?.professionalStaffCount">
                              {{ latestDssSurvey?.volunteerStaff?.value?.professionalStaffCount }}
                            </survey-response>
                          </td>
                          <td>
                            <survey-response [value]="latestDssSurvey?.volunteerStaff?.value?.supportStaffCount">
                              {{ latestDssSurvey?.volunteerStaff?.value?.supportStaffCount }}
                            </survey-response>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
            <li id="li5">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">list</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_5' | translate }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'activityReport',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_1', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_1'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.activityReport?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response [value]="latestDssSurvey?.activityReport?.value?.reportFrequencyId">
                      {{ selectedReportFrequency?.displayName }}
                    </survey-response>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'activityReport',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_2', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">
                      {{ 'OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_2' | translate }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.activityReport?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response">
                    <survey-response [value]="latestDssSurvey?.activityReport?.value"> </survey-response>
                    <div
                      *ngIf="
                        latestDssSurvey?.activityReport?.value && !latestDssSurvey?.activityReport?.value?.hasValue
                      "
                    >
                      {{ 'OrgDisasterComponent-COLLAPSIBLE_ANSWER_5_2_1' | translate }}
                    </div>
                    <div class="response sr-justify" *ngIf="latestDssSurvey?.activityReport?.value?.hasValue">
                      <div
                        class="response sr-justify"
                        *ngIf="latestDssSurvey?.activityReport?.value?.fileId; else noResponseFileId"
                      >
                        <span class="sr-justify response sr-bold">{{
                          'OrgDisasterComponent-COLLAPSIBLE_ANSWER_5_2_3' | translate
                        }}</span>
                        <a
                          class="btn"
                          target="_blank"
                          href="{{
                            documentService.getDocumentDownloadLink(latestDssSurvey?.activityReport?.value?.fileId)
                          }}"
                        >
                          {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                        </a>
                      </div>
                      <div
                        class="response sr-justify"
                        *ngIf="
                          latestDssSurvey?.activityReport?.value?.webLink &&
                            latestDssSurvey?.activityReport?.value?.webLink !== '';
                          else noResponseWebLink
                        "
                      >
                        <span class="sr-justify response sr-bold">{{
                          'OrgDisasterComponent-COLLAPSIBLE_ANSWER_5_2_2' | translate
                        }}</span>
                        {{ latestDssSurvey?.activityReport?.value?.webLink }}
                      </div>
                    </div>
                  </div>
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'engagementList',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_3', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question sr-justify">{{
                      'OrgDisasterComponent-COLLAPSIBLE_QUESTION_5_3'
                        | translate: { disasterName: context.disaster.name }
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.engagementList?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div
                    class="response sr-justify chip-container"
                    *ngIf="context?.engagements !== null; else noResponseDetail"
                  >
                    <div class="chip" *ngFor="let memberOrg of context?.engagements">
                      {{ memberOrg }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li id="li6">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">more_horiz</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_6' | translate }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'additionalInfo',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_6_1', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="sr-bold question">{{
                      'OrgDisasterComponent-COLLAPSIBLE_QUESTION_6_1' | translate
                    }}</span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.additionalInfo?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div class="response sr-justify">
                    <survey-response [value]="latestDssSurvey?.additionalInfo?.value">
                      {{ latestDssSurvey?.additionalInfo?.value }}
                    </survey-response>
                  </div>
                </div>
              </div>
            </li>
            <li id="li7">
              <div class="collapsible-header sr-bold blue-text text-size">
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">location_on</i>
                {{ 'OrgDisasterComponent-COLLAPSIBLE_TITLE_7' | translate }}
              </div>
              <div class="collapsible-body">
                <div class="survey">
                  <div class="question">
                    <i
                      class="material-icons statusFlag red-text"
                      (click)="
                        openReport(
                          'locationsList',
                          this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_7_1', {
                            orgName: context.orgName,
                            disasterName: context.disaster.name
                          })
                        )
                      "
                    >
                      flag
                    </i>
                    <span class="subheading sr-bold">
                      {{
                        'OrgDisasterComponent-COLLAPSIBLE_QUESTION_7_1'
                          | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                      }}
                    </span>
                  </div>
                  <div class="dt-stamp">
                    <span class="left">
                      <last-updated-icon-and-timestamp
                        [surveyType]="dss"
                        [timestamp]="latestDssSurvey?.locationList?.lastUpdatedDate"
                      >
                      </last-updated-icon-and-timestamp>
                    </span>
                  </div>
                  <div class="divider"></div>
                  <div *ngIf="organizationDisasterLocations?.length > 0 && !showOrgLocations">
                    <label for="countrySelect">Select a country</label>
                    <select id="countrySelect" [(ngModel)]="currentCountry" class="browser-default country-select">
                      <option *ngFor="let country of organizationDisasterLocations" [ngValue]="country.country.name">
                        {{ country.country.name }}
                      </option>
                    </select>
                    <location-map
                      *ngIf="
                        latestDssSurvey?.locationList?.value && latestDssSurvey?.locationList?.value.length;
                        else noLocations
                      "
                      [source]="latestDssSurvey?.locationList?.value"
                      [country]="currentCountry"
                      [multi]="true"
                      [editable]="false"
                      [showInfoWindow]="false"
                    >
                    </location-map>
                    <ng-template #noLocations>
                      <survey-response> latestDssSurvey?.locationList?.value </survey-response>
                    </ng-template>
                  </div>
                  <div *ngIf="showOrgLocations">
                    <ul class="lists row">
                      <li class="my-list col s6" *ngFor="let location of latestGobSurvey?.locationContactList">
                        <a [routerLink]="['/org-location', latestGobSurvey.organizationId, location.value?.countryId]">
                          {{ location.value?.countryName }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li id="li8">
              <div
                id="orgDisasterOrgResponseVideo"
                class="collapsible-header sr-bold blue-text text-size"
                [ngClass]="{ active: videoId }"
              >
                <i class="material-icons expand-more">expand_more</i>
                <i class="material-icons">video_library</i>
                {{ 'COMMON_SEARCH-SERVICES_DISASTER_VIDEO' | translate }}
              </div>
              <div class="collapsible-body text-size">
                <div class="lists row">
                  <div class="survey">
                    <div class="question">
                      <!--Video URL-->
                      <div class="question">
                        <i
                          class="material-icons statusFlag red-text"
                          (click)="
                            openReport(
                              'orgResponseVideoUrl',
                              this.translateService.instant('OrgDisasterComponent-COLLAPSIBLE_QUESTION_8_1', {
                                orgName: context.orgName,
                                disasterName: context.disaster.name
                              })
                            )
                          "
                        >
                          flag
                        </i>
                        <span class="sr-bold question">{{
                          'OrgDisasterComponent-COLLAPSIBLE_QUESTION_8_1'
                            | translate: { orgName: context.orgName, disasterName: context.disaster.name }
                        }}</span>
                      </div>
                      <div class="dt-stamp">
                        <span class="left">
                          <last-updated-icon-and-timestamp
                            [surveyType]="dss"
                            [timestamp]="latestDssSurvey?.orgResponseVideoUrl?.lastUpdatedDate"
                          >
                          </last-updated-icon-and-timestamp>
                        </span>
                      </div>
                      <div class="divider"></div>
                      <div *ngIf="!latestDssSurvey?.orgResponseVideoUrl?.value">
                        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
                      </div>
                      <div *ngIf="latestDssSurvey?.orgResponseVideoUrl?.value">
                        <survey-response
                          [value]="latestDssSurvey?.orgResponseVideoUrl?.value"
                          class="sr-justify response"
                        >
                          <parse-video-url
                            [source]="latestDssSurvey?.orgResponseVideoUrl?.value"
                            videoId="orgDisasterOrgResponseVideo"
                            [autoplay]="videoId === 'orgDisasterOrgResponseVideo'"
                          ></parse-video-url>
                        </survey-response>
                      </div>
                      <!--ended Video URL-->
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <social-network
        class="col s12 m12 l4 right"
        [orgId]="context.orgId"
        [locationId]="null"
        [disasterId]="context.disaster?.disasterId"
      ></social-network>
    </div>
  </ng-container>
  <report-modal [reportActions]="reportActions"></report-modal>

  <ng-template #noResponseDetail>
    <div class="response">
      <survey-response>
        {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
      </survey-response>
    </div>
  </ng-template>

  <ng-template #noResponseFileId>
    <div class="response sr-justify">
      <span class="sr-justify response sr-bold">{{ 'OrgDisasterComponent-COLLAPSIBLE_ANSWER_5_2_3' | translate }}</span>
      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
    </div>
  </ng-template>

  <ng-template #noResponseWebLink>
    <div class="response sr-justify">
      <span class="sr-justify response sr-bold">{{ 'OrgDisasterComponent-COLLAPSIBLE_ANSWER_5_2_2' | translate }}</span>
      {{ 'COMMON_SEARCH-NO_RESPONSE' | translate }}
    </div>
  </ng-template></ng-template
>
